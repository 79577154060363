#main.compare-page {
  width: 100%;
  max-width: 1370px;
  padding: 0 5px;
  box-sizing: border-box;
}

#compare-page-container {
  margin: 0 0 30px;
}

.compare-page--mobile__container {
  display: none;
}

.compare-page--desktop__container {
  display: block;
}

.compare-page .primary-content {
  width: 100%;
}

.compare-page .header-container {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.compare--products-description-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  border: 1px solid  #e1e1e1;
  overflow: hidden;
}

.compare--product-view-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 30px;
  overflow: hidden;
}

.compare--product-view-wrapper .compare--left-nav-wrapper {
  background-color: #fff;
  z-index: 2;
}

#compare-table {
  display: none;
}

.compare-page .header-container h1 {
  margin: 30px 0;
  font-family: Kipling-Light,Futura,sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: 1.6px;
  text-align: center;
  color: #2d2f2c;
  display: block;
  float: none;
  padding: 0;
  text-transform: none;
  
  @include mobile() {
  	margin: 15px 0;
  	text-align: left;
  	padding-left: 5px;
  	font-size: 21px;
  }
}

#compare-page-container .back {
  font-size: 15px;
  color: $color-green;
  float: left;
}

#compare-page-container .compare-print {
  font-size: 17px;
  float: right;
  color: $color-red-main;
  text-transform: uppercase;
}

#compare-page-container .compareremovecell {
  padding: 0;
  border: none;
  width: 23.3%;
  min-width: 201px;
  box-sizing: border-box;
}

#compare-page-container .compare--empty-wrapper {
  margin: 13% 0 0;
  height: 70%;
  width: 100%;
  padding: 0 7%;
  box-sizing: border-box;
}

#compare-page-container .product-tile .product-image {
  margin: 0 0 15px;
  border: none;
}

#compare-page-container .compare--empty-description {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 150px;
  font-family: Kipling-Regular,Futura,sans-serif;
  color: $color-dark-grey;
  border: 1px solid $color-dark-grey;
}

#compare-page-container .product-tile .product-name {
  min-height: 63px;
  width: 80%;
  margin: 0 auto 14px auto;
  
  @include mobile() {
  	width: 90%;
  }
}

#compare-page-container .product-name .name-link {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: left;
  color: #2d2f2c;
  font-family: Kipling-Regular,Futura,sans-serif;
  text-transform: none;
  
  @include mobile() {
  	font-size: 12px;
  }
  
  span {
  	display: block;
  	
  	&:nth-child(2) {
  		font-size: 16px;
  		color: #6c6a71;
  		
  		@include mobile() {
		  	font-size: 12px;
		  }
  	}
  }
}

#compare-page-container .compare-add-to-cart {
  width: 80%;
  margin: 0 auto;
  
  @include mobile() {
  	width: 90%;
  }
}

#compare-page-container .compare-add-to-cart button[type="submit"] {
  background: $color-dark-grey;
  font-family: Kipling-Extrabold,Arial,sans-serif;
}

#compare-page-container .compare-add-to-cart button[type="submit"]:hover {
  background-color: #000;
}

#compare-page-container .compare--remove-link {
  display: block;
  right: 3%;
  top: 0;
  width: 15px;
  height: 15px;
  float: right;
  position: relative;
  z-index: 1;
}

#compare-page-container .compare--remove-link:before {
  content: "";
  position: absolute;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 0;
  border-top: 2px solid $color-dark-grey;
  transform: rotate(45deg);
  transform-origin: center;
}

#compare-page-container .compare--remove-link:after {
  content: "";
  position: absolute;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 0;
  border-top: 2px solid $color-dark-grey;
  transform: rotate(-45deg);
  transform-origin: center;
}

#compare-page-container .compare--add-legend {
  text-align: center;
  text-transform: none;
  font-family: Kipling-Light,Arial,sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.35px;
  text-align: center;
  color: #000000;
}

#compare-page-container .compare--add-ico {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 0 15px;

  &:before,
  &:after{
      content: "";
      position: absolute;
      background-color: $color-dark-grey;
  }

  &:before{
      top: 0;
      left: 50%;
      width: 4px;
      height: 100%;
      margin-left: -2px;
  }

  &:after{
      top: 50%;
      left: 0;
      width: 100%;
      height: 4px;
      margin-top: -2px;
  }
}

.compare--left-nav-wrapper {
  width: 10%;
  min-width: 149px;
  min-height: 10px;
}

.compare--product-description-wrapper {
  width: 23.3%;
  min-width: 201px;
  border-left: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.compare--product-description-wrapper .product-pricing .product-variation-price {
  display: none;
}

.compare--product-description-wrapper .product-pricing .product-variation-price.selected {
  display: inline;
}

.compare--bottom-add-to-cart-container .compare--product-description-wrapper {
  border: none;
}

.compare--bottom-add-to-cart {
  width: 160px;
  padding: 8px 0;
  color: #fff;
  font-size: 16px;
  font-family: Kipling-Extrabold,Arial,sans-serif;
  border-radius: 3px;
  background: $color-dark-grey;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background: $color-curious-green;
  }
}

.compare--left-nav-wrapper .compare--row-align{
  font-family: Kipling-Light,Futura,sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.07;
  letter-spacing: 1.35px;
  text-align: left;
  color: #2d2f2c;
  padding-left: 10px;
  justify-content: flex-start;
  border-left: none;
}

.compare--row-align:nth-child(odd) {
  background-color: #e1e1e1;
  border-left: 1px solid #e1e1e1;
}

.compare--row-align:nth-child(even) {
  background-color: #fff;
}

.compare--left-nav-wrapper .compare--row-align {
  border-left: none;
}

.compare--row-align {
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.65px;
  text-align: left;
  color: #2d2f2c;
  padding: 7px 25px; 
  box-sizing: border-box;
  
  @include mobile() {
  	font-size: 11px;
  	padding: 5px 15px; 
  }
  
  .bulletedList ul {
  	text-align: left;
  	padding: 0 0 10px 0;
  	
  	li {
  		text-transform: none!important;
  		margin-left: 10px;
  	}
  }
  
  .bulletedList ul li:before {
  	content: '-';
  	width: auto;
    height: auto;
    background-color: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    left: -10px;
    position: absolute;
  }
}
.compare--row-dimensions {
  text-transform: uppercase;
}
.compare--row-weight {
  text-transform: lowercase;
}
.compare--row-reviews {
  .TTratingBox {
    width: 90px;
    height: 18px;
  }
  .powerreview-grid-stars {
    height: 18px;
  }
  .prSnippetNumberOfRatingsText {
    display: none;
  }
}

.compare--row-reviews .TTratingBoxBorder {
  margin-bottom: 0 !important;
}

.compare--row-features.compare--row-align {
  height: auto;
}

.compare--bottom-add-to-cart-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 20px 0 0;
}

.compare--product-description-wrapper ul.swatch-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.compare--row-swatches:hover {
  z-index: 1;
}

.compare--row-swatches ul.swatch-toggle {
  margin: auto;
}

.compare--row-swatches .product-swatches .swatch {
  display: block;
  width: 22px;
  outline: 0;
  height: 22px;
  background-position: 50px;
  border-radius: 50%;
  margin: 0 0 5px 5px;
  box-sizing: border-box;
  &.selected {
  	    border: 1px solid black;
  }
}

.compare--row-swatches .product-swatches .swatch:last {
  margin: 0;
}

.compare--row-swatches .swatch img {
  border: 1px solid  #fff;
  height: 17px;
  padding: 1xp;
  width: 17px;
  border-radius: 50%;
  display: none;
}

.bulletedList ul {
  text-align: center;
  padding: 10px 0 0;
}

.bulletedList ul li {
  display: block;
  position: relative;
}

.bulletedList ul li:before {
  content: ' ';
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
}

.product-description-wrapper .bulletedList ul {
  @include desktop {
    text-align: left;
  }
}

.product-description-wrapper .bulletedList ul li {
  @include desktop {
    margin: 0;
  }
}
.compare--product-description-wrapper .product-swatches {
  position: absolute;
  top: 10px;
}
.compare--product-description-wrapper .swatch-list.swatch-toggle {
  overflow: hidden;
  width: 164px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.compare--product-description-wrapper .swatch-list.swatch-toggle li {
  display: inline-block;
}

.compare--product-description-wrapper .swatch-list.swatch-toggle .viewMoreLI {
  margin: 0 0 0 18px;

  @include mobile {
    margin: 0 0 0 5px;
  }
}

.compare--product-description-wrapper .swatch-list.swatch-toggle .more-colors-link {
  white-space: nowrap;
  font-family: Kipling-Regular,Futura,sans-serif;
  color: #2d2c2f;
}

.compare--product-description-wrapper .compare--hide-swatches {
  visibility: hidden;
}

.compare--product-description-wrapper .mob-color-toggle,
.compare--product-description-wrapper .desktop-color-toggle:hover {
  background-color: #e1e1e1;
}

.compare--product-description-wrapper .mob-color-toggle .compare--hide-swatches,
.compare--product-description-wrapper .desktop-color-toggle:hover .compare--hide-swatches {
  visibility: visible;
}

@include mobile {
  #compare-page-container .back {
    padding: 0 0 0 10px;
  }

  #compare-page-container .compare-print {
    padding: 0 10px 0 0;
  }

  // .compare-page--container {
  //   overflow-x: scroll;
  // }

  .compare--product-view-wrapper,
  .compare--products-description-container {
    overflow: visible;
    border: none;
  }

  .compare--left-nav-wrapper {
    width: 5%;
    min-width: 80px;
  }

  .compare--left-nav-wrapper .compare--row-align {
    font-size: 13px;
  }

  .compare--product-description-wrapper,
  #compare-page-container .compareremovecell {
    width: 22.6%;
    min-width: auto;
  }

  .compare--product-description-wrapper {
    border-bottom: 1px solid #E1E1E1;
  }

  .compare--first-product.mobile-slide {
    margin: 0 0 0 -45.2%;
  }
}

@media (max-width: 750px) {
  .compare-page--mobile__container {
    display: block;
  }

  .compare-page--desktop__container {
    display: none;
  }

  .compare--product-view-wrapper {
    flex-flow: row wrap;
    padding: 0 1%;
  }

  #main.compare-page {
    padding: 0;
  }

  .compare--back-button,
  .compare-print,
  .header-container {
    padding: 0 10px;
  }

  .compare--left-nav-wrapper {
    display: none;
  }

  .compare--product-description-wrapper {
    width: 100%;
  }

  #compare-page-container .compareremovecell {
    width: 48%;
    margin: 0 0 30px;
    border-bottom: 1px solid #E1E1E1;
  }

  .compareremovecell .compare--row-align {
    font-family: Kipling-Regular,Futura,sans-serif;
    font-size: 12px;
  }

  .compareremovecell .product-tile .thimb-link {
    padding: 0 6.96%;
    display: block;
  }

  #compare-page-container .compare--empty-wrapper {
    height: auto;
    margin: 35px 0 117px;
  }

  .compare--product-description-wrapper {
    border: 0;
  }

  // .compare--first-product.mobile-slide {
  //   margin: 0 -52% 0 0;
  // }
}
