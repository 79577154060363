.build-a-bundle-slot {
  img {
    width: 100%;
  }
  .hidden-mobile {
    width:110%;
    margin-left: -5%;
  }
}

.back-to-top button{
  background: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  span.mobile-top-text {
    font-size: 13px;
    position: absolute;
    top: 40px;
    left: 25px;
    display: none;
  }

}

.build-bundle {
  .top-block {
     .step-1 > h2 {
      @media screen and (max-width: 960px) {
        border-top: 1px solid #c6c5c5;
      }
    }
  }
  max-width: 960px;
  @media screen and (min-width: 960px) {
      margin-left: 45px;
  }
  h1 {
    font-weight: bold;
  }
  .bottom-block {
    clear: both;
  }
  .top-block {
    display: inline-grid;
    width: 100%;
    .left {
      width: 330px;
      display: inline-block;
      float: left;
      @media screen and (max-width: 960px) {
        width: 100%;
        display: none;
      }
      .mobile-prev {
        display: inline-block;
        font-size: 50px;
        position: absolute;
        z-index: 1;
        top: 45%;
        left: 0%;
        color: $color-dark-grey;
        @media screen and (max-width: 960px) {
          font-size: 30px;
        }
      }
      .mobile-next {
        display: inline-block;
        font-size: 50px;
        position: absolute;
        z-index: 1;
        top: 45%;
        right: 0%;
        color: $color-dark-grey;
        @media screen and (max-width: 960px) {
          font-size: 30px;
        }
      }
    }
    .right {
      width : initial;
      display: inline-block;
      width: calc(100% - 350px);
      text-align: center;
      margin-left: 20px;
      position: relative;
      @media screen and (max-width: 960px) {
        display: none;
      }
      .color-swatcher.pdp-main {
        margin: 0;
        padding: 0;
        .swatches {
          margin: 0 auto;
          display: inline-block;
        }
      }
      .quickviewplus {
        display: none;
        position: absolute;
        top: 35%;
        left: 45%;
        width: 120px;
        height: 30px;
        background-color: $color-red-main;
        color: #fff;
        font-size: 16px;
        line-height: 35px;
        margin-left: -20px;
        margin-top: -20px;
        letter-spacing: 0.5px;
        a {
            color: white;
            display: block;

        }
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
      @media screen and (max-width: 960px) {
        .product-variations {
          border-top:0px;
          border-bottom:0px;
        }
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 20px;
        .product-image img{
            width : 100%;
            height: auto;
        }
      }
    }
    h2 {
      color: black;
      width: 80%;
      font-size: 30px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 100;
      padding-bottom: 10px;
      padding-top: 25px;
      @media screen and (max-width: 960px) {
        padding-bottom: 0;
        padding-top: 0;
        font-size: 18px;
        width: 115%;
        margin-left: -20px;
        border-bottom: 1px solid #c6c5c5;
        padding: 10px;
        color: $color-red-main;
        &::after{
          position: relative;
          top: 3px;
          right: 20px;
          float: right;
          content: "\e920" !important;
          font-family: icomoon;
          color: $color-red-main;
          font-size: 13px;
        }
        &.active {
            color: black;
            border-bottom: 0px;
            &::after{
                  transform: rotate(-180deg);
                  color:black;
            }
        }
      }
      @media screen and (max-width: 390px) {
        font-size: 15px;
      }
    }

    .product-back {
      display: inline-block;
      text-align: right;
      float: right;
      font-size: 21px;
      padding-top: 30px;
      color:$color-red-main;
      font-family: Kipling-ExtraBold,Futura,sans-serif;
      border-bottom: 1px solid $color-red-main;
      cursor: pointer;
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    .current-product-name {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      padding-top: 20px;
    }
    .current-product-price {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color:#565454;
      padding-top: 10px;
      .standart-price {
        display: inline-block;
        text-decoration: line-through;
        font-size: 14px;
      }
      .sale-price {
        display: inline-block;
        color:$color-red-main;
        padding-left: 10px;
      }
    }
    .product-image {
      text-align: center;
      @media screen and (max-width: 960px) {
        display: inline-block;
      }
      img {
        max-width: 330px;
        @media screen and (max-width: 960px) {
          max-width: 250px;
        }
      }
    }
    .product-content {
      width: 390px;
      padding-left: 40px;
      padding-top: 20px;
      // overflow-y: scroll;
      position: relative;
        @media screen and (max-width: 960px) {
          width : 100%;
          padding-left: 20px;
          padding-right: 20px;
          margin-left:0px;
          text-align: center;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 11px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid white; /* should match background, can't be transparent */
            background-color: rgba(0, 0, 0, .5);
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 8px;
        }
        .product-element {
          width : 158px;
          display: inline-block;
          float: left;
          text-align: center;
          position: relative;

          @media screen and (max-width: 960px) {
            margin: 0 auto;
            float: none;
          }


          &.active {
            img {
              border: 1px solid black;
              box-sizing: border-box;
              padding: 5px;
            }

          }
          a {
            display: block;
            img {
              max-width: 130px;

              width: 100%;
              @media screen and (max-width: 960px) {
                max-width: 90px;
              }
            }
          }
        }
    }
    .step-1 {
      display: block;
      @media screen and (min-width: 960px) {
          border-bottom: 1px dashed $color-red-main;
          padding-bottom: 20px;
      }
      @media screen and (max-width: 960px) {
          display: block !important;
      }

    }
    .step-2, .step-3 {
        @media screen and (min-width: 960px) {
            display: none;
            border-bottom: 1px dashed $color-red-main;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 960px) {
            display: block !important;
        }


    }

  }
  .monogram {
    padding-top: 5px;
    @media screen and (max-width: 960px) {
      width: 48%;
      display: inline-block;
      float: left;
      img {
        max-height: 39px;
      }
    }
  }
  .addtocart{
    display: block;
    width: 215px;
    text-align: center;
    padding: 10px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 5px auto;
    cursor: pointer;
    background: $color-red-main;
    color: white;
    &.last-step{
      animation:myfirst 2s;
      -moz-animation:myfirst 2s infinite; /* Firefox */
      -webkit-animation:myfirst 2s infinite; /* Safari and Chrome */
    }
    &.grey{
        background: #dcdbdb;
    }
    &.add-two-products{
      animation:myfirst 2s;
      -moz-animation:myfirst 2s infinite; /* Firefox */
      -webkit-animation:myfirst 2s infinite; /* Safari and Chrome */
        width: 100% !important;
    }



    @media screen and (max-width: 960px) {
      width: 48%;
      display: inline-block;
      float: right;
      height: 100%;
      font-size: 15px;
      line-height: 15px;
    }
    @media screen and (max-width: 360px) {
      font-size: 13px;
      line-height: 12px;
    }
  }
  .js-monogram-it.disabled{
    opacity: 0.3;
  }
  .bottom-block {

    .monogram {
      width: 300px;
      height: 160px;
      display: inline-block;
      float: left;
      padding-right: 50px;

      .js-monogram-it {
        padding: 10px;
        display: block;
        text-align: center;
        color: white;
        background: $color-red-main;
        margin: 60px 30px;
        font-size: 21px;
        font-family: Kipling-Regular,Futura,sans-serif;
        font-weight: bold;
        letter-spacing: 3px;
        border-radius: 10px;
        &.disabled {
          opacity: 0.3;
        }
      }
      @media screen and (max-width: 960px) {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 60px;
        margin-bottom: 20px;
        .js-monogram-it {
          width: 100%;
          margin: 20px 0px 0px 0px;
        }
      }
    }
    .your-selection {
      display: inline-block;
      width: 700px;
      float: left;
      padding-top: 30px;
      @media screen and (max-width: 960px) {
        width : 100%;
        padding-top:0px;
      }
      .center {
        @media screen and (max-width: 960px) {
          display: table;
          margin: 0 auto;
        }
      }
      h2 {
        text-align: center;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-size: 20px;
        @media screen and (max-width: 960px) {
            padding-top: 20px;
            font-weight: 100;
        }

      }
      .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 10px;
        padding-right: 55px;
        margin-bottom: 5px;
        @media screen and (max-width: 960px) {
          padding: 15px 0px;
        }
      }
      .element-1, .element-2, .element-3 {
        display: inline-block;
        float: left;
        width: 175px;
        height: 175px;
        background: #e0e0e0;
        border: 1px solid #9c9c9c;
        &.active {
          border: 2px solid black;
        }
        @media screen and (max-width: 960px) {
          display: block;
          margin: 0 auto;
          float: none;
          width: 90px;
          height: 90px;
        }
        @media screen and (max-width: 360px) {
          width: 80px;
          height: 80px;
        }
        .js-remove-element {
          position: absolute;
          width: 30px;
          height: 30px;
          text-align: center;
          border-radius: 15px;
          background-color: #e0e0e0;
          font-weight: 700;
          font-size: 20px;
          color: $color-red-main;
          line-height: 32px;
          right: -10px;
          top: -5px;
          cursor: pointer;
          @media screen and (max-width: 960px) {
                right: calc(50% - 60px);
          }
        }
      }
      .element-wrapper {
        position: relative;
        display: inline-block;
        @media screen and (max-width: 960px) {
          display : inline-block;
        }
        float: left;
        .monogramit {
          position: absolute;
          bottom: -5px;
          background-color: #e0e0e0;
          width: 28px;
          height: 28px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          border-radius: 14px;
          line-height: 32px;
          color: #636363;
          box-shadow: 0 0 5px rgba(0,0,0,0.5);
          right: -7px;
          @media screen and (max-width: 960px) {
                right: calc(50% - 60px);
          }
        }
      }
      .plus {
        display: inline-block;
        font-size: 50px;
        font-weight: 700;
        padding: 55px 10px;
        line-height: 50px;
        float: left;
        @media screen and (max-width: 960px) {
          width: 30px;
          font-size: 25px;
          padding-top: 35px;
          text-align: center;
          padding: 35px 7px;
          line-height: 20px;
        }
        @media screen and (max-width: 360px) {
            padding: 30px 5px;
            width: 20px;
        }
      }


    }
    .total-price {
      display: inline-block;
      float: left;
      padding-top: 45px;
      .promo-text {
        font-size: 16px;
        color: $color-red-main;
        display: inline-block;
        width: 215px;
        @media screen and (max-width: 960px) {
            font-size: 22px;
            width: 100%;
        }
        color :$color-red-main;
      }
      @media screen and (max-width: 960px) {
        width : 100%;
        text-align: center;
        padding-top: 25px;
      }
      .span-rav {
        display: inline-block;
        font-size: 25px;
        font-weight: bold;
        padding-right: 5px;
        vertical-align: top;
        padding-top: 7px;
      }
      .title {
        text-transform: uppercase;
        font-size: 28px;
        margin-top: 10px;
        @media screen and (max-width: 960px) {
          margin-top: 0px;
          font-size: 20px;
        }
      }
      .js-total-price {
        padding-top: 5px;
        text-align: left;
        font-size: 30px;
        color: #33b0f0;
        @media screen and (max-width: 960px) {
          padding-top: 5px;
          display: inline-block;
        }
      }
    }
  }
}
.view-all-grid-page {
  display: inline-block;
  white-space: nowrap;
  position: relative;

  @media screen and (min-width: 960px) {
    &:after {
      content: '>';
      font-size: 25px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      color: $color-green;
    }
    a {
      padding-right: 20px;
      font-size: 27px;
      font-family: Kipling-ExtraBold,Futura,sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $color-green;
    }
  }
}

@media (max-width: 960px) {
  .full-width-pdp .view-all-grid-page {
    &:after {
      content: '>';
      font-size: 25px;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $color-dark-grey;
    }
    a {
      padding-right: 20px;
      font-size: 16px;
      font-family: Kipling-ExtraBold,Futura,sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $color-green;
    }
  }
}

@media (max-width: 450px) {
  .full-width-pdp .view-all-grid-page {
    &:after {
      font-size: 20px;
    }
    a {
      font-size: 14px;
    }
  }
}

.svLinkConfigurator {
  padding : 0 !important;
}
.gridCatName {
  position: relative;
}

.back-to-top {
  cursor: pointer;
  top: inherit;
  bottom: 45px;

  &:before {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    content: "\e920" !important;
    font-family: icomoon;
    color: #fff;
    font-size: 17px;
  }

  span {
    font-size: 0;
  }
}

@media screen and (max-width: 960px){
  .pt_categoryLeftNav #secondary {
  	display : none;
  }
  #primary,
  #secondary {
    float: none;
    width: 100%;
  }

  .search-refinements-sidebar {
    display: none;
  }

  .primary-search-content {
    width: 100% !important;
  }

  h1.category-header,
  .slot-grid-header,
  .search-result-options.top {
    margin-left: 0;
    font-size: 24px;
    margin-bottom: 0px;
    padding-bottom: 0;
    text-align:center;
  }

  .search-result-options .refinement {
    width: 100%;
    float: none!important;
  }

  .refinement span.filter-by {
    top: 0;
  }

  .search-result-options .sort-wrapper .text {
    float: none;
    top: 0;
    left: 0;
  }

  .sort-by {
    height: auto;
    float: none;
    padding-left: 0;
  }

  .sort-container {
    width: 100%!important;
  }

  .product-tile .product-name {
    margin-top: 10px;
  }

  .product-tile .product-name h2 {
    font-size: 14px!important;
    font-weight: normal;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .product-tile .product-pricing .product-discounted-price .product-sales-price {
  	color: $color-kipling-red;
  }

  .product-tile .product-swatches .swatch img {
    height: 12px;
    padding: 0;
    width: 12px;
    border: none;
  }

  .product-tile .product-swatches:hover ul.swatch-toggle {
    left: 0;
    right: 0;
  }

  .product-tile .product-swatches ul.swatch-toggle {
    position: relative;

    @media screen and (max-width: 500px) {
      width: 150px!important;
    }
  }

  .search-promo {
    padding: 0;
  }

  .pt_categoryLeftNav .back-to-top {
  	bottom: 45px !important;
  }

  .viewMoreLI {
    float: right;
    padding: 3px;
    .more-colors-link {
      margin: 0;
    }
    span {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}


.backPackTypeOfUse, .backPackColorTypeFour, .refinement.categoryList, .breadcrumb-refinement-value.categoryList {
	display:none;
}

.borderfree-enable {
  .smgt-button-wrapper, .post-header-banner, #minicart-banner, .price-refinement, .promo-block, .cart-couponcode, .orUseDivider, .paypal-button, .order-total.order-summary,  .order-subtotal, .order-shipping, .order-shipping-discount, .order-sales-tax, .sales-tax-message, .rColContainer.active-promotion, .order-discount, .coupon-code--mobile{
		display:none !important;    
	}
}