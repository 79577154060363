#main.decorator__full-width,
#main.decorator__full-width .container {
    padding: 0;
}
.backpack-finder--container {
    .backpack-finder--main-content {
        max-height: 0;
        overflow: hidden;
    }
    &.finder__active {
        .backpack-finder--start-content {
            max-height: 0;
            overflow: hidden;
        }
        .backpack-finder--main-content {
            max-height: 2000px;
        }
    }
}
.backpack-finder--step-item {
    font-family: Kipling-Light,Futura,sans-serif;
    text-align: center;
    font-size: 50px;
    margin: 50px 0;
}
.backpack-finder--container .slick-slide {
    outline: none;
}
.backpack-finder--select-wrapper {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;

    .backpack-finder-button-select {
        width: 20%;
        text-align: center;
        cursor: pointer;
    }
    &.select-wrapper--multi {
        flex-flow: row wrap;

        .backpack-finder-button-select {
            width: 27%;
            margin: 0 0 20px;
        }
    }
    .select--img__regular {
        display: block;
    }
    .select__active {
        display: none;
    }
    .js-selected {
        // .svg--body {
        //     fill: #fff;
        // }
        // .svg--bg {
        //     fill: #87af4a;
        // }
        .select--img__regular {
            display: none;
        }
        .select__active {
            display: block;
        }
    }
    .js-disabled {
        pointer-events: none;
        opacity: 0.4;
    }
}
.backpack-finder--select-img__wrapper {
    width: 100%;
    margin: 0 auto;
}
.backpack-finder--select-img__wrapper svg {
    width: 100%;
    height: 100%;
}
.backpack-finder--select-text__wrapper {
    font-size: 30px;
    margin: 20px 0;
}
.backpack-finder--controlls__wrapper {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.contolls--heading__wrapper {
    margin: 25px 0 20px;
}
.contolls--heading__wrapper a {
    font-size: 20px;
    font-family: Kipling-Regular,Futura,sans-serif;
    color: #fff;
    background-color: #2d2c2f;
    width: 150px;
    text-align: center;
    padding: 10px 0;
    display: inline-block;
    &:last-child {
        margin: 0 0 0 20px;
    }
    &.button--single {
        margin: 0 !important;
    }
}
.contolls--skip__wrapper {
    font-size: 20px;
    font-family: Kipling-Extrabold,Futura,sans-serif;
    color: #000;
    text-decoration: underline;
}
@media(max-width: 1920px) {
    .backpak-finder--carousel-container {
        padding: 0 15px;
    }
}
@include mobile {
    .backpack-finder--step-item {
        margin: 20px 0;
        font-size: 40px;
    }
    .backpack-finder--select-text__wrapper {
        margin: 10px 0;
        font-size: 23px;
    }
    .contolls--heading__wrapper {
        margin: 15px 0;
    }
    .backpack-finder--select-wrapper {
        flex-flow: row wrap;
        .backpack-finder-button-select {
            width: 45%;
        }
        &.select-wrapper--multi {
            .backpack-finder-button-select {
                width: 45%;
            }
        }
    }
    .contolls--heading__wrapper a {
        width: 130px;
        &:last-child {
            margin: 0 0 0 10px;
        }
    }
}
@media (max-width: 600px) {
    .contolls--skip__wrapper,
    .contolls--heading__wrapper a {
        font-size: 18px;
    }
    .backpack-finder--select-text__wrapper {
        font-size: 21px;
    }
    .backpack-finder--step-item {
        font-size: 35px;
    }
}

//TRAVEL FINDER
.backpak-finder--carousel-container.travelfinder-bg {
	background: url('../images/travel-finder/bg.jpg') no-repeat center center;
	background-size: cover;
	
	.backpack-finder--step-item {
		font-size: 3vw
	}
	
	.backpack-finder--select-wrapper .backpack-finder-button-select {
		width: 25%;
	}
	
	.backpack-finder--select-img__wrapper {
		width: 50%;
    	padding: 0 15%;
	}
	
	.backpack-finder--select-wrapper {
		justify-content: center;
	}
	
	.backpack-finder--select-text__wrapper {
		font-size: 1.5vw;
	}
	
	.contolls--heading__wrapper a {
		background: transparent;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		
		span {
			font-family: Kipling-Extrabold,Futura,sans-serif;
			font-size: 1.3vw;
    		font-weight: 700;
    		color: #000;
		}
		
		img {
			margin-top: 7px;
			width:7vw;
		}
	}
	
	.contolls--heading__wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		
		.js-backpack-finder-prev-button {
			margin-right: 30%;
		}
	}
	
	.slick-track {
		display: flex;
	}
	
	.slick-track .slick-slide {
		display: flex;
		height: auto;
	}
	
	.slick-slide {
		flex-direction: column;
    	justify-content: space-between;
	}
}

@include mobile {
	.backpak-finder--carousel-container.travelfinder-bg .backpack-finder--step-item {
		font-size: 6vw;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .backpack-finder--select-wrapper .backpack-finder-button-select {
		width: 50%;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .backpack-finder--select-img__wrapper {
		padding: 0;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .backpack-finder--select-text__wrapper {
		font-size: 4vw;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg {
		background: url(../images/travel-finder/bg-mob.jpg) no-repeat 50%;
    	background-size: cover;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .contolls--heading__wrapper a img {
		margin-top: 5px;
    	width: 20vw;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .contolls--heading__wrapper a span {
		font-size: 3.5vw;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .contolls--heading__wrapper {
		margin: 30px 0;
	}
	
	.backpak-finder--carousel-container.travelfinder-bg .backpack-finder--select-img__wrapper {
		width: 70%;
	}
}

.backpack-finder--select-img__wrapper img.active {
	display:none;
}

.js-selected .backpack-finder--select-img__wrapper{
	img.not-active {
		display:none;
	}
	img.active{
		display:block
	}
}