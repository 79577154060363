// Colors
$white: #fff;
$white-smoke: #f6f6f6;
$alabaster: #fcfcfc;
$black: #000;
$color-red-main: #eb0e1c;
$color-red-main--darker: #db0d1a;
// Old green color
$color-green: #428539;
$color-summer-sky: #17B5E5;
$color-green--darker: #408237;
$color-green--darkest: #387230;
$color-pdp-grid: #e7e7e7;
$color-footer-h3: #5c5b61;
$color-dove-grey: #707070;
$color-dark-grey: #2d2c2f;
$color-dim-grey: #666;
$color-alto-grey: #D3D3D3;
$color-lighter-grey: #ccc;
$color-light-grey: #bdbdbe;
$color-footer-right-block-text: $color-dark-grey;
$color-dark-white: #f1f2f1;
$color-pink-lace: #f5bedb;
$color-gainsboro: #e8e4e4;
$color-mercury: #e7e7e7;
$color-silver: #BDBBBB;
$color-silver-chalice: #A9A9A9;
$color-night-rider: #333;
$color-ghost: #c5c6c8;
$color-nobel: #b3b3b3;
$color-dark-nobel: #999;
$color-mystic: #e3e5e3;
$color-urban-black: #2d2f2c;
$color-urban-grey: #6c6a71;
$color-urban-white: #e1e1e1;
$color-curious-green: #88b04b;
$color-kipling-red: #c53b28;
$color-middle-grey: #D9D9D9;
$color-middle-light-grey: #757575;

//flags lib
$flag-icon-css-path: '../src/sass/libs/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;


// Fonts
$kipling-regular: 'Kipling-Regular', 'Futura', sans-serif;
$kipling-extrabold: 'Kipling-Extrabold','Futura',sans-serif;
$kipling-medium: 'Kipling-Medium', 'Futura', sans-serif;
$kipling-light: 'Kipling-Light', Futura, sans-serif;
$font-trade-gothic-extended:  'TradeGothicLTStd-Extended', Arial, sans-serif;

//mixins
@mixin full-width() {
  @media (min-width: 1300px) {
    @content;
  }
}

//transitions
$hover: 0.2s ease;
