.category-filter  {
  width: 100%;
  margin: 10px 0;
  position: relative;

  .toggle-box {
    background: #fff;
  }

  .toggle-item {
    display: inline-block;
    width: 50%;
    padding: 10px 0;
    float: left;
    text-align: center;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    &.active {
      border-bottom: 0;
    }

    &:first-child {
      border-right: 1px solid #ccc;
    }
  }

  .filter-box {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 15;
    display: none;
	background: rgba(255, 255, 255, 1);
    &.active {
      display: block;
    }
  }

  .filter-box-link {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    text-transform: uppercase;
    border-top: 1px solid #ccc;

    .filter-box-link-icon {
      display: inline-block;
      float: right;

      i:nth-child(2) {
        display: none;
      }
    }

    &.active {

      .filter-box-link-icon  {
        transform: rotate(180deg);
      }

      + .filter-box-container {
        display: block;
      }

    }
  }

  a.filter-box-link {
    color: #333;
    text-decoration: none;
    text-transform: uppercase;

    &.active {
      font-weight: 700;
      color: #000;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .filter-box-container {
    display: none;
    padding: 0px 10px 10px;

    &:nth-child(6) {
      border-bottom: 1px solid #ccc;
    }

    &.active {
      display: block;
    }

    a {
      display: inline-block;
      margin-top: 10px;
      margin-right: 3px;
      margin-bottom: 6px;
      padding: 0 10px;
      font-size: 12px;
      line-height: 24px;
      text-decoration: none;
      border-radius: 0;
      white-space: normal;
      color: #333;
      border: 1px solid #ccc;
    }
    
    a.active {
    	border: 1px solid black;
    }

  }
}

.filter-box-filter {

  .filter-box-link {

    &:first-child {
      border-top: 0;
    }

    &:nth-child(5) {
      border-bottom: 1px solid #ccc;

      &.active {
        border-bottom: 0;
      }
    }

  }

}


@media screen and (max-width: 960px){
	.filter-clear-price {
	    width: 100%;
	    display: block;
	    padding: 10px 40px 10px 40px !important;
	    position: relative !important;
	    left: 0px !important;
	    margin: 0px;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 11px;
	    line-height: 11px;
	    text-align: center;
	    float: left !important;
	}
	.category-filter .filter-box-container a.swatch-Color {
		width : 25px !important;
		height : 25px !important;
		border-radius: 50%;
		
	}
	
}
