.gift-finder--container {
	margin: 0 auto 4%;
	.contolls--heading__wrapper {
		text-transform: uppercase;
		a {	
			min-width: 210px;
		}
	}
	.contolls--skip__wrapper {
		font-family: Kipling-Regular, Futura, sans-serif;
	}
	.backpack-finder-button-select {
		font-family: Kipling-Light,Futura,sans-serif;
		letter-spacing: 0.05em;
	}
	.backpack-finder--select-img__wrapper {
		img {
			border-radius: 50%;
			max-width: 288px;
			margin: 0 auto;
			display: block;
		}
	}
	.js-selected {
	    img {
	 		border: 2px solid #2d2c2f;
	 	}   
	}
	[data-url-attr-name="category-url"] {
		.backpack-finder--select-text__wrapper {
			font-size: 24px;
		}
	}
	[data-url-attr-name="price"] {
		.backpack-finder--select-text__wrapper {
			white-space: nowrap;
		}
	}
}

@media screen and (min-width: 961px) {
	.gift-finder--container {
		.backpack-finder--select-wrapper {
			column-gap: 40px;
			.backpack-finder-button-select {
				flex: 1;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.gift-finder--container {
		margin-bottom: 40px;
		.contolls--heading__wrapper {
			a {	
				min-width: 0;
			}
		}
		.backpack-finder--select-wrapper {
			justify-content: center;
			row-gap: 40px;
			.backpack-finder-button-select {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 20px;
				box-sizing: border-box;
			}
		}
		[data-url-attr-name="price"] {
			.backpack-finder--select-text__wrapper {
				white-space: normal;
			}
		}
	}
}


@media screen and (max-width: 600px) {
	.gift-finder--container {
		[data-url-attr-name="category-url"] {
			.backpack-finder--select-text__wrapper {
				font-size: 18px;
			}
		}
	}
}


/*
.gift-finder--container {
	.contolls--heading__wrapper {
		margin: 0;
		
		a {
			padding: 0;
			width: 125px;
			background: none;
			
			@media only screen and (max-width: 960px) {
				width: 100px;
			}
		}
	}
	
	.backpack-finder--select-text__wrapper {
		font-size: 24px;
	}
	
	.js-gift-finder-step-to-check.loader.slick-current {
		opacity: .6!important;
		transition: .2s ease-in-out all;
		pointer-events: none;
	}
	
	.backpack-finder--select-wrapper {
		justify-content: center;
		
		.backpack-finder-button-select {
			width: 23%;
			flex: 0 0 23%;
			margin-right: 2%;
			
			&:last-child {
				margin-right: 0;
			}
			
			&.js-disabled {
				opacity: 0.6;
				filter: grayscale(100%);
			}
			
			img.elm-width__100 {
				width: 80%;
				margin: 0 auto;
			}
			
			@media only screen and (max-width: 960px) {
				width: 45%;
				flex: 0 0 45%;
				margin: 2.5%;
			}
		}
	}
	
	.backpack-finder--step-item {
		@media only screen and (max-width: 600px) {
			font-size: 25px;
		}
	}
	
	.backpack-finder--select-text__wrapper {
		@media only screen and (max-width: 600px) {
			font-size: 16px;
		}
	}
}

.hidden-gift-more {
    display: none!important;
}

.js-load-more-gifts {
    display: block;
    width: 100%;
    text-align: center;
    margin: 30px 0;
}

.gift-finder-load-more {
    max-width: 60%;
}

.gift-finder__reset-button.bp-finder__reset-button {
    position: absolute;
    top: 10px;
    right: 40px;
    font-size: 21px;
    font-family: Kipling-ExtraBold, Futura, sans-serif;
}

.search-result-content.gift-finder-btn-added {
	padding-top: 45px;
}

@media (max-width: 960px) {
    .gift-finder__reset-button.bp-finder__reset-button {
        //top: -108px;
        font-size: 20px;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
    }
}
*/