.rowcoupons .item-details .remove-coupon-code, .js-remove-coupon-code, .js-remove-coupon-checkout {
    font-size: 13px;
    color: $color-urban-grey;
    text-transform: uppercase;
    text-decoration: underline;
    line-height: normal;
    letter-spacing: 0.025em;
    cursor: pointer;
    cursor: pointer;
    text-decoration: underline;

    &:hover{
      color: $color-urban-white;
    }
}
.address-illigal {
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 20px !important;
}

.pt_checkout {

  .button-fancy-large, .button-fancy-medium {
    height: 40px;
    font-family: $kipling-regular;
    padding: 2px 0 0px;
    border-radius: 10px;
    font-size: 17px;
    letter-spacing: 0.03em;
  }

  .button-fancy-large {
    width: 300px;
  }

  .button-fancy-medium {
    width: 150px;
  }

  &>.container {
    width: 100%;
    max-width: 1370px;

    @include mobile {
      padding: 0;
    }
  }

  .checkout-step-title {
    margin: 20px 0 0;
    font-size: 24px;
    letter-spacing: 0.05em;
    background: $color-urban-white;

    @include mobile {
      margin-top: 15px;
    }

    a, p {
      padding: 13px 20px 12px;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      color: $color-urban-grey;
    }

    &.active {
      a, p {
        color: $black;
      }
    }
  }

  .primary-focus {
    .primary-content {
      width: 69.7%;

      @include mobile {
        padding-bottom: 0;
      }
    }
    #secondary {
      width: 27.5%;
      margin: 88px 0 0;
    }
  }

  /* checkout sidebar basket */
  .sidebar-basket {
    padding: 10px;

    @include mobile {
      padding: 20px;
    }

    .sidebar-basket-items {
      display: none;
    }

    h2.js-sidebasket-toggle {
      font-size: 20px;
      font-family: $kipling-regular;
      text-transform: none;
      color: $black;
      letter-spacing: .03em;
      font-weight: 400;
      margin: 0;
      padding: 0 25px 0 0;
      cursor: pointer;
      display: inline-block;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        right: 6px;
        top: 8px;
        display: block;
        width: 7px;
        height: 7px;
        box-sizing: border-box;
        border-right: 1px solid #2d2f2c;
        border-bottom: 1px solid #2d2f2c;
        transform: rotate(-45deg);
      }

      &.active {

        &+.sidebar-basket-items {
          display: block;
        }

        &::after {
          transform: rotate(45deg);
          top: 6px;
          right: 4px;
        }
      }
    }

    div {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .sidebar-basket-product {
      display: flex;
      align-items: center;
      margin: 20px 0;

      picture {
        display: block;
        width: 35.5%;

        @include mobile {
          width: 45%;
        }

        img {
          width: 100%;
          @include mobile {
            width: 100%;
          }
        }
      }

      img {
        display: block;
        aspect-ratio: 1;
        width: 35.5%;

        @include mobile {
          width: 45%;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        width: 64.5%;
        padding: 0 10px;
        font-size: 14px;
        color: $color-urban-grey;
        letter-spacing: 0.05em;

        @include mobile {
          width: 55%;
        }

        .name {
          margin: 5px 0;
          font-size: 17px;
          color: $color-dark-grey;
        }

        .price {
          margin: 5px 0;
        }

        .monogrammed {
          display: inline-block;
          float: right;

          img {
            width: 16px;
            display: inline-block;
          }
        }

        .gift .price {
          display: block;
          margin: 10px 0 0;
          font-size: 14px;
        }

        .total {
          margin: 10px 0 0;
          font-size: 14px;
        }

        .price-divider {
          margin: 10px 0 0;
          width: 60%;
        }
      }

      .gift {
        margin: 10px 0 0;

        .label {
          display: block;
          padding: 0 0 0 20px;
          background: url("../images/kip_gift_box_icon.png") no-repeat 2px 0px;
        }
      }

      ul.product-availability-list li {
        color: $color-night-rider;
        font-family: $kipling-regular;
        font-size: 12px;
        font-weight: normal;
      }

      .sidebar-item-details {
        .item-details-container {
          display: none;

          .label {
            width: 87px;
            display: inline-block;
            box-sizing: border-box;
            margin: 5px 0;
            padding-right: 5px;
          }

          .value {
            display: inline-block;
            box-sizing: border-box;
            margin: 5px 0;
            width: calc(100% - 92px);

            @include mobile {
              text-align: right;
            }
          }

          .product-availability-list {
            margin: 5px 0;

            li {
              margin: 0;
              font-size: 14px;
              color: $color-urban-grey;
            }
          }
        }

        .item-details-btn {
          display: inline-block;
          position: relative;
          margin: 5px 0;
          padding: 0 20px 0 0;
          cursor: pointer;

          &::after {
            content: " ";
            position: absolute;
            right: 10px;
            top: 6px;
            display: block;
            width: 6px;
            height: 6px;
            box-sizing: border-box;
            border-right: 1px solid #2d2f2c;
            border-bottom: 1px solid #2d2f2c;
            transform: rotate(-45deg);
          }
        }
      }

      &.expanded {
        align-items: flex-start;
        .sidebar-item-details .item-details-btn {
          &::after {
            transform: rotate(45deg);
            top: 4px;
            right: 8px;          
          }
  
          &+.item-details-container {
            display: block;
          }
        }
      } 
    }

    ul.product-availability-list li {
      color: $color-night-rider;
      font-family: arial;
      font-size: 12px;
    }
  }

  .checkout-summary-container .sidebar-basket {
    max-width: 375px;
    margin-bottom: 15px;
  }

  .sidebar-shipping {
    padding: 25px 20px 5px;
    letter-spacing: 0.03em;

    .sidebar-details {
      line-height: 20px;
      font-size: 16px;
      color: $color-urban-grey;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;

      &.sidebar-shipping-name {
        font-size: 18px;
        padding-bottom: 5px;
        color: $black;
      }

      &.sidebar-shipping-method {
        padding-top: 15px;

        span {
          color: $black;
        }

        .item-delivery, .item-delivery-test-info {
          display: inline;
          margin-top: 0px;
          font-size: 16px;
          line-height: normal;

          br {
            display: none;
          }

          span {
            color: $color-urban-grey;

            &:first-child {
              display: none;
            }
          }
        }
      }

      .content-asset {
        display: inline;
      }
    }
    .sidebar-edit-details {
      float: right;
      color: $color-urban-grey;
      font-size: 16px;
      text-decoration: underline;

      &:hover {
        color: $color-urban-white;
      }
    }

    .payment-giftsert {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 20px;

      @include mobile {
        align-items: flex-end;
        justify-content: space-between;
      }
  
      .payment-giftsert-remove {
        position: absolute;
        right: 0;
        top: 0;
        color: $color-urban-grey;
        font-size: 16px;
        text-decoration: underline;
        outline: 0;

        &:hover {
          color: $color-urban-white;
        }
      }

      .payment-giftsert-title {
        flex: 0 0 360px;
        max-width: 360px;
        font-size: 18px;
        line-height: 24px;

        @include mobile {
          flex: 0 0 auto;
          max-width: none;
        }

        > i {
          font-style: normal;
          display: none;
          @include mobile {
            display: inline;
          }
        }

        > span {
          font-size: 14px;
          line-height: 22px;
          @include mobile {
            display: block;
            font-size: 15px;
            margin-top: 4px;
          }

          > i {
            font-style: normal;
            @include mobile {
              display: none;
            }
          }
        }
      }

      .payment-giftsert-value {
        flex: 1 1 auto;
        min-width: 0;
        font-size: 16px;
        line-height: 22px;
        @include mobile {
          flex: 0 0 auto;
          max-width: none;
          margin-top: 4px;
        }
      }
    }

    .payment-instrument {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      position: relative;

      .payment {
        width: 45px;
        height: 31px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: left center;
        &.method-Visa {
          background-image: url(../images/checkout/visa.jpg);
        }
        
        &.method-MasterCard {
          background-image: url(../images/checkout/mastercard.jpg);
        }
        
        &.method-Amex {
          background-image: url(../images/checkout/americanexpress.jpg);
        }
        
        &.method-Discover {
          background-image: url(../images/checkout/discover.jpg);
        }
        
        &.method-PayPal {
          background-image: url(../images/checkout/paypal.png);
        }
        
        &.method-GoogleWallet {
          background-image: url(../images/svg/google-pay.svg);
        }
      }

      .sidebar-details {
        color: $color-urban-black;

        &.sidebar-payment-date {
          flex: 0 0 110px;
          max-width: 110px;

          @include mobile {
            flex: 1 1 auto;
            min-width: 0;
            max-width: none;
            font-size: 13px;
            color: #6c6a71;
            white-space: nowrap;
          }

          @include mobile-xxs {
            display: none;
          } 

          > span {
            display: none;

            @include mobile {
              display: inline;
            }
          }
        }

        &.sidebar-payment-method {
          flex: 0 0 45px;
          max-width: 45px;
          margin-right: 20px;
        }

        &.sidebar-payment-number {
          flex: 0 0 185px;
          max-width: 185px;

          @include mobile {
            flex: 0 0 auto;
            max-width: none;
            margin-right: 12px;
          }

          @include mobile-xxs {
            flex: 1 1 auto;
            min-width: 0;
          }
          /*
          @include mobile {
            display: flex;
          }

          .sidebar-payment-number-date {
            display: none;
            @include mobile {
              display: inline;
              margin-left: 12px;
              font-size: 14px;
              color: #6c6a71;
            }
          }
          */
        }

        &.sidebar-payment-value {
          flex: 1 1 auto;
          min-width: 0;

          @include mobile {
            flex: 0 0 auto;
          }

          .dollar-value {
            float: none;
          }
        }

        img {
          display: block;
          width: auto;
        }

        @include mobile {
          margin: 0;
        }
      }
    }
  }

  h1 {
    margin-bottom: 15px;
  }

  #main {
    padding: 65px 25px 0;

    @include mobile {
      padding: 0;
    }
  }

  #donation-confirmation-wrapper {
    margin: 20px 0 0;

    @include mobile {
      margin-bottom: 10px;
    }
  }

  #navigation .home {
    margin: 0;
  }

  #header .container.container-header {
    padding: 0 0 0 25px;
    max-width: 1370px;
    width: 100%;
  }

  .breadcrumb>li {
    margin-left: 0;
  }

  .checkout-step-title {
    &.shipping + .sidebar,
    &.payment + .sidebar {
      @include mobile {
        .sidebar-shipping {
          padding: 20px 20px 0;
          background: none;
          position: relative;
  
          .sidebar-header {
            display: none;
          }
          .sidebar-details:not(:nth-child(2)) {
            font-weight: 100;
          }
        }
      }
    }

    &.payment + .sidebar {
      .sidebar-edit-details {
        float: none;
        position: absolute;
        right: 0;
        top: 0;
        outline: 0;

        @include mobile {
          top: auto;
          right: 20px;
        }
      }
    }
  }

  .select2-container .select2-choice abbr {
    display: none !important;
  }

  .chechout-user-signin {
    font-size: 18px;
    text-decoration: underline;
    color: $color-urban-black;
    display: inline-block;
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 8px;
    }
    
    &:hover {
      color: $color-urban-white;
    }
  }

  .arrow_box {
    position: relative;
    padding: 0px 20px 30px 0;
    margin-bottom: 20px;
    font-size: 16px;

    .existing-account-msg {
      display: none;
      color: $color-urban-black;

      &.active {
        display: block;
        position: relative;
        top: -7px;
        font-size: 13px;
        margin-left: 8px;
      }
    }

    &.billing-enter-address {
      margin-bottom: 0;
      padding-bottom: 0;
      
      &.address-is-hidden {
      	margin: -20px 0 10px 0;
      	legend,
      	#billing-address-form,
      	.same-address-container,
      	.select-address,
      	.shippingtoggle {
      		display: none;
      	}
      }
    
      legend {
        padding: 0 0 0 5px;

        &.billingAddressHeader {
          padding-bottom: 20px;
        }
      }

      #billing-address-form {
        .add-address-header, .edit-address-header {
          padding: 0 0 10px 5px;
        }
      }
    }
  
    @include mobile {
      padding: 0;
      border: none;
      margin: 10px 0 0;
    }

    .form-row {
      width: 64%;
      min-width: 560px;

      &.no-show {
        display: none;
      }

      .month, .year {
        width: 200px;
        margin-right: 33px;
        min-width: auto;
      }

      @include mobile {
        width: 100%;
        min-width: auto;
        position: relative;

        &.expiration {
          padding-bottom: 0;
        }

        .month {
          width: 51%;
          margin-right: 4.5%;
        }

        .year {
          width: 44.5%;
          margin-right: 0;
        }
      }
    }

    @include desktop {
      &.border_bottom-dt {
        border-bottom: 1px solid $color-urban-white;
      }
    }

    &.padding_top {
      padding: 20px 0 0;
      margin: 0;
    }

    &.select-address {
      margin-bottom: 0;
      padding-bottom: 0;

      @include mobile {
        margin-top: 0;
      }
      
      label {
        font-size: 17px;
        padding-left: 0;
        text-transform: none;

        @include mobile {
          padding-top: 5px;
        }
      }

      .form-row {
        min-width: auto;
        width: auto;

        @include mobile {
          width: 100%;
        }

        &.form-row-button {
          display: none;
        }
      }
    }
  }

  .payment-fieldset {
    .select-creditcard {
      padding-bottom: 10px;

      @include mobile {
        padding-bottom: 0;
      }
    }

    .arrow_box {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  .redemption {
    margin: 10px 0;

    span.error {
      margin-left: 0;
      background-color: $white !important;
    }
  }

  .checkout-billing .gift-card {
    padding: 0 20px 0 0;
    margin: 0;

    @include mobile {
      padding-right: 0;
    }

    .success.giftcert-pi {
      padding-top: 5px;

      @include mobile {
        padding-top: 10;
      }

      &:not(:first-child) {
        padding-top: 15px;
      }
    }

    .gc--table__container, .form-row-container {
      width: 64%;
      min-width: 560px;
      display: flex;
      justify-content: space-between;

      &::after {
        display: none;
      }

      @include mobile {
        width: 100%;
        min-width: auto;
      }
    }

    @include mobile {
      .form-row-container {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .gc--table__container {

      }
    }

    .redemption .error {
      margin: 8px 0;
    }
    
    .gc--amount__wrapper,
    .gc--number__wrapper {
        color: $color-urban-grey;
    }
    
    .gc--amount__wrapper {
      width: calc(100% - 327px);
      padding-left: 25px;
      box-sizing: border-box;
      position: relative;

      .remove {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        color: $color-urban-grey;
        font-size: 16px;
        text-decoration: underline;
    
        &:hover {
          color: $color-urban-white;
        }

        @include mobile {
          .remove-gc-icon {
            display: block;
            width: 23px;
            height: 23px;

            &::before {
              content: "";
              display: block;
              position: relative;
              top: 11px;
              left: 4px;
              height: 1px;
              width: 15px;
              background-color: $color-urban-grey;
              transform: rotate(45deg);
            }

            &::after {
              content: "";
              display: block;
              position: relative;
              top: 10px;
              left: 4px;
              height: 1px;
              width: 15px;
              background-color: $color-urban-grey;
              transform: rotate(-45deg);
            }
          }
        }
      }

      @include mobile {
        width: 33%;
        padding-left: 0;
      }
    }

    .gc--number__wrapper {
      width: 327px;
  
      @include mobile {
        width: 67%;
      }
    }
    
    .gc--amount__header,
    .gc--number__header {
      text-decoration: underline;
      font-size: 16px;
      font-family: $kipling-regular;

      @include mobile {
        font-size: 14px;
      }
    }
    
    .gc--number__n,
    .gc--amount__a {
      margin: 15px 0 0;
      font-size: 16px;
      font-family: $kipling-regular;
      color: $color-urban-grey;
    }

    .form-row.gift-card-number {
      width: 327px;
      margin-right: 0;

      @include mobile {
        width: 100%;
      }
    }

    .form-row.gift-card-pin {
      width: calc(100% - 327px);
      padding-left: 25px;
      box-sizing: border-box;
      margin-right: 0;

      @include mobile {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  #gift-certificate-form.active {
    border-bottom: 1px solid $color-urban-white;
    margin-top: 15px;
  }

  .error-by-js-checkout {
    font-size: 12px;
    color: $color-urban-grey;
    margin-left: 8px;
    letter-spacing: 0.05em;
  }

  #default-address {
    border-bottom: 1px solid $color-urban-white;
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 0;
    }
  }

  .form-field-tooltip.with-helper-text {
    margin: 0 0 10px 8px;
    letter-spacing: 0.05em;

    p {
      font-size: 13px;
      color: #6c6a71;
      margin-bottom: 5px;
    }

    a {
      color: $color-urban-grey;

      > span {
        text-decoration: underline;
      }
    }
  }

  .email-list {
    label.fancyCheck {
      margin: 20px 0 0;
      font-size: 15px;
      width: auto;
      max-width: 300px;
      padding-left: 35px;
      text-transform: none !important;

      &::before {
        top: 7px;
        transform: none;
        left: 5px;
      }

      &::after {
        top: 17px;
        left: 9px;
      }

      @include mobile {
        margin-top: 15px;
        padding-bottom: 0;
      }
    }
  }

  .address .emailNote {
    font-size: 14px;
    color: $color-dim-grey;
    padding-left: 35px;

    a {
      color: $color-urban-grey;
      font-size: 13px;
      text-decoration: underline;
      display: inline-block;
    
      &:hover {
        color: $color-urban-white;
      }
    }
  }

  legend {
    font-family: $kipling-regular;
    color: $black;
    text-transform: none;
    letter-spacing: 0.03em;
    padding-top: 5px;

    @include mobile {
      font-size: 18px;
      padding: 8px 0 0;

      &.billingAddressHeader {
        padding: 0 0 10px;
      }
    }
  }

  .address .shipping-methods-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @include mobile {
      justify-content: space-between;
      margin-top: 15px;
    }

    .form-row {
      width: auto;
      margin: 0 10px;
      text-align: center;

      @include mobile {
        margin: 0 auto;
        width: 30%;
      }

      label.fancyCheck {
        width: 90px;
        height: 90px;
        margin: 15px auto 10px;
        padding: 0;
        color: $color-urban-black;
        background-color: #f0f0f0;
        text-align: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px;
        font-family: $kipling-extrabold !important;
        text-transform: uppercase !important;
        letter-spacing: 0.02em;

        span {
          font-size: 14px;
        }
      }

      input.fancyCheck:checked+label {
        background-color: #333333;
        color: white;
      }

      input.fancyCheck:checked+label:after, label.fancyCheck:before {
        content: none;
      }
    }
  }

  #shipping-method-list {
    padding-bottom: 15px;

    @include mobile {
      margin-top: 15px;

      fieldset {
        padding-bottom: 5px;
      }
    }

    .form-caption {
      margin: 0 auto;
      max-width: 140px;
      text-align: left;
      letter-spacing: 0.02em;
      
      @include mobile {
        max-width: none;
        font-size: 11px;
      }
    }

    .shipping-promos {
      max-width: 140px;
      font-size: 12px;
      color: $color-kipling-red;
      margin: 5px auto 0;
      text-align: left;

      @include mobile {
        max-width: none;
        font-size: 11px;
      }
    }
  } 

  .form-row-continue {
    margin: 0;
    width: 100%;
    padding-bottom: 0;
    display: block;

    @include mobile {
      text-align: center;
    }
  }

  #shipping-address-buttons,
  #billing-address-buttons {
    @include mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .button-fancy-medium:first-of-type {
      margin-right: 20px;
    }
  }

  &#wrapper {
    .input-text[type=text], .select2-container, .input-text-pw {
      width: 100%;
      height: 42px;
      border: 2px solid $color-urban-white;
      border-radius: 10px;
      padding-left: 16px;
      font-size: 16px;
      box-sizing: border-box;
      margin-bottom: 10px;

      &::placeholder {
        color: $color-urban-black;
        letter-spacing: 0.05em;
      }

      @include mobile {
        height: 42px;
        border: 2px solid $color-urban-white;
      }
    }

    .input-text-pw.cvn {
      width: 160px;
      padding-top: 0;

      @include mobile {
        padding: 0 10px 0 16px;
      }
    }

    .select2-container .select2-choice {
      padding: 0 48px 0 0;
      height: 38px;
      line-height: 38px;

      .select--caret.custom-chevron {
        width: 48px;
        box-sizing: border-box;
        border-left: 2px solid $color-urban-white;
        height: calc(100% - 8px);
        right: 0;
        top: 4px;

        &.bottom:before {
          top: calc(50% - 2px);
          left: 50%;
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
    }

    .select2-container-active .select2-choice {
      background-color: transparent;
    }

    .form-row .styled-select {
      @include mobile {
        margin-bottom: 10px;
      }
    }

    .form-row select {
      border: 2px solid $color-urban-white;
      border-radius: 10px;
      height: 42px;
    }

    .styled-select {
      &::before {
        width: 48px;
        box-sizing: border-box;
        border-left: 2px solid $color-urban-white;
        height: calc(100% - 12px);
        top: 6px;
        right: 0;
      }

      &::after {
        right: 18px;
      }
    }

    .shipping-methods-container {
      .form-caption {
        text-align: left;
        font-size: 12px;

        .content-asset {
          display: inline;
        }
      }

      .form-row label.fancyCheck {
        width: 90px;
        cursor: pointer;
      }
    }
  }

  .flex-pmethods-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .google-container .gpay-button.new_style {
      padding: 8px 0 5px;
      min-height: 40px;
      font-size: 20px;
      border-radius: 4px;
      min-width: 100px !important;
    }

    @include mobile {
      flex-wrap: wrap;
      align-items: flex-start;

      &.payment-methods .form-row {
        margin-bottom: 20px;
        max-width: 127px;
        margin-right: 10px;
      }
    }
  }

  .address .form-row.CREDIT_CARD-method {
    margin: 10px 0 30px;
    width: 100%;

    @include mobile {
      margin-bottom: 0;
    }

    label.fancyRadio {
      margin-top: 10px;
      padding: 0 0px 0 34px;
      display: inline-block;
    }
  }

  .bth--gift-cards {
    font-size: 15px;
    margin: 20px 0 10px;
    padding: 0 20px 0 0;
    cursor: pointer;
    display: inline-block;
    position: relative;
    letter-spacing: 0.05em;

    @include mobile {
      padding-left: 0;
      margin: 0;
    }

    &::after {
      content: " ";
      position: absolute;
      right: 8px;
      top: 5px;
      display: block;
      width: 7px;
      height: 7px;
      box-sizing: border-box;
      border-right: 1px solid $color-urban-black;
      border-bottom: 1px solid $color-urban-black;
      transform: rotate(-45deg);
    }

    &.active::after {
      transform: rotate(45deg);
      top: 3px;
      right: 6px;
    }
  }

  .gc-applied-rewards-header {
    font-size: 15px;
    margin: 10px 0 10px;
    display: inline-block;
    position: relative;
    letter-spacing: .05em;
  }

  @include mobile {
    #secondary {
      display: flex;
      flex-direction: column;
    
      > .sidebar-basket-wrapper {
        order: 1;
      }

      > .summary {
        order: 2;
        padding: 0 20px;
      }

      > .cart-coupon-code {
        order: 3;
        width: calc(100% - 40px);
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 40px);
          top: 0;
          left: 20px;
          border-top: 1px solid $color-urban-grey;
        }
      }
    }
  }

  .address label {
    padding-left: 5px;
  }
  
  .address .shippingtoggle {
    margin-top: 10px;

    .fancyCheck::before {
      margin-top:-12px;
    }

    label {
      display: inline-block;
      margin: 13px 0 10px;
      padding: 2px 0 2px 34px;
      cursor: pointer;
      font-size: 15px;
      text-transform: none;

      @include mobile {
        margin: 7px 0;
      }
    }

    @include mobile {
      margin-top: 10px;
    }
  }

  .card-type--select {
    display: none !important;
  }

  .payment-cc-formfield.cc-buttons {
    margin: 15px 0 0;

    @include mobile {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &::after {
        display: none;
      }
    }

    @include desktop {
      #cancel-cc-button {
        margin-right: 20px;
      }
    }
  }

  .fancyRadio:before {
    width: 18px;
    height: 18px;
    left: 2px;
    box-sizing: border-box;
    box-shadow: 0 0 0px 2px #6c6a71;
  }

  .useShippingAddress, .suggested-addresses, .saved-address {
    letter-spacing: 0.03em;

    .address-value-first {
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 5px;
      color: $black;
    }
    
    .address-value {
      font-size: 16px;
      line-height: 20px;
      color: $color-urban-grey;
    }
  }

  .saved-address{
    margin: 20px 0;

    @include mobile {
      margin: 15px 0 10px;
    }
  }

  .useShippingAddress {
    max-width: 335px;

    label {
      margin-top: 10px;
      text-transform: none;
    }
  }

  .same-address-container {
    padding: 15px 0 25px 0;
    border-bottom: 1px solid;

    &.no-border {
      border-bottom: none;
      padding-bottom: 15px;
    }
  }

  .cart-sidebar-slot {
    margin-top: 20px;
  }

  .order-totals-table {
    width: 100%;
  }

  #secondary .place-order-container {
    order: 3;
    padding: 0 20px;

    .cart-order-totals {
      padding: 20px 0;
      background: #f0f0f0;
      text-align: center;
      margin-bottom: 20px;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 40px);
        top: 0;
        left: 20px;
        border-top: 1px solid $color-urban-grey;
      }

      #cart-order-checkout .button-fancy-large {
        width: 300px;
        height: 40px;
        font-family: $kipling-regular;
        padding: 2px 0 0;
        margin: 0 auto;
        border-radius: 10px;
        font-size: 17px;
        letter-spacing: .03em;
      }
    }

    .cart-footer .submit-order {
      margin: 0;
    }
  }

  #add-address-form legend {
    margin-bottom: 0;
    padding-top: 0;
  }

  #default-address legend {
    margin-bottom: 20px;
    padding-top: 0;
  }

  .select-creditcard label {
    padding: 0 0 5px 5px;
  }

  #billing-payment-actions {
    margin-top: 10px;
  }
}

@media screen and (min-width: 960px) {
  #checkout-home-link {
    display: none;
  }

  .pt_checkout {

    .shipping-address-actions {
      margin-top: 12px;
    }

    .post-header-banner, #pre-header-banner {
      display: none;
    }

    #pre-header li {
      &#select--country, &#storeLocator, &#kipling--rewards, &#talkable-offer-button, &#myAccountLink, &#mini-wishlist, &#mini-cart, #header-search {
        display: none;
      }

      &#checkout-home-link {
        display: inline-block;
        flex-grow: 1;
        font-size: 18px;

        a:hover {
          color: $color-urban-white;
        }
      }
    }

    #secondary .summary .cart-secondary-module.cart-sidebar-help {
      z-index: 2001;
    }

    #pre-header .systemControls {
      height: 108px;
      text-transform: none;

      li#logo-wrapper a.home {
        padding: 0;

        img {
          height: 70px;
          display: block;
        }
      }
    }
  }
}

.pt_checkout .sidebar-basket .sidebar-basket-product .details .price-promotion,
.pt_order-confirmation .sidebar-basket .sidebar-basket-product .details .price-promotion {
  color: $color-urban-grey;
}

.pt_checkout .sidebar-basket .sidebar-basket-product .details .price-standard,
.pt_order-confirmation .sidebar-basket .sidebar-basket-product .details .price-standard {
  color: $color-urban-black;
  text-decoration: line-through;
}
.checkoutLogin {
    input.email-input, input.password-input {
      border: 1px solid #000 !important;
      border-radius: 0 !important;
    }
    h1 {
      text-transform: initial;
      font-size: 35px;
      font-weight: 400;
      color: $color-urban-black;
      @include mobile {
      font-size: 25px;
    }
    }
    p {
      font-family: Kipling-Regular,Futura,sans-serif;
      color: $color-urban-black;
    }
    button {
      padding: 5px 30px 0px;
      font-size: 18px;
      height: 40px;
      border-radius: 0;
    }
    h2.section-header {
      display: none;
    }
    .form-row > label {
      @include mobile {
        font-size: 18px;
        color: $color-urban-black;
      }
    }

    .login-box .login-box-content p {
      font-family: Kipling-Regular,Futura,sans-serif;
      color: $color-urban-black;
    }
    .login-create-account {
      h1 {
        @include mobile {
        float: left;
      }
      }
      p {
        @include mobile {
        clear: both;
      }
      }
    }
    .col-1, .col-2 {
      @include mobile {
      padding: 0 20px;
    }
    }
  }
.checkout--buttons-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}

// #cart-order-checkout .checkout--buttons-container button {
//   @include mobile {
//     background: inherit;
//   }
// }

.checkout--buttons-container form {
  width: 100%;
  max-width: 30%;
  margin: 0 5% 0 0;
}

.checkout--submit-button {
  width: 100%;
}

.checkout--paypal-logo {
  background: url(../images/paypal-logo.svg) no-repeat;
  background-size: 100%;
  height: 40px;
  background-position-y: 50%;
  max-width: 150px;
  margin: 0 auto;

}

.dw-apple-pay-button {
  max-width: 100% !important;
  min-width: 150px !important;
  margin: 0 auto 15px;
  display: block;
  height: 40px;
  border-radius: 10px !important;

  @include mobile {
    margin: 0 auto 15px;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 960px) {
  .pt_cart .primary-focus #secondary .rColContainer {
    display: flex;
    flex-direction: column;
  }

  #secondary .cart-continue-shopping-header {
    order: 1;
  }

  #secondary .cart-coupon-code {
    order: 2;
    width: 100%;
    margin: 0 auto 5px;
  }

  #secondary .cart-order-totals {
    order: 3;
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px 15px;
    border-bottom: none;
  }

  #secondary .cart-express-checkout {
    order: 4;
    margin: 15px 0 0;
  }

  .pt_checkout #header .container.container-header {
    padding: 0 10px;
  }

  .checkout-shipping.address {
    padding: 15px 20px 0;
  }

  .checkout-billing.address {
    padding: 20px 20px 0;
  }
  .address .form-row-continue button {
   margin:0;
   padding:0;
  }

  #monetate_endcap_58eb0838_0.monetate-endcap.horizontal .top {
      position: relative !important;
      left: 0 !important;
      background-position: center;
      background-repeat: no-repeat;
      width: 100% !important;
    height: 50px;
  }
  #monetate_endcap_58eb0838_0.monetate-endcap.outer {
    width : 100%;
  }
  #monetate_endcap_58eb0838_0 .cartridge {
    margin:0px;
  }
  #monetate_endcap_58eb0838_0.monetate-endcap.horizontal .item {
    width : 130px;
  }

  .price-label, .total-label {
    display:block;
    text-transform: uppercase;
  }
  .giftInfo {
    .label {width : 100% !important;display:inline;    margin-right: 5px;}
  }
  .content-asset p {
      line-height: 22px;
  }
  .cart-action-continue-shopping fieldset {
    text-align : left;
  }

  .login-box-content2 p, .login-box .login-box-content p, #primary .login-box-content label.fancyCheck,
  #primary .login-box-content.returning-customers #password-reset, .address .form-caption, .thank-you-message, .cart-empty, .button-continue-shopping {
    font-family: Kipling-Regular, Futura, sans-serif;
  }

  .checkout-mobile-quantity {
    margin : 0 0 5px 0;
    .fa {
      color : #333333;
    }
  }

  #cart-order-checkout .button-continue-shopping {
    margin-top: 20px;
  }
  #cart-order-checkout .button-fancy-large {
    background: $color-dark-grey;

    &:hover, &:focus {
      background: $color-urban-white;
      color: $black;
    }
  }
  #PaymentMethod_CREDIT_CARD legend {
    padding: 0 !important;
  }

  .pt_checkout h1 {
    display : none;
  }
  .pt_checkout .breadcrumb {
    display : none;
  }
  .checkout-shipping.address,
  .checkout-billing.address {
    background: #fff;
  }

  input {
    color : #333;
  }
  .select2-results .select2-highlighted {
      background: #bbb9b9;
  }
  .select2-container a.select2-choice span, .cart-coupon-code .ovlb .ovlbLabel {
    color : #333;
  }
  #secondary {
    .toggleMyAccount {
      color : #333;
    }
  }
  .bonus-discount-container .buttonbar{
      display: inline-flex;
      button {
        margin: 0 auto;
      }
  }

  .select-bonus, #update-cart, #cancelBtn, #add-donate, #deleteBtn,
  .formbuttonrow button, .form-row-button button, .return-to-shopping,
  #RegistrationForm button, .select-bonus-btn, .no-bonus-btn,
  .cancel-button-redirect-exp, .button-continue-shopping
   {
      background: #333 !important;
      border-radius: 0 !important;
      color: #ffffff !important;
      &:hover {
      background: #333;
      }
  }

     #cart-table .section-header {
      color : #333333;
     }

  .thank-you-header {
       line-height: 30px;
       margin: 0;
       font-size: 25px;
  }
  .your-shipment {
      font-size: 22px;
      padding-top: 5px;
      margin-bottom: 15px;
  }
  .primary-content h1{
    font-size : 22px;
    padding-bottom: 10px;
  }
  .payment-methods {
    .form-row  label{
      display : inline-block;
    }
  }
  a, button, input {
    outline: 0;
  }

  .cart-sidebar-help.cart-secondary-module{
    display : none;
  }

  .bonus-product {
    .item-image {
      padding : 0px 16px 0px 0;
    }
    padding-top: 10px;
    .item-price, .item-total {
      padding : 0;
    }
    .price-promotion {
      float : left;
      .price-sales {
        display : inline !important;
      }
    }

    .item-quantity {
        padding: 0px;
        display: block !important;
        width: 100% !important;
        padding-top: 0px;

        .item-quantity-details {
          width : 95%;
          .item-user-actions {
            button {
              display : inline-block !important;
            }
            .add-to-wishlist {
              float : right;
              display : inline-block !important;
            }
            .product-availability-list {
              display : none;
            }
          }
        }
    }
  }

  #bonus-product-list {
    height: 100% !important;
  }
  .bonus-product-list {
    .product-col-1 {
      margin-bottom : 0px;
    }
  }
  .bonus-product-list-footer.promotion{
    display : block;
    #add-to-cart-bonus {
      float : none !important;
    }
  }
  .pt_cart .primary-focus .primary-content {
    width: 100%;
    float: none;
  }

  .pt_cart .primary-focus #secondary {
    width: 100%;
    //float: none;
  }

  .cart-secondary-module:first-of-type {
    margin-bottom: 25px;
    padding-top: 10px;
  
    .order-totals-header td {
      color: $color-urban-black;
      font-size: 19px;
      text-align: left;
      padding-bottom: 10px;
    }
  }

  .cart-secondary-module {
    padding: 15px 20px;
  }

  .pt_cart .order-totals-table td {
    font-size: 19px;
    padding: 5px 0;
  }

  #secondary .cart-sidebar-help a,
  .cart-sidebar-help span {
    font-size: 14px;
  }

  .cart-coupon-code label {
    text-indent: 0;
  }
  
  .shopping-delimiter {
    margin-top: 20px;
    border-top: 1px solid #D9D9D9;
  }
  .shopping-delimiter span {
    text-align: center;
    display: block;
    width: 30px;
    margin: 0 auto;
    margin-top: -10px;
    background: #f0f0f0;
    text-transform: uppercase;
  }
  .button-fancy-small,
  #update-cart,
  #cancelBtn,
  #add-donate,
  #deleteBtn,
  .cancel-button-redirect-exp {
    width: 100%;
    padding: 0;
  }

  #primary th.section-header.prod {
    width: 100%;
  }

  #cart-table .item-image {
    width: 45%;
    min-width: 80px;
    border: none;

    img {
      max-width: 100%;
    }
  }

  #cart-table .item-details {
    width: 55%;
    min-height: 140px;
    border: none;
  }

  #cart-table .item-edit-details {
    width: 40%;
    min-width: 100px;
    padding-top: 0;
  }

  #cart-table .item-price {
    float: left;
    width: 40%;

    .price-label {
      padding-top: 0 !important;
    }
  }
  #cart-table .item-total {
    width: 100%;
    padding: 0;
    font-size: 14px;
    border-bottom: none;
    text-align: left;
  }

  .pt_cart td.item-details .product-list-item {
    width: 100%;
    .sku .value,  .attribute .value {
      display: inline-block;
      width: calc(100% - 92px);
    }
  }

  table.item-list .item-price {
    padding-right: 0;
    text-align: right;
  }

  .cart-row .item-image,
  .cart-row .item-details,
  .cart-row .item-quantity,
  .cart-row .item-price,
  .cart-row .item-total {
    padding: 10px 0;
  }

  .cart-row .item-total .section-header {
    text-align: right;
  }

  #cart-table .item-price {
    text-align: right;

    .section-header {
      text-align: center;
    }
  }

  #cart-table .section-header {
    display: block;
    padding: 0 0 10px;
  }

  #cart-table tbody .section-header {
    display: block;
  }

  #cart-table .price-promotion .price-sales,
  #cart-table .price-promotion .price-standard {
    width: 100%;
  }

  .pt_cart .primary-content h1, .pt_cart #secondary h1 {
    margin: 20px auto;
    padding-bottom: 0;
    font-size: 32px;
    text-align: center;
  }

  #cart-table .item-edit-details a {
    border: none;
    padding: 0;
  }

  #cart-table .item-edit-details {
    margin: 5px 0 5px 0;
  }

  .item-quantity {
    span.error {
      display:inline;
    }
  }
    .coupons-heading td, .coupons-heading .label {
        padding-left: 0;
        padding-right: 0;
    }
  #cart-table{
    .rowcoupons{
      .item-image, .item-quantity, .item-price{
        display:none;
      }
      .item-details{
        width:80%;
      }
    }
  }

  .bonus-item-details {
    height: auto;
    padding: 0;
    margin-bottom: 15px;
  }

  .bonus-item-actions {
    padding: 0;
  }

  .select-bonus {
    display: block;
    width: 100%;
    padding: 0;
    line-height: 30px;
    border-radius: 3px;
    text-align: center;
  }

  #cart-update {
    float: none;
  }

  .cart-update-footer {
    padding: 0;
  }

  .cart-update-footer #cart-update button {
    margin-top: 15px;
  }

  .checkout-progress-indicator {
    display: none;
  }

  .pt_checkout .primary-focus .primary-content,
  .pt_checkout .primary-focus #secondary {
    width: 100%;
    float: none;
    clear: both;
    margin-top: 0;
  }

  legend span.required-text {
    top: 0;
    right: 0;
    position: relative;
  }

  .zipcode {
    width: 100%!important;
  }

  .cart-footer {
    margin: 0;
  }

  #shipping-method-list fieldset label.fancyCheck {
    margin-top: 0;
    position: initial;

    &:before {
      left: 10px;
    }

    &:after {
      left: 14px;
    }
  }

  .payment-method-options .CREDIT_CARD {
    top: 0;
  }

  #PaymentMethod_CREDIT_CARD .verisign {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .address .form-row-container.email-container .label-addEmail {
    width: 100%;
  }

  .toggle-span {
    width: 100%;
    float: none;
  }

  .useShippingAddress label {
    margin-top: 20px;
  }

  .address .expiration .month .select2-container,
  .address .expiration .month,
  .address .expiration .year,
  .address .expiration .year .select2-container {
    width: 100%;
  }

  #gift-certificate-form input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  #primary {
    float: none;
  }

  .address .form-row-container.email-container {
    padding: 15px;
  }

  .pt_checkout .sidebar-basket, .pt_checkout .sidebar-shipping {
    height: auto;
    width : 100%;
  }

  .summary-primary-left,
  .summary-primary-right {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .thank-you-header {
    line-height: 40px;
  }

  .updated-login-box {
    width: 100%;
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
    background: #ffffff;
  }

  .pt_order-confirmation #main {
    padding: 0 15px;
  }

  .design-optimization-login,
  .benefits-confirmation-message {
    float: none;
    width: 100%;
  }

  .design-optimization-login #RegistrationForm .input-text-pw {
    width: 100%;
  }

  .pt_order-confirmation .primary-focus .primary-content {
    width: 100%;
  }

  .sidebar-basket-order-confirmation {
    width: 100%;
    padding: 10px;
  }

  .return-to-shopping {
    display: inline-block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0;
    text-align: center;
  }

  .pt_order-confirmation .primary-focus #secondary {
    float: none;
    width: 100%;
  }

  .return-div {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .order-confirmation-details {
    margin-left: 0;
    margin-top: 0;
  }

  .confirmation-message {
    padding: 0;
  }

  .order-details-summary-container {
    width: 100%;
    padding: 15px;
  }

  .order-conf-float,
  .confirmation-billing-method {
    width: 100%;
    float: none;
  }

  #secondary .cart-continue-shopping-header {
    margin: 0 0 20px;
  }

  .ui-dialog .ui-dialog-content .container {
    width: 100%;
  }

  .ui-dialog .ui-dialog-content .pdp-main {
    padding: 0;
  }

  .ui-dialog .ui-dialog-content #pdp-main .product-col-1, .ui-dialog .ui-dialog-content #pdp-main .product-col-2 {
    width: 100%;
  }

  .ui-dialog .ui-dialog-content #pdp-main .product-col-1 {
    padding: 0;
  }

  .ui-dialog .ui-dialog-content #pdp-main .product-col-2 {
    padding: 5px 0 0;
  }

  #wrapper {
    width: 100%;

    label, .form-label-text {
      width: 100%;
      float: none;
      text-align: left;
    }

    input[type="text"], input[type="password"], textarea {
      float: none;
      margin-left: 0;
    }
  }

  .gift-input-textarea {
    width: 100%;
  }

  #prod-gift-image {
    float: none;
    padding: 10px;
    text-align: center;
  }

  #add-gift-message #image-button .form-row.form-row-button {
    margin-left: 0;
    width: 100%;
  }

  #add-gift-message {

    #cancelBtn {
      height: 40px;
      font-size: 18px;
    }
  }

  .address .select-address .form-row .select2-container {
    width: 100%;
  }

  #shipping-address-buttons,
  #billing-address-buttons {
    float: none;
  }

  .confirmation-thank-you {
    margin-bottom: 0;
  }

  .sidebar-basket-product {

    img {
      width: 40%;
      float: left;
    }

    .details {
      width: 60%;
      padding: 10px;
      float: left;
    }
  }

  .useShippingAddress {
    padding: 0;
  }

  .return-to-shopping {
    border-radius: 3px;
  }

  #cart-table .item-quantity input {
    &.mobile-quantity-input {
      margin-top: 2px;
        height: 22px;
        width: 22px;
        padding-top: 1px;
  }
  }

  .payment-method .form-row.payment-select {
    height: auto;
  }
  
  .pt_checkout .select2-container span{
    max-width: 210px;
    overflow: hidden;
  }
  /*
  .pt_checkout .checkout-step-title ~ form {
    display: none;
  }
  */
  .ui-dialog .ui-dialog-content {
    border-color: #fff;
  }

  .buttonbar {
    width: 100%;
  }

  .fancyCheck:before {
    border: 1px solid #BDBBBC!important;
  }

  .address .form-row .input-text {
    &.error {
      background: none !important;
    }
    
    &.error + span:after {
      font-family: FontAwesome;
      content: "\f00d";
      color: $color-kipling-red;
      position: absolute;
      top: 15px;
      right: 17px;
    }

    &.valid + span:after {
      font-family: FontAwesome;
      content: "\f00c";
      color: $color-urban-black;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
 .card-number-formfield input + span:after {
  content: '' !important;
 }
  .styled-select {
    position: relative;

  @include mobile {
    &:after {
      position: absolute;
      right: 12px;
      top: 12px;
      content: " ";
      border-style: solid;
      border-color: #2d2c2f;
      border-width: 1px 1px 0 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      vertical-align: top;
      transform: rotate(135deg);
    }
  }

    &:before {
      content: '';
      position: absolute;
      right: 14px;
      top: 0;
      width: 20px;
      height: 100%;
      border-left: 1px solid #c5c6c8;
      vertical-align: top;
      display: block;
    }

    select {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      font-size: 13px;
      border: 1px solid #c5c6c8;
      padding-right: 40px;
      position: relative;
      z-index: 20;
      &.error {
        background: #FFD0D5;
      }
    }
  }

  #wrapper {

    input[type=text],
    input[type=password],
    textarea {
      display: inline-block;
      width: 100%;
      height: 40px;
      border: 1px solid #e6e6e6;
      font-family: Kipling-Regular,Futura,sans-serif;
      border-radius: 0;
      box-shadow: none;
      padding: 0 10px;
      font-size: 16px;
      float: none;
      margin-left: 0;
    }

    textarea {
      height: 100px;
    }
  }
}

.cart-order-totals .paypal-button:not(.paypal-button-context-iframe) {
    text-align: center;
    padding-top: 15px;
}

.Klarna-method {
  .fancyRadio:before {
    content: none;
  }

  &.hide{
    display:none !important;
  }
}

.klarna-icon-billingpage {
  width: 150px;
  height: 40px;
  display: block;
  cursor: pointer;
  background: #ffb3c7;
  border-radius: 5px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.js-extra-field-googlepay {
  display:none !important;
}

.pt_order-confirmation {
  .sidebar-basket-product {
    img,
    picture {
      width: 95px;
      display: block;
      aspect-ratio: 1; 
    }
  }
}

.cart-applied-coupon-codes .cartcoupon {
  margin: 10px 0;
  position: relative;

  .cartcoupon {
    margin: 10px 0;
    padding-right: 55px;
    font-size: 13px;
  }

  .bonus-remove {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    line-height: normal;
  }
}

.pt_order-confirmation .linc-optin-widget .toggle.customizable {
  display: inline-flex;
  margin: 0;
  padding: 0;

  input[type=checkbox] {
    margin: 0;
  }

  &.MuiSwitch-root {
    padding: 6px 12px;
  }

  label {
    font-size: 18px;
    padding: 0;
  }
}

.gpay-card-info-container {
  min-width: 0px!important;
}