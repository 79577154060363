header {
	position: sticky;
	left: 0;
	top: 0;
	width: 100%;
	background: #FFF;
	z-index: 100;
}

#header {
	position: relative;
}

a {
	outline: 0;
}
.slick-next,
.slick-prev {
	cursor: pointer;	
}
.all-other-countries {
    background: url(../images/pink_texture.jpg) $color-red-main;
    text-align: center;
    border-radius: 3px;
    width: 130px;
    display: block;
    color: #fff;
    font-size: 13px;
}
.all-other-countries-footer {
	font-size:11px;
	border-top:1px solid black;
}

.fa-shopping-bag:before {
    content: "\e916" !important;
    font-family: icomoon;
}

@media screen and (min-width: 960px) {

  .hidden-desktop {
    display: none!important;
  }

  .mini-cart-image img {
	width : 83px;
	min-width:83px;
  }
}


@media screen and (max-width: 960px){
  .monetate_selectorHTML_c615af28 , #monetate_endcap_921126cf_0 {
  	display:none;
  }

  h3.searchcount {
       margin-top: 10px;
  }

  .mt_search_product a {
    position: absolute;
    top: 0px;
    left: 5px;
  }

  #monetate_search_results {
    border: 1px dashed #999;
    border-top: 1px solid #fff;
    background: #fff;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    font-family: Kipling-Extrabold,Futura,sans-serif;
    letter-spacing: 1px;
    color: #333;
  }

  .hidden-mobile {
    display: none !important;
  }

  #pre-header {
  	display : none !important;
  }

  #header-search {
  	display: none !important;
  }

  * {
    box-sizing: border-box;
  }
   .container {
    width: 100%;
    padding: 0 10px;
  }
  #navigation .home {
  	display:none !important;
  }
  .mobile-search-block {
  	display:none;
  }
	.mobile-home-page-link{
		position:absolute;
		top: 50%;
		left:50%;
		transform: translate(-50%,-50%);
		outline: 0;
		margin-top: 2px;
	}
	.mobile-home-page-link img {
		height: 30px;
		display: block;
	}

  .mobile-bar-btn {
    display: inline-block;
    font-size: 23px;
    color: #6C6A71;
  }
  .mobile-menu {
  	width : 100%;
  	display: inline-block;
  }
 
  .mobile-bar-item-3{float:left;margin-top:5px;}
  .mobile-bar-item-1,.mobile-bar-item-5{float:left;margin-top:10px;}

  .mobile-bar-item-3 {
  	float:right;
  	span.fa {
  		    padding-top: 4px;
  	}
  }

  .mobile-bar-item-1 {
  	text-align : left;
  }

  .mobile-bar-item-3-1, .mobile-bar-item-3-2, .mobile-bar-item-3-3 {
  	display : inline-block;
  	text-align: right;
  }

  .menu-bar-info {
    font-size: 12px;
    text-align: center;
    display: none;
  }

  .mobile-bar-item-3-2.search a {
  	font-size: 22px;
  }

  #navigation .menu span {
  	    margin-top: 1px;
  }
  .mini-cart-content {
    display: none;
  }

  .mini-cart-content {
    display: none;
  }

  #navigation .cart a {
    height: auto;
    font-size: 25px;
    line-height: inherit;
    color: #6C6A71;
    background: transparent;
    padding: 0px;
    margin: 0px;
		position: relative;
		display: inline-block;
  }
  .link-to-home span {
  	display : none;
  }
  .fa-stores:before {
  	content: "\e919" !important;
    font-family: icomoon;
  }

  .fa-search:before {
    content: "\e918" !important;
    font-family: icomoon;
  }

  .fa-bars.open:before{
    content: "\e921" !important;
    font-family: icomoon;
  }

  .mobile-bar-title {
	position: absolute;
	font-size: 12px;
	transform: translateX(-50%);
	top: 13px;
	width: 100%;
	display: block;
	left: 50%;
  }
  
  .mobile-search-block.active {
    display: inline-block;
    width: 100%;
    margin-top: 7px;
    .ovlb {
		width: 100%;
		border: 2px solid #6c6a71;
		padding: 0;
		height: auto;
       .ovlbLabel {
         top: 0;
         margin-top: 0;
         height: 100%;
          line-height: 36px;
       }
       .ovlbInput {
         height: 100%;
         line-height: 36px;
         font-size: 1.1em;
         border : 0 !important;
       }
    }
    button {
    	position: absolute;
    	top: 0px;
    	right: 3px;
    	height: 100%;
    	display: flex;
    	align-items: center;
    	padding: 0 5px;
    	border: none;
    	background: none;
    	font-size: 17px;
    	color: black;

      .fa-search {
		background-color: #fff;
		width: 30px;
		font-size: 28px;
		color: #6c6a71;
      }
    }
  }

  .mobile-left-navigation {
    width:90%;
	position:absolute;
	left:-10px;
	top:75px;
	overflow-x:hidden;
	overflow-y:scroll;
	padding:0;
	background:#fff;
	text-transform:capitalize;transform:translateX(-150%);visibility:hidden;transition:.2s;

    &.active {
      transform: translateX(0);
      visibility: visible;
    }
	#navigation-menu-left {
		position: relative;
	}
	.mobile-signin {
		background: #6C6A71;
		color: #fff;
		text-transform: none;
		font-weight: normal;
		font-size: 20px;
		padding: 25px 20px;

        .mobile-sigin--auth__container {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			margin: 0 auto;

			.logout-link {
				display: inline-block;
				position: relative;
				white-space: nowrap;

				&::after {
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					height: 1px;
					background: white;
					content: '';
				}
			}
        }

		span {
			font-weight: normal;
			font-family: Kipling-Light,Futura,sans-serif;
		}
		.top a {
			color: #fff;
			font-size: 18px;
			padding: 0;
			text-decoration: underline;
		}
		.top .ca--help__wrapper a {
			font-size: 15px;
		}
		.back-button {
			font-size: 20px;
			border-top: 1px solid #000;
		}
		a:before {
			display: none;
			color: #fff !important;
			top: 22px !important;
		}
		.top {
			border: 0;
			padding: 0;
			display: inline-block;
			margin: 0;

			&:first-of-type, + span {
				float: left;
			}
			+ span {
				padding: 0 5px;
			}
		}
		.error-form {
			background: none;
			margin: 0;
    		padding: 0 0 0 35px;
		}
		.slide-menu a {
			color: #000;
		}
		.back-button:before {
			display: inline;
		    content: "\f104";
		    color: $color-dark-grey !important;
		    font-size: 20px;

		}
		.form-row {
			padding: 0 10px;
		}
		#password-reset {
			display: inline-block;
		    font-family: Kipling-Regular,Futura,sans-serif;
		    font-weight: 700;
		    font-size: 12px;
		    text-decoration: underline;
		    letter-spacing: 1px;
		    margin: 0;
		    float: right;
		    color: $color-green;
		}
        .input-name--container {
            width: 49%;
            float: left;
        }
        .input-lastname--container {
            float: right;
            width: 49%;
        }
		.si--su__container {
			padding: 20px 0;
    		border-top: 1px solid #BDBBBB;
    		clear: both;

    		&:last-of-type {
    			border-bottom: 1px solid #BDBBBB;
    		}
		}
		.si--su__header {
		    font-size: 15px;
		    text-transform: capitalize;
		    color: #000;
		    letter-spacing: 1px;
		}
		.si--su__redirect a {
		    font-size: 13px;
		    color: $color-green;
		    text-decoration: underline;
		    letter-spacing: 1px;
		}
		.form-row.label-inline {
			padding: 0;
			float: left;
		}
		.form-row.form-row-button {
			float: right;
		}
		.form-row.form-row-button.centered{
			text-align: center;
	    	margin: 15px 0;
	    	width: 100%;

	    	button {
	    		background: $color-urban-black !important;
    			border-radius: 0!important;
    			color: #fff!important;
    			height: 40px;
			    padding: 0 20px;
			    font-size: 18px;
			    line-height: 1;
		        text-transform: uppercase;
			    border: 0;;
			    font-family: Kipling-Extrabold,Futura,sans-serif;
	    	}

		}
		.loyalty-checkbox .fancyCheck:before {
			top: 22px;
		}
		.fancyCheck:before {
			    background: #fff;
		}
		input.checked + .fancyCheck:before {
			    background: url(../images/check-red-darker.jpg) center 2px no-repeat #fff;
		}
		.form-caption {
			color: #000;
		    font-size: 12px;
		    margin: 0;
		    clear: both;
		    font-family: Kipling-Regular,Futura,sans-serif;

		    label {
		    	color: #000;
		    	font-size: 12px;
		    	font-family: Kipling-Regular,Futura,sans-serif;
		    	margin: 5px 0 0;
		    }

		    a {
		    	font-size: 12px;
		    	font-family: Kipling-Regular,Futura,sans-serif;
		    	text-decoration: underline;
    			font-weight: 100;
    			text-transform: capitalize;
		    }
		   	.check16 {
		   		margin: 0;
			}
		}
		strong {
			font-family: Kipling-Regular,Futura,sans-serif;
		}

        .m--dob {
            float: left;
            width: 35%;
        }

        .m--mob {
            float: left;
            width: 35%;
            margin-right: 15px;
        }

		.styled-select select {
			width: 100% !important;
			padding: 0 10px!important;
			margin: 0;
			font-size: 12px !important;
            border-radius: 0;
            outline: none;

			// &:first-of-type {
			// 	margin-right: 3%;
			// }

		}
        #dwfrm_profile_customer_gender {
            width: 100% !important;
        }
		.styled-select:before {
			right: 0;
		    left: calc(47% - 25px);
		    height: 25px;
		    top: 2px;
		    z-index: 99;
		    border-left: 1px solid #c5c6c8;
		    display: none;
		}
		.styled-select:after {
			right: 0;
		    left: calc(47% - 8px);
		    height: 25px;
		    top: 2px;
		    z-index: 99;
		    display: none;
		}
		.sub-menu.slide-menu label.fancyCheck {
			font-size: 12px;
			color: #000;
			text-transform: initial;
			font-weight: 100;
			margin-top: 15px;
			a {
				font-size: 12px;
			}
			span {
				font-weight: bold;
			}
		}
		a {
			color: white;
		}
	}
    .top {
      display: block;
      font-size: 20px;
      border-bottom: 1px solid #e8e4e4;
      margin: 0 20px;

      &:first-of-type {
       border-top: 1px solid #e8e4e4;
      }

      > a {
        display: inline-block;
        width: 100%;
		transition: .2s;
		padding: 16px 0 13px;
		color: #2D2F2C;
		
		&.level1:before {
		    position: relative;
		    top: 7px;
		    float: right;
		    content: "\e920";
		    font-family: icomoon;
		    color: #2d2c2f;
		    font-size: 10px;
		    font-style: normal;
		    transform: rotate(-90deg);
		}
      }

		.sub-menu {
			> .elem-menu {
				margin: 0 20px;
				
				&.subcategory {
					> .elem-menu {
						margin: 0 20px;
					}
					
					.elem-menu-box > .elem-menu{margin: 0 20px;}
				}
			}
			
			.elem-menu {
				font-size: 17px;
				border-bottom: 1px solid #e8e4e4;
				background: #fff;
				
				a:not(.back-button) {
					padding: 16px 0 13px 20px;
					display: inline-block;
					width: 100%;
					font-size: 20px;
					color: #6C6A71; 
	
					&.active {
						opacity: 0;
					}
				}
	
				&:first-of-type {
					border-top: 1px solid #e8e4e4;
				}
			}
		}
    }
   .elem-menu.top {
		&:first-of-type {
			border-top: 0 !important;
		}
	}
   .subcategory > a:before{
	    position: relative;
	    top: 7px;
	    float: right;
	    content: "\e920";
	    font-family: icomoon;
	    color: #d50158;
	    font-size: 10px;
	    font-style: initial;
	    transform: rotate(-90deg);
   }

  }
	.slide-menu {
		position: absolute;
		display: block;
		transform: translateX(115%);
		top: 0;
		left: 0;
    	background: #fff;
    	width: 100%;
    	height: 100%;
    	z-index: 2;
    	-webkit-transition: all 500ms;
		-moz-transition: all 500ms;
		-o-transition: all 500ms;
		transition: all 500ms;
    	&.active{
    		display: block;
    		transform: translateX(0);
    		-webkit-transition: all 500ms;
			-moz-transition: all 500ms;
			-o-transition: all 500ms;
			transition: all 500ms;

    	}
	}
  .elem-menu-box {

    .elem-menu {
      font-size: 20px;
      margin-bottom: 3px;

      a {
        display: inline-block;
        width: 100%;
      }

    }
  }
 a.back-button {
	color: $color-dark-grey;
    display: block;
    padding: 16px 20px 13px 40px;
    margin-top: 0;
    font-size: 20px;
    position: relative;

	&:before {
		content: "\e920";
	    font-family: 'icomoon';
	    padding-right: 10px;
	    font-size: 10px;
	    transform: rotate(90deg);
	    display: block;
	    position: absolute;
	    left: 10px;
	    top: 26px;
	}
  }

  .elem-menu.viewall{
  	font-weight : bold;
  }
 

  .mobile-header-banner {
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin: 0;
    position:relative;
    height: 18px;
    width : 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .mobile-icons-wrapper {
    height: 45px;
    position: relative;
  }

  .mobile-bar-items-class {
    width: 40px;
    text-align: center;

    @media screen and (max-width: 380px) {
      width: 32px;
    }
  }

  .header-mob-banner {
    height: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-dark-grey;
  }

 }

 #wrapper .mobile-search-block.active .ovlb input[type=text] {
    line-height: normal;
    height: 55px;
    font-size: 22px;
 }
 
 .borderfree-enable.pt_storefrontHome {
 	.mobile-pre-header-banner.for-home-page.bfx-hide {
		display: none!important;
	}
 }

@include only_ie11 ( '.mobile-home-page-link' , (top: 4px) );

#navigation-menu li img {
	max-height: 250px;
} 
.mobile-bar-title {
	visibility: hidden;
}

.welcome-lightbox-reg-wrap {
	display: none;
	position: fixed;
	text-transform: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.4);
	z-index: 2002;
	
	.required-mark {
		color: #666;
	    position: absolute;
	    right: 35px;
	    top: 105px;
	    
	    @media screen and (max-width: 960px) {
	    	right: 30px;
    		top: 95px;
		}
	}
	
	.welcome-lightbox-reg {
		width: 900px;
		height: 600px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		
		@media screen and (max-width: 960px) {
			top: 15px;
			width: 340px;
			transform: translate(-50%, 0);
			bottom: 15px;
		    overflow: auto;
		    height: auto;
		}
	}
	
	.welcome-lightbox-reg-bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		z-index: 1;
		
		@media screen and (max-width: 960px) {
			display: none;
		}
		
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	.welcome-lightbox-reg-steps {
		position: relative;
		z-index: 2;
		background: #fff;
		margin: 25px;
		width: 405px;
		height: calc(100% - 50px);
		
		@media screen and (max-width: 960px) {
			height: auto;
			width: 100%;
			margin: 0;
		}
	}
	
	.welcome-lightbox-reg-close {
		position: absolute;
		z-index: 3;
		top: 10px;
		right: 10px;
		color: #fff;
		font-size: 28px;
		cursor: pointer;
		
		@media screen and (max-width: 960px) {
			color: #000;
		}
	}
	
	form {
		height: 100%;
	}
	
	.welcome-lightbox-reg-step {
		padding: 35px 35px 10px 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
    	box-sizing: border-box;
    	
    	@media screen and (max-width: 960px) {
			padding: 25px 25px 10px 25px;
		}
	}
	
	.label-inline label {
		text-transform: none;
		font-size: 12px;
	}
	
	.kip-agree-welcome {
		margin-top: 20px;
		font-size: 12px;
		
		a {
			text-decoration: underline;
		}
	}
	
	.form-row {
		width: 100%;
	}
	
	input {
		border: 1px solid #2d2c2f!important;
	    border-radius: 10px!important;
	    height: 40px!important;
	    width: 100%!important;
	    box-sizing: border-box;
	}
	
	.select2-container {
		border: 1px solid #2d2c2f!important;
	    border-radius: 10px!important;
	    height: 30px!important;
	    width: 105px!important;
	    box-sizing: border-box;
	    
	    @media screen and (max-width: 960px) {
			width: 91px!important;
		}
	}
	
	.gender--form-row .select2-container {
		width: 160px!important;
	}
	
	.welcome-lightbox-reg-step.step-2 input {
		 height: 30px!important;
	}
	
	#RegistrationForm .form-row label {
		text-transform: none;
	    font-size: 13px;
	    padding-top: 12px;
	    padding-bottom: 3px;
	    font-weight: normal;
    	font-family: 'Kipling-Regular';
	}
	
	#RegistrationForm .form-caption {
		font-size: 12px;
		font-family: Kipling-Regular,Futura,sans-serif;
	}
	
	.select2-container a.select2-choice span {
	    color: #2d2c2f;
	}
	
	.select2-container {
	    margin-left: 0!important;
	}
	
	.email-input-wrap-lightbox {
		display: flex;
		margin-top: 20px;
		
		label {
			display: none;
		}
		
		input {
			border-radius: 10px 0 0 10px!important;
		}
		
		.next-step-lightbox-form {
			font-size: 50px;
			color: #fff;
			width: 55px;
			height: 40px;
			background: #2d2c2f;
			flex-shrink: 0;
			border-radius: 0 10px 10px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			
			&:hover {
				background: #000;
			}
		}
	}
	
	.birth--wrapper {
		display: flex;
		flex-wrap: wrap;
		
		label {
			width: 100%;
		}
		
		.styled-select {
			width: auto;
			float: none;
			display: block;
			margin-right: 5px;
		}
	}
	
	.styled-select > .custom-chevron:before {
		display: none;
	}
	
	.form-row.custom-chevron:before {
		display: none;
	}
	
	#RegistrationForm .form-row-button {
		margin: 15px 0;
		
		button {
			width: 100%;
			border-radius: 10px!important;
		}
	}
	
	.welcome-lightbox-buttons {
		.button {
			height: 35px;
			display: flex;
			width: 220px;
			align-items: center;
			justify-content: center;
			color: #fff;
			background: #2d2c2f;
			flex-shrink: 0;
			border-radius: 10px;
			margin-top: 15px;
			font-size: 14px;
			
			&:hover {
				background: #000;
			}
		}
	}
}

.dob-info-wrap {
		margin-left: 5px;
		display: inline-block;
		position: relative;
		z-index: 200;
		
		&:hover {
			.dob-info-text {
				display: block;
			}
		}

		.dob-info-icon {
			background: #000;
			border-radius: 50%;
			width: 15px;
			height: 14px;
			display: block;
			color: #fff;
		    text-align: center;
		    font-weight: bold;
		    padding: 1px;
		    cursor: pointer;
		}
		
		.dob-info-text {
			display: none;
			position: absolute;
			left: 30px;
			top: 25px;
			transform: translateX(-100px);
    		border: 1px solid #000;
    		width: 200px;
    		background: #fff;
    		padding: 7px;
    		z-index: 2;
    		
    		&:after {
    			content: '';
    			position: absolute;
    			left: 36%;
    			top: -6px;
    			margin-left: -8px;
    			border-style: solid;
				border-width: 0 8px 6px 8px;
				border-color: transparent transparent #000000 transparent;
				z-index: 1;
    		}
    		
    		&:before {
    			content: '';
    			position: absolute;
    			left: 36%;
    			top: -5px;
    			margin-left: -8px;
    			border-style: solid;
				border-width: 0 8px 6px 8px;
				border-color: transparent transparent #ffffff transparent;
				z-index: 2;
    		}
		}
	}