@mixin only_ie11($selector, $map){
	@media all and (-ms-high-contrast:none){
		*::-ms-backdrop, #{$selector} {
			@each $property, $value in ($map) {
				#{$property}: $value;
			}
		}
	}
}

@mixin mobile-xxs() {
  @media only screen and (max-width: 374px) {
    @content;
  }
}

@mixin mobile-xss() {
  @media only screen and (max-width: 419px) {
    @content;
  }
}

@mixin mobile-xs() {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin mobile-s() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile() {
  @media only screen and (max-width: 960px) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: 960px) {
    @content;
  }
}

@mixin desktop-hd() {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

@mixin desktop-1600() {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin retina() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}
