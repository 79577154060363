.linc-optin-widget {
    border:    1px solid #d9d9d9;
    border-radius: 6px;
    padding: 2em 38px;
    margin: 2em 38px 0em 38px;
}
.optin_title {
    font-weight: normal;
}
.optin_description {
    font-size: 1em;
}
@media only screen and (max-device-width: 767px) {
    .linc-optin-widget {
        padding: 1.5em 18px; 
        margin: 1.5em 18px 0em 18px;
    }
}

