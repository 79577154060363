.error-message-18 {
	display:none;
}
.form-row.error .error-message-18{
	display:block;
	width: 100%;
	margin-top: 5px;
}
.borderfree-enable {
	.bfx-hide {
		display: none!important;
	}
}

.facebook-share-button, .sharethis-share-button, .twitter-share-button {
	background-color: white;
	color:  $color-red-main;
}
.email-share-button {
	font-size: 30px;
    box-sizing: border-box;
}
.container {
	position:relative;
}

.turnto--tabs-list {
	margin: 0 0 15px;
}

.turnto--tabs-list li {
	display: inline-block;
	font-size: 16px;
}

.turnto--tabs-list li a {
	cursor: pointer;
	text-transform: uppercase;
	padding: 12px 40px;
	background: #fbfbfb;
	border-radius: 2px;
	color: $color-dark-grey;
	transition: background 0.3s ease;
}

.turnto--tabs-list li a.active {
	color: #000;
	background: linear-gradient(to bottom right, #d8d8d8, #f8f8f8);
	transition: background 0.3s ease;
}

.turnto--tab-content {
	display: none;
}

#pdp-detail {
	.pdp-monogaram-img_desktop {
	    width: 26px;
	    margin: 0 8px 0 5px;
	    position: relative;
	    top: -2px;
	}

	.availability-msg {
		margin: 5px 0 10px;
		font-size: 13px;
	}

	.pr-snippet-read-write a.pr-snippet-link {
		color: #000;
		font-family: Kipling-Extrabold,Futura,sans-serif;
		font-size: 14px;
	}

	.pr-snippet .pr-snippet-review-count {
		display: none;

		@include mobile {
			display: block;
		}
	}

	.container {
		width: 100%;
		max-width: 1370px;
	}

	@include desktop {
		.product_recommender {
			margin: 20px 0 40px;
		}
	}

	.slick-slide img {
		margin: 0 auto;
	}

	.slick-slide .mobile-pinchzoom img {
		max-width: 100%;
	}

	.mobile-quantity-parent {
  	text-align : center;

		@include desktop {
			padding: 9px 0;
		}
}
  .mobile-quantity {
	display: flex;
    align-items: center;

  	.fa-plus {
  		margin-left : 10px;
  	}
  	.fa-minus {
		margin-right : 10px;
  	}
  	span {
  		font-size : 13px;
  	}
  	input {
		width: 36px;
		height: 32px;
		text-align: center;
		border: 2px solid #6c6a71;
		border-radius: 10px;
		padding-top: 2px;
		font-size: 16px;
		color: #2d2f2c;
		box-sizing: border-box;
  	}
  }

	@include desktop {
		.product-info-accordion > .product-info-accordion-box:first-child{
			border-top: 1px solid #d3d3d3;
		}

		.product-info-accordion-box {
			border-bottom: 1px solid #d3d3d3;
		}
	}

	.product-info-accordion-link {
		display: inline-block;
		width: 100%;
		@include desktop {
			padding: 10px 10px 10px 0;
		}
		font-family: Kipling-Extrabold, Futura, sans-serif;
		font-size: 1.1em;
		font-weight: bold;
		text-transform: uppercase;
		color: #333;

		@include desktop {
			font-size: 1.4em;
		}

		&.active {

			&:after {
				transform: rotate(-180deg);
			}
		}

		&:after {
			position: relative;
			top: 3px;
			float: right;
			content: "\e920" !important;
			font-family: icomoon;
			color: $color-green;
			font-size: 8px;
			margin-right: 10px;
		}
	}

	.product-info-accordion-content {
		display: none;
		color: #333;
		font-size: 1.2em;
		padding: 0 10px 10px;

		@include desktop {
			font-size: 1.4em;
		}
		&.active {
			display: block;
		}
		font-family: Kipling-Regular,sans-serif;
		a {
			color: $color-green;
			text-decoration: underline;
		}
	}

	#pdp-detail .product_recommender-title {
		font-size: 18px;
		overflow: hidden;
		font-weight: 400;
		font-family: Kipling-Light,Futura,sans-serif;
		margin: 0 0 10px 5px;
		text-transform: none;
		text-align: left;
		color: #2d2f2c;
	}

	div.notifyme{
		width: 100%;

		.notifyme-description{
			padding-left: 5px;
			font-family: Kipling-Regular,sans-serif;
			font-size: 14px;
		}

		.notifyme-callout{
			padding-left: 5px;
			font-family: Kipling-Extrabold, Futura, sans-serif;
			font-size: 14px;
			font-weight: bold;
			color: #000;
		}

		label{
			display:none;
		}
		input{
			width: 100%;
			padding: 10px;
			margin: 7px 7px 7px 0;
		}
		input::-webkit-input-placeholder{
			color: #bbb;
		}
		input:-ms-input-placeholder{
			color: #bbb;
		}
		input::-moz-placeholder{
			color: #bbb;
		}
		button{
			text-transform: none;
			letter-spacing: 1px;
			display: block;
			background: #2d2f2c;
			border-radius: 5px;
			padding: 2px 44px 0;
			height: 50px;
			font-size: 20px;
			color: #fff;
			margin-bottom: 7px;
		}
	}

}

.quickview .pdp-main {
	@include desktop {
		.product-info-accordion-link {
			font-size: 1.1em !important;
		}

		.product-info-accordion-content {
			font-size: 1.2em !important;
		}
	}

	.product-gallery-thumbnails {
		width: 100%;
		float: none;
		padding: 0 10px;
	}

	.product-gallery {
		width: 100%;
		float: none;
		@include mobile {
			margin-bottom: 0;

			.pdp-slick-next, .pdp-slick-prev {
				display: block;
				position: absolute;
				font-size: 42px;
				color: #2d2c2f;
				height: 100%;
				width: 30px;
				z-index: 2;
			}

			.pdp-slick-prev {
				top: 0;
				left: 0;

				&:after {
					content: "\f104";
					top: 50%;
					display: block;
					position: absolute;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			.pdp-slick-next {
				top: 0;
				right: 0;

				&:after {
					content: "\f105";
					top: 50%;
					display: block;
					position: absolute;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}
		}
	}

	.product-gallery .slick-slide img  {
		width: 480px;
	}

	.product-gallery-thumbnails .pdp-slick-next, .product-set-image .pdp-slick-next{
		@include desktop {
			width: 30px;
			height: 100%;
			left: 100%;
		}

		right: 0;
	  z-index : 1;
	}

	.product-gallery-thumbnails .pdp-slick-prev, .product-set-image .pdp-slick-prev{
		@include desktop {
			left: auto;
			right: 100%;
			height: 100%;
			width: 30px;
		}
	  left: 0;
	  z-index : 1;
	}

	@include desktop {
		.pdp-main .product-name {
			max-width: 55%;

		}
	}
}

@media scen and (min-width: 1025px) {
	.st-btns {
		overflow: hidden !important;
	}
	.st-close {
		background: $color-red-main !important;
	}
}

@media screen and (max-width: 1025px) {
	.st-logo {
		top:0px;
		height : 40px;
	}
	.st-close {
	    top: 5px !important;
    	right: 5px !important;
    	height: 30px !important;
    	width: 30px !important;
    	background: $color-red-main !important;
    	font-size: 22px !important;
    	text-align: left !important;
    	border-radius: 15px !important;
    	padding: 4px !important;
	}
	.st-form{
		    margin: 35px auto !important;
	}
}


.st-custom-button {

	      background-color: $color-dark-grey;
	    	display: inline-block;
		    cursor: pointer;
		    width: 44px;
		    height: 44px;
			line-height: 46px;
		    color: white;
		    font-size: 29px;
		    text-align: center;
		   	margin: 0 2% 0 0;
	        box-sizing: border-box;
			border-radius: 100%;

    		@include mobile {
    			vertical-align: top;
    			margin-right: 15px;

					&:last-child {
						margin: 0;
					}
    		}


}
.share-pdp-block {
	text-align: center;
		@include desktop {
			margin: 20px 0;
		}
}

.rtp-zoomin,
.rtp-zoomout {
	display: none;

	@include mobile {
		font-size: 17px;
		display: block;
		position: absolute;
		bottom: 2%;
		left: 15px;
		z-index: 1;
		color: $color-dark-grey;
	}
}

.rtp-pinch-icon {
  display: none;

  @include mobile {
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 2%;
    right: 4%;
    z-index: 1;

    // .pinch-wrapper {
    //   background: url("../images/pinch-icon.png");
    //   height: 100%;
    //   background-size: 100%;
    // }
  }
}

.rtp-zoomout {
	@include mobile {
		left: 50px;
	}
}

@media screen and (min-width: 960px) {
	.sharethis-inline-share-buttons.st-inline-share-buttons{
		display : none !important;
	}
}
.sharethis-inline-share-buttons {
  padding-top: 30px;
}

.out-of-stock-product {
  height: 40px;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0px;
  background: white;
  color: black;
  line-height: 40px;
  font-size: 26px;
  text-transform: uppercase;
}

video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

@include desktop {
	.product-gallery {
		width: 88%;
		float: right;

		@media (max-width: 1200px) {
			width: 85%;
		}
	}
}

.product-gallery video {
	width:100%;
	max-width:550px;
	height:550px;
	@media screen and (max-width: 960px){
		height : auto;
	}
}

@include desktop {
	.product-gallery-thumbnails {
		width: 12%;
		float: left;
		padding: 70px 0;

		@media (max-width: 1200px) {
			width: 15%;
		}
	}

}

@media screen and (max-width: 960px){
	.product-gallery-thumbnails {
		padding: 0 10px;
		.slick-prev {
			left: -10px;
		}
		.slick-next {
    	right: -10px;
		}
	}

}
.product-gallery-thumbnails .pdp-slick-next,
.product-gallery-thumbnails .pdp-slick-prev, .product-set-image .pdp-slick-next, .product-set-image .pdp-slick-prev{
        display: block;
        position: absolute;
        font-size: 42px;
        color: $color-dark-grey;
		@include mobile {
			top: 29%;
		}
}

@include mobile {
	.product-set-image .pdp-slick-next, .product-set-image .pdp-slick-prev {
		top: 50%;
	}
}

#pdp-main .fa-angle-left:before, #pdp-main .fa-angle-right:before {
	display: block;
	font-family: Kipling-Light,Futura,sans-serif;
	font-size: 102px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) scaleX(.6);
}

#pdp-main .fa-angle-left:before {
	content: "<"!important;
}

#pdp-main .fa-angle-right:before {
	content: ">"!important;
}

.product-gallery-thumbnails .pdp-slick-next, .product-set-image .pdp-slick-next{
	@include desktop {
		left: 34%;
		bottom: 0;
		width: 27px;
	}

	right: 0;
	z-index : 1;
}

.product-gallery-thumbnails .pdp-slick-prev, .product-set-image .pdp-slick-prev{
	@include desktop {
		top: 0;
	  left: 34%;
	}
  left: 0;
  z-index : 1;
}

.product-set-image .pdp-slick-next {
	right: 13px;
}

.product-set-image .pdp-slick-prev {
	left: 13px;
}

.product-gallery, .product-gallery-thumbnails,
.product-gallery div, .product-gallery-thumbnails div {
	outline: 0;
}
.product-gallery-thumbnails .slick-slide{
		width: 105px;
		position: relative;
		overflow: hidden;
}product-gallery-thumbnails .slick-slide video {
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
}
.product-gallery-thumbnails img{
	width: 95px;
}
.ajax-pre-loader {
	padding-top: 50px;
}

.price-sales {
	color: #000;
}

.price-standard + .price-sales {
	color: $color-red-main;
}

.price-label {
	font-size: 13px;
	color: #6c6a71;
	margin: 3px 0 3px 5px;
	border : 0 !important;

	&:hover {
	border : 0 !important;
	}

	.old-price {
		text-decoration: line-through;
		color: $color-urban-grey;
		padding-right: 10px;
	}

	.new-price {
		color : $color-red-main;
	}
	& + .emptyswatch,
	& + .selected {
		clear: both;
	}
}


@media screen and (max-width: 960px){
.video-js {
	height:320px !important;
	width:100% !important;
}
.pdp-main.recent-wishlists{
	display:none;
}

	#curalate-fan-reel-wrapper {
		.curalate-fan-reel-container {
			width : 100%;
			#curalate-header {
				margin-top: 10px;
				margin-bottom: 10px;
				h1 {
					font-size: 24px;
				}
			}
			.curalate-follow-links {
				width: 100%;
    			float: none;
    			text-align: center;
			}
			#curalate-fan-reel {
				width: 100% !important;
				max-width: 510px;
				#curalate-content {
					width : 83%;
					@media(max-width:415px){
						width:72%;
					}
					@media(max-width:380px) {
						width:80%;
					}
					@media(max-width:360px) {
						width:83%;
					}
				}
			}

		}
	}
	 /* Add to cart popup*/
   .mini-cart-content.ui-dialog-content{
    box-shadow : initial;
    position : initial;
	border: 0;
   	.header {
		display: block;
    	padding: 5px;
		margin: 5px 14px 0;
    	.title {
   		   display : none;
    	}
    	.cartLevel {
			font-size: 13px;
			color: #2d2f2c;
    	}
    	.closeBtn {
    		display : none;
    	}
   	}
   	.itemsFrame {
		margin: 20px 10px 15px;
		overflow-x: auto;
		max-height: 54vh;

    	.mini-cart-product {
			display: inline-flex;
			margin: 10px 0;
			width: 100%;
			align-items: center;

    		&:first-child {
    			margin-top: 0px;
    		}

			&:last-child {
				margin-bottom: 0;
			}

    		.mini-cart-image {
   			    float: left;
			    margin-right: 10px;
			    position: relative;
			    display:inline-block;
				width: 25%;
    		}
    		.mini-cart-details {
    			 display:inline-block;
    			 float:left;
    			 margin-left : 2px;
    			.mini-cart-name a {
					text-transform: none;
					font-size: 13px;
					color: #2d2c2f;
    			}
    			.mini-cart-price {
					color: #6c6a71;
					font-size: 13px;
					text-transform: none;

					.price-unadjusted span {
						color: #6c6a71;
						font-family: Kipling-Regular,Futura,sans-serif;
						font-size: 13px;
						text-transform: none;
					}
    			}
    			.mini-cart-attributes {
    				display: inline-block;
    				.attribute {
						width: 100%;
						display: inline-block;
    					.label {
    						font-size: 13px;
							color: #6c6a71;
							text-transform: none;
							display: inline-block;
    					}
    					.value {
    						font-size: 13px;
							color: #6c6a71;
							text-transform: none;
							display: inline-block;
    					}
    				}
    			}

				a.remove-item-btn {
					margin-left: 10px;
				}
    		}
    	}
   	}

   	.footer {
   		.cartLevel {
	 		display : none;
	    }
	    #mini-cart-subtotals {
			margin: 0 15px;
			padding: 5px 0;
	    }
	    .actions > a {
			padding: 5px 15px 15px;
			display: inline-block;
			align-items: center;
			border-radius: 0;
			color: #2d2c2f!important;
			height: auto;
			font-size: 13px;
			text-transform: none;
			text-decoration: underline;
    	}
   	}
   	#minicart-banner {
 		font-size: 10px;
   	}
   }

  .bonus-item-details {
  	.tooltip {
  		background : white;
  	}
  	.tooltip-content {
  		    position: inherit;
		    width: 100% !important;
		    max-width: 100% !important;
		    min-width: 100% !important;
		    font-family : Kipling-Regular, Futura, sans-serif !important;
  	}
  	@iclude mobile {
  		text-transform: capitalize;
  	}
  }
  .bonus-discount-container {
      .tooltip-content {
		max-width: 100%;
		margin-left : 0px !important;
		left: 0% !important;
      }
  }
  .bonus-product-item {
    .product-col-1 img {
      width: 100%;
      height: auto;
    }
  }

  .button-fancy-large, .ui-dialog-content button#add-to-cart,
  .ui-dialog-content button#add-to-cart-bonus,
  .ui-dialog-content button#add-all-to-cart,
  .ui-dialog-content button.continue-checkout, .button-continue-shopping
  #product-set-list .add-to-cart {
    background: #333;
    border-radius: 0;
    padding: 5px 50px 5px 50px;
    width: 100%;
  }

  .bonus-discount-container {
    padding-left: 0.5em!important;
  }

  .breadcrumb {
    margin-bottom: 15px;
    font-size: 1.1em;
    .pdp:last-of-type:after, .pdp:last-of-type:before {
	    content: "";
    }
  }

  .breadcrumb > a,
  .breadcrumb li > a {
    margin-right: 0;
  }

  .breadcrumb > a:before,
  .breadcrumb li > a:before {
    font-size: 1em;
    margin: 0 3px 0 3px;
    color: #ccc;
  }

  .breadcrumb > span,
  .breadcrumb > li {
    margin-left: 0;
  }

  #pdp-detail {
    margin-top: 0;

    .container {
      width: 100%;
      max-width: 1370px;
    }
  }

  .pdp-main {
    margin: 0 0 40px;
    padding: 0;

    .product-col-1,
    .product-col-2 {
      float: none;
      margin-right: 0;
      width: 100%;
    }

    .product-primary-image {
      min-height: inherit;
    }

    .product-name {
      margin: 0 0 10px 0;
      font-size: 2em;
      text-align: center;
      line-height: 1.2;
    }

    .product-number {
      margin-bottom: 5px;
      text-align: center;
    }

    .product-price {
      margin-bottom: 0;
      font-size: 1.2em;
      text-align: center;
      color: #333;
    }

    .product-variations {
      padding: 0 10px;
      margin: 0;
    }

    .inventory {
      padding-bottom: 10px;
    }

	.availability {
		margin-top: 10px;
	}

    .swatches {
      margin: 5px 0;

      li {
        //float: none;
        display: inline-block;
      }
    }

    .promotion {
      display: none;
    }

    .product-add-to-cart {
      margin: 0;
	  padding: 0;
      button {
        margin-top: 0;
      }
    }

    .powerReviewsMiniContent {
      margin: 0;
      text-align: center;

      .pr-snippet .pr-snippet-review-count {
				display: none;
			}

      .pr-snippet .pr-stars,
      .pr-snippet .pr-stars-small {
        margin-right: 0;
      }
    }

  }

	#product-content .select2-container {
		float: none;
		margin: 0 auto;
	}

  .product-info-accordion {
    padding: 20px 0;
    margin: 0 -10px;
  }

  .product-info-accordion-box {
    border-bottom: 1px solid #ccc;
  }

  .product-info-accordion-link {
    display: inline-block;
    width: 100%;
    padding: 10px 10px;
    font-family: Kipling-Extrabold, Futura, sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;
    color: #333;

    &.active {

      &:after {
        transform: rotate(-180deg);
      }
    }

    &:after {
      position: relative;
      top: 3px;
      float: right;
      content: "\e920" !important;
      font-family: icomoon;
      color: $color-red-main;
      font-size: 8px;
      margin-right: 10px;
    }
  }

  .product-info-accordion-content {
    display: none;
    color: #333;
    font-size: 1.2em;
    padding: 0 10px 10px;
    &.active {
      display: block;
    }
    font-family: Kipling-Regular,sans-serif;
    a {
    	text-decoration: underline;
			color: $color-green;
    }
  }

  .fluid-display-imageviewer {
    max-width: 100%;
    height: auto !important;
    padding-left: 50px;
    min-height: 260px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .fluid-display-viewgroup {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 50px;

    > div {
      display: block!important;
      width: 100%!important;
      height: auto!important;
      left: 0!important;
      top: 0!important;
    }

    .fluid-display-imagegroup {
      display: block!important;
      margin-bottom: 0;
      height: auto;
      overflow: auto;

      a {
        display: inline-block;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0;
      }
    }
  }

  .fluidExperienceWrapper {
    overflow: hidden;
  }

	.mobile-pdp-header-wrapper {
		border-top: 1px solid #ccc;
		margin: 0 -10px;
		padding: 10px 0 0;
	}

  .productBody {
  	.mobile-name {
	    margin: 0 0 10px 0;
	    font-size: 2em;
	    text-align: center;
	    line-height: 1.2;
      font-weight: bold;
      font-family: Kipling-Extrabold, Futura, sans-serif;
  	}
  	.mobile-price span{
	    font-size: 1.4em;
        line-height: 1em;
  	}
  	.stickvogel-content, .js-monogram-mobile {
  		display : none;
  	}

  	.monogram-mobile{
	    background: url("../images/MakeYourOwn-icon-PDP-new.png") no-repeat center;
		height: 44px;
		width: 100%;
		background-size: auto 100%;
		background-position-x: center;
		display: inline-block !important;
  	}
  	.box-inventory.active {
  		 border-bottom: 1px solid #ccc;
  		.inventory, .mobile-monogram {
  			width : 49.5%;
  			display : inline-block;
  			border-bottom : 0px;
  		}
  		.inventory {
  			border-right: 1px solid #ccc;
		}
  		.mobile-monogram {
  			text-align : center;
		    vertical-align: text-bottom;
		    height : 33px;
		    float : right;
		}
  	}

	  .monetate-endcap.outer {
	    width: 100%!important;
	  }



	  .monetate-endcap.horizontal .top {
	    position: relative!important;
	    left: 0!important;
	    background-position: center;
	    background-repeat: no-repeat;
	    width: 100% !important;
	  }

  }
	.mobile-quantity-parent {
  	padding-top: 10px;
	}
  .mobile-quantity {
     margin: 0 auto;
  }

}
@media screen and (max-width: 360px){
.monogram-mobile{
	    font-size: 27px !important;
  	}
}
@media screen and (max-width: 960px){
.pdp-main .product-add-to-cart button{
		position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 600;
        margin: 0;
        background-color: black;
        height: 55px;
        font-size : 19px;
}
}

.smartgift {
    height: 30px;
    line-height: 30px;
    padding-top: 10px;
    display:inline;
    @include mobile {
		text-align:center;
	}
	a {
		font-size: 16px;
	}
}

.smgt-button-wrapper .smgt-giftbox {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.smgt-button-wrapper .smgt-button {
  font-size: 16px;
  font-family:Kipling-ExtraBold,sans-serif;
  padding: 6px 0 2px;
  color: #000000;
  text-decoration: none;
  text-transform:uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #000000;
}


#s7_spinview.s7spinviewer{
	width:100%;
	height:auto;
}
.spin {
	width:100%;
	height:100%;
}

.product-gallery-elem.spin.slick-active:before {
	position: absolute;
    top: 0;
    content: " ";
    display: block;
    width: 33%;
    height: 14%;
    left: 5%;
    background: url(../images/svg/360degree-icon-dragtorotate.svg) no-repeat;
    background-size: 100%;
}

@media screen and (max-width: 960px) {
	.product-gallery-elem.spin.slick-active:before {
	    width: 26%;
    	height: 23%;
    	left: 0;
	}
}



#navigation-menu li img {
	max-height: 250px;
}

.mobile-bar-title {
	visibility: hidden;
}

.pdp-googlepay{

	.gpay-button {
	margin-top: 10px;
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  padding: 12px 24px 10px;
  width: 240px;
}

.gpay-button.black {
  background-color: #000;
  box-shadow: none;
}

.gpay-button.white {
  background-color: #fff;
}

.gpay-button.short, .gpay-button.plain {
  min-width: 90px;
  width: 160px;
}

.gpay-button.black.short, .gpay-button.black.plain {
  background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
}

.gpay-button.white.short, .gpay-button.white.plain {
  background-image: url(https://www.gstatic.com/instantbuy/svg/light_gpay.svg);
}

.gpay-button.black.active {
  background-color: #5f6368;
}

.gpay-button.black.hover {
  background-color: #3c4043;
}

.gpay-button.white.active {
  background-color: #fff;
}

.gpay-button.white.focus {
  box-shadow: #e8e8e8 0 1px 1px 0, #e8e8e8 0 1px 3px;
}

.gpay-button.white.hover {
  background-color: #f8f8f8;
}

.gpay-button-fill, .gpay-button-fill > .gpay-button.white, .gpay-button-fill > .gpay-button.black {
  width: 100%;
  height: inherit;
}

.gpay-button-fill > .gpay-button.white,
  .gpay-button-fill > .gpay-button.black {
    padding: 12px 15% 10px;
}

.gpay-button.donate, .gpay-button.book,
.gpay-button.checkout,
.gpay-button.subscribe, .gpay-button.pay,
.gpay-button.order {
    padding: 9px 24px;
}

.gpay-button-fill > .gpay-button.donate,
.gpay-button-fill > .gpay-button.book,
.gpay-button-fill > .gpay-button.checkout,
.gpay-button-fill > .gpay-button.order,
.gpay-button-fill > .gpay-button.pay,
.gpay-button-fill > .gpay-button.subscribe {
    padding: 9px 15%;
}
}
