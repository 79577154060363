.pt_blog{
	.container {
		max-width: 1370px;
    	width: 90%;
    	margin: 0 auto 0;
    	@media screen and (max-width: 960px) {
    		width: 100%;
    		margin: 65px auto 0;
    	}
	}
	.header {
		margin-bottom: 30px;
	}
	.top-line {
		height: 65px;
		background: #ececec;
	}
	.logo-img {
		width: auto;
		margin: 0 auto;
		position: relative;
	}
	.logo-img a{
		margin: 0 auto;
	    display: block;
	    width: 230px;
	}
	.logo-img span {
		font-size: 35px;
		display: block;
	    float: left;
	    line-height: 65px;
	    @media screen and (max-width: 600px) {
    		font-size: 30px;
    	}
	}

	.logo-img img {
		width: 35px;
		float: left;
		padding: 15px 5px;
	}
	.top-nav {
		height: auto;
		position: relative;
		text-align: center;

		.logo {
			color: #000;
		    font-family: 'Knewave', cursive;
		    font-size: 50px;
		    font-weight: 400;
		    text-transform: uppercase;
		    padding: 45px 0 20px;

		    img {
		    	width: 300px;
		    }
		}
	}


	.blog-nav {
		display: inline-block;
	}
	.blog-nav li {
		float: left;
		display: block;

		@media screen and (max-width: 600px) {
			width: 40%;
			padding: 0 0 20px;

			&:nth-of-type(1),
			&:nth-of-type(3){
				margin-left: 10%;
			}
		}
	}
	.blog-nav li a {
		color: #000;
	    font-family: Kipling-Regular,Futura,sans-serif;
	    font-size: 23px;
	    text-transform: uppercase;
	    padding: 0px 25px;
	    font-weight: 600;

	}

	.search {
 		font-size: 20px;
 		float: right;
 		line-height: 65px;
 		right: 5%;
 		position: relative;
 		text-align: right;

		label {
			display: inline;
		}
		.searchblogForm {
			display: inline;
		}
		.fa-neuter {
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			cursor: pointer;
			font-size: 20px;
		}

		.fa-shopping-bag {
			padding-left: 20px;
			font-size: 20px;
			@media screen and (max-width: 600px) {
				padding-left: 10px;
			}
		}

		input {
			border: 0;
			border-bottom: 1px solid #000 !important;
			font-size: 13px;
			background: #ececec;
		}
	}
	@media screen and (max-width: 1050px){
		.search {
			width: 25%;
		}
		#search-field {
			width: 50% !important;
		}
	}
	@media screen and (max-width: 600px) {

		#search-field.active {
			display: block !important;
		}
		#search-field {
			display: none !important;
		    position: absolute;
		    top: 70px;
		    width: 90VW !important;
		    left: 0;
		    background: #fff;
    		margin-left: -260% !important;
    		border: 1px solid #ececec !important;
    		z-index: 5;
		}
	}


	.home-content-wrapper {
		position: relative;
		margin-top: 65px;

	}

	.article-tile {
		width: 32%;
		float: left;
		padding-right: 2%;
		padding-bottom: 30px;
		display: none;

		&:nth-child(-n+9){
   	 		display: block;
		}

		&:nth-of-type(3n) {
			padding-right: 0;
		}

		.article-thumbnail {
			overflow: hidden;
    		height: 325px;
    		background-repeat: no-repeat;
		    background-position: 50% 50%;
		    background-size: cover;
		    box-sizing: border-box;
		    display: block;
		}
		.article-title {
			min-height: 90px;

			h2 {
				color: #000;
			    font-family: Kipling-Regular,Futura,sans-serif;
			    font-size: 23px;
			    font-weight: 600;
			    text-transform: capitalize;
			    text-align: center;
			    line-height: 25px;
			    padding-top: 15px;
			}
		}

		.article-read {
			font-family: Kipling-Extrabold,Futura,sans-serif;
			color: $color-green;
			text-decoration: underline;
			text-transform: uppercase;
    		text-align: center;
		    display: block;
		    margin: 0 auto;
		    width: 125px;
    		font-size: 12pt;
		}
	}
	@media screen and (max-width: 1050px) {
		.article-tile {
			width: 45%;
			padding-right: 0;
			padding-bottom: 30px;

			&:nth-of-type(2n) {
				margin-left: 9%;
			}

			.article-read {
				box-sizing: content-box;
			}

			.article-thumbnail {
			    background-position: 50% 0%;
			}
		}
	}
	@media screen and (max-width: 805px) {
		.article-tile {
			width: 70%;
			margin: 0 auto;
			float: none;

			&:nth-of-type(2n) {
				margin: 0 auto;
			}

			.article-title {
				min-height: 0;
				padding-bottom: 20px;
			}
		}

	}
	.post-page {
		padding: 30px 0 0;
	}
	.post-content {
		text-align: justify;
		font-size: 18px;
		font-family: Kipling-Regular,Futura,sans-serif;
		display: none;

		a {
			text-decoration: underline;
		}
		img {
			margin: 0 auto;
			display: block;
			max-width: 400px;
			height: auto;
			padding: 20px 0;
		}
	}
	.post-title h1{
		text-align: center;
		text-transform: capitalize;
		font-size: 30px;
		color: #000;
		margin-top: 15px;
	}
	.post-info {
		text-align: center;

		.post-date, .post-category {
			color: #989898;
			font-size: 21px;
		    font-weight: 400;
		    font-family: Kipling-Regular,Futura,sans-serif;
		    text-transform: uppercase;
		    display: inline;
		}
	}
	.post-thumbnail {
	    width: auto;
	    max-width: 1000px;
	    height: 500px;
	    margin: 0 auto 30px;
	    background-repeat: no-repeat;
	    background-position: 50% 50%;
	    background-size: cover;
	    box-sizing: border-box;
	}

	.social-networks li	i {
		color: #989898;
	}

	.explore {
		padding-bottom: 50px;
		margin-top: 0;

		.explore-heading {
			font-size: 30px;
			line-height: 30px;
			font-weight: 400;
			font-family: Kipling-Regular,Futura,sans-serif;
			text-align: center;
			color: #000;
			padding: 30px 0;
		}
		.explore-tile {
			width: 18%;
			display: inline-block;
			padding-right: 2%;
			vertical-align: top;
			&:last-of-type {
				padding-right: 0;
			}
		}
		.explore-tile h4 {
			font-size: 18px;
			text-align: center;
			padding-top: 15px;
			color: #000;
		}

		.explore-tile .article-thumbnail, .explore-tile .explore-thumbnail {
			height: 200px;
			background-repeat: no-repeat;
		    background-position: 50% 50%;
		    background-size: cover;
		    box-sizing: border-box;
		    overflow: hidden;
		}
		.explore-tile .explore-link {
			text-align: center;
		    font-size: 23px;
		    text-transform: uppercase;
		    padding-top: 15px;
		}
		.explore-tile .explore-link a {
			color: $color-dark-grey;
		}

		@media screen and (max-width: 960px) {
			.explore-tile{
				width: 70%;
			    padding: 0;
			    margin: 0 auto 30px;
			    display: block;

				.article-thumbnail {
					background-position: 50% 0%
				}
			}
		}
	}
	.persistent-wrapper {

		.slick-slide {
			padding-right: 15px;
			width: 807px;

			@media screen and (max-width: 1050px) {
				width: auto;
			}

				.slide-img {
					height: 494px;
					background-repeat: no-repeat;
					background-position: 50% 50%;
					background-size: cover;
					box-sizing: border-box;
					display: block;
				}
				@media screen and (max-width: 1050px) {
					.slide-img {
						height: 330px;
					}
				}

				.slide-title {
					font-size: 25pt;
				    font-weight: 400;
				    text-align: center;
				    padding: 15px 0 40px;
				    color: #000;
				    font-family: Kipling-Extrabold,Futura,sans-serif;
				    text-align: center;
				    display: block;
				}
				.article-read {
					font-family: Kipling-Extrabold,Futura,sans-serif;
					color: $color-green;
					text-transform: uppercase;
					text-decoration: underline;
		    		padding: 0 10px;
		    		text-align: center;
				    display: block;
				    margin: 0 auto;
				    width: 130px;
				    font-size: 12pt;
				}

				.slide-info {
					display: none;
				}

			&.slick-center .slide-info{
				display: block;
			}


		}
		.fa {
			position: absolute;
			z-index: 5;
		    font-size: 30px;
		    color: #fff;
		    background: #000;
		    padding: 10px 10px;
		    top: 30%;
			&.fa-angle-left {
			}
			&.fa-angle-right {
			    right: 0;

			}
			@media screen and (max-width: 600px) {
	    		top: 20%;
	    	}
		}

	}
	.load-more-button {
		font-family: Kipling-extrabold,Futura,sans-serif;
		background: $color-dark-grey;
		color: $color-dark-white;
		text-transform: uppercase;
		text-align: center;
	  display: block;
	  margin: 35px auto 30px;
	  cursor: pointer;
	  width: 166px;
	  line-height: 44px;
  	font-size: 20pt;
	  font-weight: 600;
	  padding: 0 10px;
	  clear: both;

		&:hover {
			background: $color-green;
		}

	    @media screen and (max-width: 1050px) {
	    	font-size: 20px;
	    }
	}

	.blog-notfound {
		text-align: center;
	    font-size: 30px;
	    margin-top: 100px;
	}

	.footer-banner {
		background: #ececec;
		height: 175px;

		@media screen and (max-width: 600px) {
			height: 200px;
		}

		.check-box-newsletter label.email-policy {
			color: $color-dark-grey;
		}

		.signup-form {

			// width: 545px;
		  //   padding: 65px 0;
		  //   position: relative;
		  //   margin: 0 auto;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			justify-content: center;
			align-items: baseline;
			flex-flow: column nowrap;
			width: 350px;
			margin: 0 auto;
			padding: 20px 0 0;

			form {
				padding-top: 5px;
			}
			.signup-text {
			    position: relative;
			    font-size: 20px;
			    font-weight: 700;
			    // width: 202px;
			    // display: block;
			    // margin: 0 auto;
			    // float: left;
			    // line-height: 55px;
			    color: $color-dark-grey;
					font-family: Kipling-Extrabold,Futura,sans-serif;

					@include mobile {
						margin: 0 27.5px;
					}
			}
			.ovlb {
    			float: left;
    			border: none;
    			border-radius: 0;
    			height: 40px;

    			.ovlbLabel {
    				top: 45%;
    				left: 25px;
    				font-size: 11px;
    				font-weight: bold;
    				color: #000;
    			}
    			input {
    				padding-left: 15px;
    				height: 40px;
    				position: relative;
    				width: 250px;
    				font-weight: bold;
    				color: #000;
    			}
			}
			.actions {
				float: left;
			}
			.actions button {
				height: 40px;
				width: 60px;
				font-size: 20px;
				background: $color-dark-grey;
 				border: 0;
 				color: $color-dark-grey;


				.fa-chevron-right {
					color: #fff;
				}
			}

			label#emailFooterSuccess, label#emailFooterError {
				top: 15px;
    			position: relative;
			}
			@media screen and (max-width: 1050px) {
				margin: 0 auto;
				float: none;
				padding: 50px 0;

				.ovlb {
					width: 250px !important;
				}
				.ovlb label {
					top: 0 !important;
				}
			}
			@media screen and (max-width: 600px) {
				width: 100%;

				.signup-text {
					float: none;
				}
				form {
					width: 320px;
					margin: 0 auto;
				}
				.ovlb {
					width: 260px!important;
				}
			}
		}

		.socialcall + .signup-form {
			margin-right: 10%;
		    padding: 50px 0;
		    width: 335px;
   			float: right;
		    position: relative;
		    display: inline-block;

		   	@media screen and (max-width: 1050px) {
		    	display: block;
		    	margin: 0 auto;
		    	float: none;
		    	padding-top: 10px;

		    	.signup-text {
		    		float: none;
		    	}
		   }
		  	@media screen and (max-width: 600px) {
		  		width: 320px;
		  	}

		   form {
		   		float: right;
		   }
		  .signup-text {
				position: relative;
    			font-size: 18px;
    			font-weight: bold;
				line-height: 30px;
				text-transform: uppercase
			}
		}
		.socialcall {
			margin-left: 10%;
		    width: 40%;
		    padding: 75px 0 0 0;
		    display: inline-block;
		    float: left;

				.social-networks li {
					display: inline;
				    padding: 0 15px;
				    font-size: 30px;
				}
				.social-networks li i {
			    	color: #fff;

				    &:hover {
						color: $color-green;
					}
			    }

			@media screen and (max-width: 1050px) {
				width: 340px;
				margin: auto;
				padding: 30px 0;
				display: block;
				float: none;
				padding: 30px 0 10px 0;
			}
		}
	}

	.search-results li {
		color: #000;
		font-family: Kipling-Regular,Futura,sans-serif;
		font-size: 20px;
	}

	.hline {
		border-bottom: 2px solid #ececec;
	}

	.post-iframe {
		max-width: 900px;
		margin: 0 auto;
	}
	@media screen and (max-width: 1050px) {
		overflow: hidden;
	}
}
