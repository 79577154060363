.address-illigal {
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 20px !important;
}
/* Account-Logged in Page */
#main.account {
  width: 100%;
  max-width: 1370px;
  box-sizing: border-box;
  padding: 0 10px;
  padding-bottom:20px;

  @include desktop {
    #primary {
      width: 80%;
    }

    #secondary {
      width: 18%;

      nav {
        padding: 0;
        z-index: 499;
      }

      .toggleMyAccount {
        margin: 0 0 7px;
        padding: 0;
      }
    }
  }
}

.rewards-user-header-wrapper {
  	position: relative;
  	margin: 0 0 20px;
	display:block;
	width:100%;
	
	.sign-out-link {
		a {
			text-transform: uppercase;
	    	font-family: 'Kipling-Regular', sans-serif;
	    	font-size: 16px;
	    	color: #000;
	    	text-decoration: underline;
	    	
	    	@include mobile {
		      font-size: 12px;
		    }
		}
	}
	
  	.rewards-user-greetings {
	    font-size: 36px;
	    text-transform: capitalize;
	    font-family: 'Kipling-Light', sans-serif;
	
	    @include mobile {
	      margin-left: 15px;
	      font-size: 18px;
	      top: 12%
	    }
	    
	    &.employee {
	    	top: 10%;
	    }

		.js-points-balance + .rewards-copy {
			display: none;
		}

		.js-points-balance.show-rewards ~ .rewards-copy {
			display: inherit;
		}

		.js-points-balance.show-rewards ~ .points-copy {
			display: none;
		}
  }
  
  .employee-order-balance {
  	position: absolute;
    font-size: 15px;
    color: #191919;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    width: auto;
    font-family: Futura, sans-serif;
    font-weight: 300;
    right: auto; 
    left: 0; 
    top: auto; 
    bottom: 15px;
    
    @include mobile {
      font-size: 10px;
      left: 15px;
      right: 15px; 
    }
  }

  .current-point-balance {
    position: absolute;
    top: 72%;
    right: 3%;
    font-family: Kipling-ExtraBold,Futura,sans-serif;
    font-size: 23px;
    margin: 0;

    .registred-norewards {
      font-family: Kipling-Regular,Futura,sans-serif;

      .kipling-bold {
        font-family: Kipling-ExtaBold,Futura,sans-serif;
      }

      .noregistred-link {
        color: $color-green;
        font-family: Kipling-ExtraBold,Futura,sans-serif;
        text-decoration: underline;
      }
    }

    @include mobile {
      font-size: 12px;
      top: 55%;
    }
  }
}

/* Account-StartRegister Page */
#main.pt_startregister {
  max-width: 1370px;
  width: 100% !important;
  box-sizing: border-box;
  padding: 0 10px;
}

.pt_startregister .form-row {
    width: 47%;
    margin: 0 2% 0 0;
    float: left;

    @include mobile {
        width: 100%;
    }
}

.pt_startregister .form-row:nth-child(even) {
    margin: 0;
}

.pt_startregister .create-account-wrapper-left fieldset .label-inline-first,
.pt_startregister .sr--fieldset__check .start-register-email-label,
.pt_startregister .create-account-wrapper-left fieldset .sr--fieldset__check .email-share-policy,
.pt_startregister .sr--fieldset__check .form-row {
    width: 60%;
    min-width: 600px;
    float: none;
    margin: 0 auto !important;

    @include mobile {
        width: 100%;
        min-width: 0;
    }
}

.pt_startregister .create-account-wrapper-left {
    padding: 0;
}

.pt_startregister label[for="dwfrm_profile_customer_gender"] {
    padding: .75em 0 5px 4%;
}

.pt_startregister .create-account-wrapper #RegistrationForm .form-row-button {
    width: 100%;
    text-align: center;
}

.pt_startregister .create-account-wrapper #RegistrationForm .form-row-button button {
    margin: 20px auto !important;
    padding: 0 40px;
    box-sizing: content-box;

    @include mobile {
        margin: 0 auto 10px !important;
        padding: 5px 40px;
    }
}

.pt_startregister .create-account-wrapper .edit-profile-wrapper {
    padding: 0 40px;

    @include mobile {
        padding: 0 10px;
    }
}

.ar-footer-img--wrapper {
    width: 100%;
}

.ar-footer-img--wrapper img {
    width: 100%;
    display: block;
}

.changed-mind--btn a {
    font-size: 16px;
    text-decoration: underline;
}

.account-registraton--bottom-nav ul {
    display: inline-block;
    margin: 20px auto 0;
}

.account-registraton--bottom-nav ul li {
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
    text-decoration: underline;
}

.account-registraton--bottom-nav ul li:last-child {
    margin: 0;
}

.pt_startregister .select2-container {
    border: 1px solid #BDBBBB;
    border-radius: 0;
    background-color: #fff;
    margin-left: 4%;

    @include mobile {
        margin-left: 0;
    }
}

.pt_startregister .styled-select select {
    @include mobile {
        margin-left: 0 !important;
    }
}

.pt_startregister {
  #secondary {
    display: none;
  }

  #primary {
    width: 100% !important;
  }

  /* CREATE ACCOUNT STYLES START */
  .start-register-email-label .label-inline-email label {
    font-family: Kipling-Extrabold,Futura,sans-serif !important;
  }

  .create-account-right-wrapper {
    width: 39.5%;
    float: right;

    @include mobile {
      width: 100%;
      margin: 2.5% 0;
    }
  }
  .create-account-wrapper-left {
    width: 100%;
    float: left;
    background-color: #f6f6f6;
    padding: 0;
    box-sizing: border-box;

    .create-new-header {
      font-family: Kipling-ExtraBold,sans-serif;
      font-size: 52px;
      text-transform: none;
      margin: 0;
      padding: 0;

      @include mobile {
        font-size: 32px;
      }
    }

    .create-new-text {
      font-size: 20px;
      margin: 0 0 30px;

      @include mobile {
        font-size: 16px;
      }

      @media (min-width: 1050px) {
        width: 85%;
      }
    }

    fieldset {
      margin: 0 !important;

      .label-inline-first {
        margin: 50px 0 0;

        .fancyCheck:before {
          top: 0;
          margin-top: 0 !important;
        }
      }

      .email-share-policy {
        display: block !important;
        font-family: Kipling-Regular,Futura,sans-serif;
        font-size: 16px !important;
        margin: -2px 0 0 25px !important;
        color: #333 !important;
      }

      .label-inline {
        .fancyCheck {
          float: left !important;
          font-family: Kipling-Regular,Futura,sans-serif;
          font-size: 16px !important;
          padding: 2px 0 0 30px !important;
          margin: 0 !important;
          text-transform: none !important;

          .rewards-brandname {
            color: $color-green;

            .rewards-brandname-bold {
              font-family: Kipling-ExtraBold,Futura,sans-serif;
            }
          }

          &:before {
            margin-top: -12px;
          }
        }
      }

      .form-caption-inline,
      .label-inline-email {
        display: inline-block;
      }

      .form-caption-inline {
        a {
          font-size: 16px;
          text-decoration: underline;
          color: $color-dove-grey;
          font-family: Kipling-Regular,Futura,sans-serif;
        }
      }

      .form-row {
        label {
          font-size: 21px !important;

          @include mobile {
            font-size: 16px !important;
            padding: 15px 0 5px;
          }
        }

        input {
          height: 44px !important;
          font-size: 18px !important;
          border: 1px solid #BDBBBB !important;
        }


      }
    }

    .form-row-button {
      margin: 30px 0 0 !important;

      button {
        height: 40px !important;
        width: 170px !important;
        border-radius: 0 !important;
        font-size: 20px !important;
      }

    }
  }
  /* CREATE ACCOUNT STYLES END */
  .signin-container {
    width: 100%;
    margin: 0 0 2.5%;

    .signin-img-wrapper {
      width: 345px;
      float: left;
      margin: 0 1% 0 0;

      @include desktop-1600 {
        width: 299.5px;
      }
    }

    .signin-wrapper {
      width: auto;
      background-color: #f6f6f6;
      padding: 40px;
      overflow: hidden;

      @include desktop-1600 {
        padding: 20px 30px;
      }

      @include mobile {
        width: 100%;
        padding: 20px;
      }

      .login-box {
        margin-right: 0 !important;

        h2 {
          text-transform: capitalize;
          font-size: 51px !important;
          background-color: #f6f6f6 !important;
          padding: 0 0 20px;

          @include desktop-1600 {
            font-size: 40px !important;
            padding: 0 0 10px;
          }

          @include mobile {
            font-size: 32px !important;
            padding: 0;
          }
        }

        label {
          font-size: 20px;
          padding: 20px 0 10px;

          @include mobile {
            font-size: 16px;
            padding: 15px 0 5px;
          }
        }

        label.fancyCheck {
          font-family: Kipling-Regular,Futura,sans-serif;
          padding: 13px 0 0 30px;
        }

        input {
          width: 100% !important;
          box-sizing: border-box;
          height: 42px;
          border: 1px solid #BDBBBB;
          border-radius: 0;
          font-size: 18px;
          line-height: 44px;
        }

        .form-row-button {
          padding-bottom: 0 !important;
          margin: 0 !important;
          border: none !important;

          button {
            display: inline-block;
            height: 40px;
            width: 170px !important;
            border-radius: 0;
            font-size: 20px;
            margin: 0 35% 0 0;

            @include mobile {
              margin: 0 10% 0 0;
            }
          }

          #password-reset {
            display: inline-block !important;
            font-size: 20px !important;
            letter-spacing: 1px;
            position: relative;

            &:after {
              content: '';
              width: 100%;
              position: absolute;
              left: 0;
              bottom: -2px;
              border-width: 0 0 1.4px;
              border-style: solid;
              border-color: $color-green;
            }
          }
        }
      }
    }
  }
}

.fancyCheck.disabled:before{
  background: #cfcfcf
}
.padding-left-30 {
  padding-left: 30px !important;
}
.underline {
  text-decoration: underline;
}
.current-point-balance {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  @media screen and (min-width: 960px) {
  	margin-left: 247px;
  }

}

.js-list-of-products {
  .element-product {
    padding-bottom: 10px;
    padding-top: 10px;
    .elem-1 {
      width: 50%;
      @media screen and (max-width: 380px) {
        width: 40%
      }
    }
    .elem-1, .elem-2, .elem-3{
      display: inline-block;
      vertical-align: top;
    }
    .elem-2, .elem-3 {
      text-align: center;
      font-weight: bold;
      width: 24%;
    }
    .elem-1-1 {
      font-weight: bold;
    }
  }
}

.list-rewards {
    font-family: Kipling-Regular, Futura, sans-serif;
    font-size: 15px;
    color: #666;
    padding-bottom: 10px;
    margin-top: 0;
    line-height: 1.4em;
    li {
          list-style-type: disc;
    }
}
.registration-account {
  max-width: 300px;
  top: 13%;
  right: -5%;
  background: white;
  clear: both;
}

.pt_order .lnc-widget-order-history .label.customizable {
  font-family: Kipling-Regular, Futura, sans-serif;
}

.pt_order .linc[data-source='orderhistory'] .linc-button {
  font-family: Kipling-Extrabold, Futura, sans-serif;
  font-size: 15px !important;
}

@media screen and (max-width: 960px) {
  .registration-account {
    position: relative;
    max-width: 100%;
  }
  #main {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .account {

    #primary,
    #secondary {
      width: 100%;
      float: none;
      padding-bottom : 0px;
    }
  }

  legend span {
    top: 0;
    padding-right: 0;
    position: relative;
    right: 0;
  }

  label,
  .form-label-text {
    font-size: 14px;
  }

  input.email-input,
  .cart-coupon-code input,
  input.password-input,
  .gift-certificate-purchase form .input-text,
  .gift-certificate-purchase form .input-textarea,
  .gift-cert-balance input,
  #dwfrm_ordertrack input,
  #RegistrationForm input.input-text,
  #RegistrationForm .input-textarea,
  #RegistrationForm .input-text-pw,
  #resetPassBox input,
  #setPassword input, .newsletterSubscriptionForm .ovlb,
  #StoreLocator input,
  #send-to-friend-main input,
  #send-to-friend-main .input-textarea {
    height: 40px;
    padding: 0 10px 0 10px;
    font-size: 16px;
  }

  .button-fancy-medium,
  .login-box button,
  #saveBtn,
  #mini-cart .checkoutLink,
  #CheckBalanceButton,
  #contactUsKIP button,
  .order-history-header button, .order-history-header .order-detail,
  #RegistrationForm button,
  .form-row-button button.button-fancy-medium,
  #resetPassBox button,
  #setPassword button,
  #emailSignUpArea .signUpBtn,
  .showMore .shopAllBtn,
  #send-to-friend-main .edit-button,
  #send-to-friend-main .preview-button,
  #send-to-friend-main .send-button,
  .back-to-top button,
  #product-set-list .add-to-cart {
    height: 40px;
    padding: 0 20px 0 20px;
    width: 100%;
    font-size: 18px;
    line-height: 1;
  }


  .cart-coupon-code .ovlbInput::placeholder {
  	@include mobile {
  		font-size: 12px;
  	}
  }

  .login-box button {
    width: auto !important;
  }

  #RegistrationForm {

    .input-text-pw {
      width: 100%;
    }

    .form-caption {
      padding-left: 0;
    }

    .form-row-button {
      width: 100%;
      margin: 45px 0 0;
    }

    .form-row {
      margin-bottom: 10px;

      label {
        padding-top: 10px;
        font-size: 14px!important;
      }
      &.form-indent.label-inline{
      	border : 0;
      	padding-top: 0px;
    	margin-top: 0px;
      }
    }

    fieldset {
      margin-bottom: 0;
    }

  }

  .toggleMyAccount {
    font-size: 18px;
    padding-top: 0px;
  }

  .MyAccountUL a {
    font-size: 14px;
  }

  .account-nav-asset h2 {
    font-size: 16px;
  }

  h1 .account-logout {
    float: none;
  }

  .content-asset ul.account-options {

    li {
      float: none;
      width: 100%;

      a {
        padding-left: 55px;
      }
    }
  }

	h1.myAccountLB  {
	    font-size: 22px;
		span {
			color: #333333 !important;
		}

	}
  .pt_account {
  	.address-list {
  		display : inline-block;
  		li {
  			min-width : 110px;
  		}
  	}

  	h1 {
  		padding-bottom : 0px !important;
  	}
  	
    .col-1,
    .col-2 {
      width: 100%;
      height: auto;
      float: none;
      border: none;
    }

    .form-row {
      margin-bottom: 10px;
    }
  }

  #primary .login-box {
    margin-right: 0;
  }

  #primary .login-box-content.returning-customers button,
  #primary .login-box-content button {
    width: 100%;
    margin-bottom: 10px;
  }

  #primary .login-box-content.returning-customers #password-reset {
    margin-left: 0;
  }

  #primary .login-box-content input[type="text"],
  #primary .login-box-content input[type="password"] {
    width: 100%;
  }

  .ui-widget-content {
    width: 100%!important;
    height: auto!important;
    padding: 0;
  }

  .ui-dialog .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-draggable .ui-dialog-titlebar {
    margin: 0;
  }

  .ui-dialog .ui-dialog-titlebar-close {
    right: 5px;
    top: 10px;
  }

  #edit-address-form,
  #CreditCardForm {
    margin-left: 0;

    legend span {
      top: -5px;
      right: 0;
    }
  }

  .address .form-row .input-text{
    width: 100%;
    height: 40px;
    padding: 0 10px 0 10px ;
    font-size: 16px;
    border: 1px solid #c5c6c8;
  }
  .address {
  	legend {
  		padding : 0px;
  	}
  }

  .address .form-row {
    width: 100%;
    margin-right: 0;
    float: none;
  }


  #CreditCardForm .form-row {
    width: 100%;
    margin-right: 0;
    float: none;
  }

  #edit-address-form.address div.spacer {
    display: none;
  }

  .select2-container {
    margin-left: 0;
    width: 100%;
    height: 40px;
    border: 1px solid #c5c6c8!important;

    .select2-choice {
      height: 40px;
      line-height: 40px;

      div b {
        height: 30px;
        background-position: -97px -123px;
      }

      abbr {
        top: 12px;
      }
    }
  }

  .address .form-row .select2-container {
    width: 100%;
    height: 40px;
  }

  #edit-address-form.address .button-fancy-medium,
  #CreditCardForm .button-fancy-medium {
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }

  #edit-address-form.address .form-row.form-row-button .delete-button {
    margin-right: 0;
  }

  td.item-details .product-list-item .label,
  .order-confirmation-details .label,
  .pt_order .label,
  tr.rowbundle .itemnumber .label,
  tr.rowbundle td.item-details .name a,
  .rowcoupons .item-details .label,
  .item-details .cart-coupon .label,
  .item-details .discount .label,
  ul.address-list .mini-address-title,
  #cart-table td.item-details .product-list-item .product-option {
    font-size: 14px;
  }

  .pt_account ul.address-list li.default {
    float: none;
  }

  .pt_account .section-header-note {
    position: relative;
    right: 0;
    display: inline-block;
    width: 100%;
  }

  #CreditCardForm .expiration .month,
  #CreditCardForm .expiration .month .select2-container,
  #CreditCardForm .expiration .year,
  #CreditCardForm .expiration .year .select2-container {
    width: 100%;
    margin-right: 0;
  }

  .order-history-header {

    button, .order-detail {
      margin-bottom: 10px;
    }

    .order-date,
    .order-status,
    .order-number,
    .orderTotal {
      float: none;
      width: 100%;
    }
  }

  .account {

    .item-list-box,
    .order-shipment-table-box {
      width: 100%;
      overflow: auto;
    }
  }

  .mobile-order-details {
	  	h3 {
	  		font-size: 18px;
	    	letter-spacing: 0.075em;
	    	margin-bottom: 20px;
	    	padding-top: 20px;
    	    color: black;
	  	}
	  	h4 {
  		    font-size: 16px;
			letter-spacing: 0.075em;
			padding: 15px;
		    color: black;
	  	}
	  	.sidebar-basket-order-confirmation {
	  		width : 100%;
	  		.constrain-items {
	  			.content-header {
				     font-size: 16px;
	   				 padding-top: 0px;
	  			}
	  			.product-list-item, .name, .sku, .sku .label   {
	  				font-size : 13px;
	  			}
	  		}
	  		.label {
	  			color : black !important;
	  		}
	  		.section-header {
	  			color : black !important;
	  		}
	  	}

	  	.confirmation-order-details{
	  			border: 1px solid #ccc;
	  			.confirmation-order-details-header {
	  				.label {
	  				    color: black;
					    width: 49%;
						text-align: left;
						display: inline-block;
						padding: 8px 0px 8px 15px;
					    font-size: 13px;
	  				}
	  				.value {
	  					width: 49%;
	  					text-align: right;
    					padding: 8px 10px 8px 15px;
    					display: inline-block;
    					font-size: 13px;
	  				}
	  			}
		}
		.order-totals-table {
			width : 100%;
			td {
				padding: 8px 10px 8px 15px !important;
				width: 50% !important;
			}
			td.totLabel {
				    color: black !important;
			}
		}
		.payment-amount .label, .details .label {
			color : black;
		}
		.order-payment-instruments, .mini-address-name, .mini-address-location {
			padding-left: 15px;
		}
		.order-shipment-address {
			padding-top : 10px;
		}
		.shipping-status-revised {
			margin-bottom : 15px;
			padding-bottom : 15px;
		}
		.shipping-method-revised {
			padding : 0px;
		}
		.sidebar-basket-product-image {
			width : 43%;
			display : inline-block;
		}
		.sidebar-basket-product .details {
		    width: 50%;
			display: inline-block;
    		margin-left: 10px;
		}
  }

}

.black-white-button {
	    background: $color-dark-grey;
    color: #fff!important;
    padding: 5px 20px 0;
    font-size: 13px;
    text-transform: uppercase;
    border: 0;
    border-radius: 3px;
    -moz-border-radius: 3px;
    webkit-border-radius: 3px;
    font-family: Kipling-Extrabold,Futura,sans-serif;
    height: 30px;
    display: inline-block;
    width: auto;
    line-height: 30px;
}


.native-order-history {
	color:black !important;
    font-family: Kipling-Regular,Futura,sans-serif !important;
    td {
    	padding: 5px;
    }
    .mini-address-location , p, a, mini-address-location, .mini-address-name, td, th, tr, span {
    	font-family: Kipling-Regular,Futura,sans-serif !important;
    	color:black !important;
    }
    .item-quantity {
    	display:none !important;
    }

}

.order-detail-options {
	 .button-fancy-medium, strong {
    	    display: block;
    		clear: both;
    		margin: 10px;
    }
    strong {
    	text-align:left;
    }
}



.lnc-widget-order-history .linc-optin-widget{
	margin: 15px 0px;
    padding: 10px;
}


.stl_action {
  cursor:pointer !important;
}

/*NEW DESIGN*/
.pt_account, .pt_order, .pt_order-confirmation {
	overflow: hidden;
}

.primary-content .create-account-wrapper {
	@media screen and (max-width: 960px) {
    	padding: 0;
    }
}

.rewards-user-header-wrapper {
    height: 110px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    z-index: 1;
    position: relative;
    justify-content: space-between;
    
    @media screen and (max-width: 960px) {
    	height: 90px;
    	align-items: flex-start;
    	padding-top: 20px;
    }
    
    &:after {
    	content: '';
    	position: absolute;
    	left: -100%;
    	right: -100%;
    	top: 0;
    	background: #f0f0f0;
    	bottom: 0;
    	z-index: -1;
    }
}

.account #primary h1 {
	display: none;
}

.pt_account, .pt_order, .js-pt_account {
	.create-account-wrapper .edit-profile-wrapper .select2-container .custom-chevron:before {
    	border-color: #2d2f2c;
    }
    
    .address .form-row {
		margin-right: 0!important;
		padding: 0!important;
	}
	
	.tabs {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		
		.tab {
			padding: 0;
			max-width: 50%;
			
			img {
				max-width: 100%;
				
				&.desk {
					display: block;
					
					@media screen and (max-width: 737px) {
				    	display: none;
				    }
				}
				
				&.mob {
					display: none;
					
					@media screen and (max-width: 737px) {
				    	display: block;
				    }
				}
			}
		}
	}
	
	.body {
		.fa {
			font-family: FontAwesome!important;
		}
		
		.primary-block {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #dbdbdb;
			position: relative;
			
			&.kip-rewards {
				background: #dbf7be;
				display: block;
				padding: 25px;
				
				@media screen and (max-width: 960px) {
			    	padding: 20px;
			    }
			    
			    .sub-block {
			    	background: #dbf7be!important;
			    }
				
				p {
					font-size: 15px;
    				font-family: 'Kipling-Regular', sans-serif;
    				color: #292929;
				}
				
				li {
					display: flex;
					align-items: center;
					margin: 20px 0;
					font-size: 13px;
    				font-family: 'Kipling-Regular', sans-serif;
    				color: #292929;
    				
    				.img-wrap {
    					width: 58px;
    					flex-shrink: 0;
    				}
    				
    				img {
    					max-width: 100%;
    				}
				}
				
				.block-info {
					width: 100%!important;
					margin: 0!important;
					padding: 0!important;
					
					.sub-block {
						padding: 0!important;
						width: 100%!important;
						
						label.fancyCheck {
							margin-bottom: 20px!important;
						}
						
						label.fancyCheck a {
							display: inline-block!important;
						}
					}
				}
			}
			
			.title-list {
				width: 100%;
				padding: 30px 20px;
				background: #dbdbdb;
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;
				font-size: 18px;
    			font-family: 'Kipling-Regular', sans-serif;
    			color: #191919;
    			
    			@media screen and (max-width: 960px) {
			    	font-size: 16px;
			    	padding: 15px;
			    }
				
				i {
					transform: rotate(180deg);
				}
			}
			
			.block-info {
				width: 45%;
				padding: 20px;
				box-sizing: border-box;
				
				@media screen and (max-width: 960px) {
			    	width: 92%;
			    	padding: 15px;
			    }
				
				&:last-child {
					width: 55%;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					
					@media screen and (max-width: 960px) {
				    	width: 100%;
				    	padding: 0 0 15px 0;
				    }
					
					.sub-block {
						&:first-child {
							width: 430px;
							flex-shrink: 0;
							background: #badb94;
							padding: 15px 15px 5px 15px;
							
							@media screen and (max-width: 960px) {
						    	width: 100%;
						    }
							
							.js-second-step {
								padding: 30px 25px 20px 25px;
								
								@media screen and (max-width: 960px) {
									padding: 20px 0;
								}
								
								.action-buttons {
									justify-content: center;
									
									a {
										&:last-child {
											margin-right: 0;
										}
									}
								}
								
								.text-block {
									margin-bottom: 20px;
									font-size: 16px;
									
									.big-text {
										display: flex;
										font-size: 20px;
										align-items: flex-end;
										margin-top: -12px;
										
										@media screen and (max-width: 960px) {
											font-size: 18px;
										}
										
										img {
											position: relative;
    										top: 6px;
										}
									}
								}
							}
							
							img {
								width: 110px;
							}
							
							.text-block {
								text-align: left;
								font-size: 13px;
    							font-family: 'Kipling-Regular', sans-serif;
    							color: #292929;
    							margin: 0 0 10px 0;
    							float: none;
    							
    							@media screen and (max-width: 960px) {
							    	font-size: 12px;
							    }
							}
							
							label.fancyCheck {
								margin: 0 0 10px 0;
								padding-top: 0;
								padding-bottom: 0;
								text-transform: none;
								font-size: 13px;
    							font-family: 'Kipling-Regular', sans-serif;
    							color: #292929;
    							
    							@media screen and (max-width: 960px) {
							    	font-size: 12px;
							    }
    							
    							&:before {
    								background: #fff!important;
    							}
    							
    							a {
    								display: block;
    							}
							}
							
							.action-buttons {
								display: flex;
								flex-wrap: wrap;
								
								a {
									display: block;
									font-size: 13px;
	    							font-family: 'Kipling-Regular', sans-serif;
	    							color: #ffffff;
	    							width: 170px;
	    							padding: 10px;
	    							box-sizing: border-box;
	    							border-radius: 10px;
	    							margin: 0 10px 10px 0;
	    							text-transform: uppercase;
	    							background: #2d2f2c;
	    							transition: .2s ease-in-out all;
	    							text-align: center;
	    							font-weight: bold;
	    							
	    							@media screen and (max-width: 960px) {
								    	font-size: 12px;
								    	margin: 0 5px 5px 0;
								    	width: calc(50% - 5px);
								    }
	    							
	    							&:hover {
	    								background: #000;
	    							}
								}
							}
						}
						
						&:nth-child(2) {
							a {
								color: #111011;
								font-size: 16px;
	    						font-family: 'Kipling-Regular', sans-serif;
	    						text-decoration: underline;
	    						
	    						@media screen and (max-width: 960px) {
							    	font-size: 13px;
							    	position: absolute;
							    	top: 60px;
							    	right: 5px;
							    }
							}
						}
					}
				}
				
				.customer-information {
					display: flex;
					flex-wrap: wrap;
					
					.column {
						width: 50%;
						padding-right: 10px;
						box-sizing: border-box;
						
						@media screen and (max-width: 960px) {
							padding-right: 5px;
							
							&:first-child {
								width: 60%;
							}
							
							&:last-child {
								width: 40%;
							}
						}
						
						.block {
							margin-bottom: 20px;
							
							.title {
								margin-bottom: 15px;
								color: #292929;
								font-family: 'Kipling-Regular', sans-serif;
								font-size: 16px;
							}
							
							.value {
								color: #6c6a71;
								font-family: 'Kipling-Regular', sans-serif;
								font-size: 16px;
								text-overflow: ellipsis;
							    max-width: 100%;
							    overflow: hidden;
								
								@media screen and (max-width: 960px) {
							    	font-size: 14px;
							    }
							}
						}
					}
				}
			}
			
			.tooltip {
				position: relative;
				
				&:hover {
					.tooltip-text {
						display: block;
					}
				}
				
				.tooltip-mark {
					width: 16px;
					height: 16px;
					font-family: 'Kipling-Regular', sans-serif;
					font-size: 12px;
					color: #fff;
					background: #201d1e;
					border-radius: 50%;
					margin-left: 6px;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					padding: 3px 0 0 0;
				    box-sizing: border-box;
				    display: inline-flex;
				}
				
				.tooltip-text {
					position: absolute;
					left: -96px;
					top: 25px;
					display: none;
					z-index: 1;
					border: 1px solid #000000;
					width: 220px;
					box-sizing: border-box;
					padding: 10px 15px;
					font-family: 'Kipling-Regular', sans-serif;
					font-size: 13px;
					background: #fff;
					color: #000000;
					box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
					
					@media screen and (max-width: 960px) {
						width: 180px;
						font-size: 12px;
						left: -76px;
						padding: 10px;
					}
					
					&:after {
		    			content: '';
		    			position: absolute;
		    			left: 50%;
		    			top: -6px;
		    			margin-left: -8px;
		    			border-style: solid;
						border-width: 0 8px 6px 8px;
						border-color: transparent transparent #000000 transparent;
						z-index: 1;
		    		}
		    		
		    		&:before {
		    			content: '';
		    			position: absolute;
		    			left: 50%;
		    			top: -5px;
		    			margin-left: -8px;
		    			border-style: solid;
						border-width: 0 8px 6px 8px;
						border-color: transparent transparent #ffffff transparent;
						z-index: 2;
		    		}
				}
			}
		}
		
		.other-actions {
			.account-options {
				&.grey {
					border-top: 1px solid #7d7d7d;
					
					li {
						background: #dbdbdb;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 20px;
						border: 1px solid #7d7d7d;
						border-top: none;
						font-size: 18px;
		    			font-family: 'Kipling-Regular', sans-serif;
		    			color: #7d7d7d;
		    			
		    			@media screen and (max-width: 960px) {
					    	font-size: 16px;
					    	padding: 15px;
					    }
					}
				}
			}
			li {
				&.option-active {
					background: #dbdbdb;
					
					&.green {
						background: #dbf7be;
					}
				}
			}
			a {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px;
				border: 1px solid #dbdbdb;
				border-top: none;
				font-size: 18px;
    			font-family: 'Kipling-Regular', sans-serif;
    			color: #191919;
    			
    			@media screen and (max-width: 960px) {
			    	font-size: 16px;
			    	padding: 15px;
			    }
			}
		}
	}
}

.edit-profile .edit-profile-wrapper {
	@media screen and (max-width: 960px) {
		padding: 15px 0;
	}
	
	fieldset {
		display: flex;
		flex-wrap: wrap;
		padding-right: 50px;
		
		@media screen and (max-width: 960px) {
			padding: 0;
			justify-content: space-between;
		}
	}
	
	.form-row {
		width: 33.33333%;
		float: none!important;
		padding-right: 30px;
		box-sizing: border-box;
		margin: 0;
		
		@media screen and (max-width: 960px) {
			width: 100%;
			padding: 0;
			
			&:nth-child(1), &:nth-child(2) {
				width: calc(50% - 7px);
			}
		}
		
		&.form-row-button {
			max-width: 390px;
			
			@media screen and (max-width: 960px) {
				max-width: 100%;
				padding: 0 15px;
			}
		}
		
		input {
			width: 100%!important;
    		box-sizing: border-box;
		}
		
		.select2-container {
			width: 100%;
			border: 1px solid #2d2f2c!important;
    		border-radius: 7px!important;
    		color: #2d2f2c!important;
    		height: 40px!important;
    		font-size: 16px!important;
    		margin: 0!important;
    		padding: 0!important;
    		
    		.select2-choice {
    			height: 40px;
    			line-height: 40px;
    		}
		}
		
		&.month-day-birth__container {
			display: flex;
			flex-wrap: wrap;
			
			label {
				display: block;
				width: 100%;
			}
			
			.styled-select--month-of-birthday.ep--select {
				width: 42%;
				margin-right: 3%;
				float: none;
			}
			
			.ep--select.styled-select--day-of-birthday {
				width: 25%;
				margin-right: 3%;
				float: none;
			}
			
			.ep--select.styled-select--year-of-birthday {
				width: 27%;
				float: none;
			}
		}
	}
	
	.tooltip {
		position: relative;
		
		&:hover {
			.tooltip-text {
				display: block;
			}
		}
				
		.tooltip-mark {
			width: 16px;
			height: 16px;
			font-family: 'Kipling-Regular', sans-serif;
			font-size: 12px;
			color: #fff;
			background: #201d1e;
			border-radius: 50%;
			margin-left: 6px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			padding: 3px 0 0 0;
			box-sizing: border-box;
			display: inline-flex;
		}
				
		.tooltip-text {
			position: absolute;
			left: -96px;
			top: 25px;
			display: none;
			z-index: 1;
			border: 1px solid #000000;
			width: 220px;
			box-sizing: border-box;
			padding: 10px 15px;
			font-family: 'Kipling-Regular', sans-serif;
			font-size: 13px;
			background: #fff;
			color: #000000;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
					
			&:after {
		    	content: '';
		    	position: absolute;
		    	left: 50%;
		    	top: -6px;
		    	margin-left: -8px;
		    	border-style: solid;
				border-width: 0 8px 6px 8px;
				border-color: transparent transparent #000000 transparent;
				z-index: 1;
		    }
		    		
		    &:before {
		    	content: '';
		    	position: absolute;
		    	left: 50%;
		    	top: -5px;
		    	margin-left: -8px;
		    	border-style: solid;
				border-width: 0 8px 6px 8px;
				border-color: transparent transparent #ffffff transparent;
				z-index: 2;
		    }
		}
	}
}

.account-fullwidth-info, .edit-profile .edit-profile-wrapper {
	border: none;
	border-bottom: 1px solid #dbdbdb;
    margin: 0;
    
    @media screen and (max-width: 960px) {
    	padding: 0 15px;
    }
    
    .payment-header-info {
    	display: none;
    }
    
    &.account-fw-order {
		padding: 0;
		
		.search-result-items {
			padding: 0;
			background: none;
		}
	}
}

.pt_order ul.search-result-items li {
	margin: 0;
	padding: 20px;
	border: none;
	
	@media screen and (max-width: 960px) {
    	padding: 15px;
    }
	
	&:nth-child(2n + 2) {
		background: #f0f0f0;
	}
}

.account-fw-order .orderTotal {
	float: none;
	padding: 0!important;
}

.order-history-header .order-date, .order-history-header .order-number, .order-history-header .order-status {
	float: none;
	margin: 0;
	width: 100%;
}

.order-history-header {
	padding: 0;
	line-height: normal!important;
	
	button {
		float: none;
		width: auto!important;
	    font-size: 16px!important;
	    padding: 0;
	    height: auto;
	    background: none;
	    color: #2d2c2f!important;
	    font-family: 'Kipling-Extrabold', sans-serif;
	    text-transform: capitalize;
	    text-decoration: underline;
	    
	    &:hover {
	    	background: none;
	    }
	}
}

.account-fw-order .order-history-header {
	font-family: 'Kipling-Regular', sans-serif;
	font-weight: normal;
	color: #6c6a71!important;
}

.account-fw-order .order-history-header .label {
	font-family: 'Kipling-Regular', sans-serif;
	font-weight: normal;
	color: #2d2c2f!important;
}

.account-fw-adresses {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	box-sizing: border-box;
	padding-bottom: 25px;
	position: relative;
	
	.section-header {
		order: 1;
		width: 20%;
		padding: 20px 0 0 0;
		border: none;
		position: static;
		
		@media screen and (max-width: 960px) {
			padding: 0;
		}
		
		a {
			color: #111011!important;
			font-size: 16px!important;
			font-family: 'Kipling-Regular', sans-serif!important;
			text-decoration: underline!important;
			text-transform: capitalize!important;
			
			@media screen and (max-width: 960px) {
		    	font-size: 13px!important;
		    	position: absolute;
		    	right: 5px;
		    	top: 20px;
		    }
		}
	}
	
	.address-list, .payment-list {
		order: 0;
		width: 80%;
		margin: 0;
		
		@media screen and (max-width: 960px) {
	    	width: 100%;
	    }
	}
	
	ul.address-list .first.default h3 {
		font-size: 16px!important;
		font-family: 'Kipling-Regular', sans-serif!important;
		text-transform: capitalize!important;
		margin: 0 0 5px 0;
		color: #292929;
		font-weight: 400;
	}
	
	ul.address-list li {
		padding: 20px 0 20px 0;
		color: #6c6a71;
		font-size: 16px!important;
		font-family: 'Kipling-Regular', sans-serif!important;
	}
	
	ul.payment-list li {
		color: #6c6a71;
		font-size: 16px!important;
		font-family: 'Kipling-Regular', sans-serif!important;
	}
	
	ul.address-list .mini-address-title {
		font-size: 16px!important;
		font-family: 'Kipling-Regular', sans-serif!important;
		text-transform: capitalize!important;
		margin: 0 0 5px 0;
		color: #292929;
		padding: 0;
		margin: 0;
	}
	
	ul.address-list li:nth-child(2) .mini-address-title {
		margin-top: 24px;
		
		@media screen and (max-width: 960px) {
	    	margin-top: 0;
	    }
	}
	
	ul.address-list li a, ul.payment-list li a, ul.payment-list li button {
		color: #111011!important;
		font-size: 16px!important;
		font-family: 'Kipling-Regular', sans-serif!important;
		text-decoration: underline!important;
		text-transform: capitalize!important;
		margin: 0 20px 0 0;
		
		@media screen and (max-width: 960px) {
	    	font-size: 13px!important;
	    }
		
		&:last-child {
			margin: 0;
		}
		
		&:after {
			display: none;
		}
	}
}

h1.myAccountLB {
	margin-left: 30px;
	margin-right: 30px;
	
	@media screen and (max-width: 960px) {
		margin-left: 0;
		margin-right: 0;
	}
}

h1.myAccountLB, h1.myAccountLB span {
	text-align: left;
	font-family: 'Kipling-Regular', sans-serif!important;
	text-transform: capitalize!important;
	font-size: 22px;
	color: #191919;
}

#edit-address-form legend span, #CreditCardForm legend span {
	position: static;
	color: #3b7028;
}

#CreditCardForm legend {
	padding: 0;
}

#edit-address-form.address div.spacer {
	display: none;
}

.address .form-row .select2-container {
	margin-left: 0;
}

#edit-address-form, #CreditCardForm {
	margin: 0 30px 30px 30px;
	
	@media screen and (max-width: 960px) {
		margin-left: 0;
		margin-right: 0;
	}
}

.address {
	.form-label-text, label {
		color: $color-urban-black;
		font-family: 'Kipling-Regular', sans-serif;
		text-transform: capitalize;
		font-size: 16px;
		padding-bottom: 15px;
	}
	
	.form-caption {
		font-size: 13px;
	}
}

.address .form-row.form-row-button {
	margin-top: 15px;
	
	button, a {
		float: none!important;
	    display: block;
	    width: 100%!important;
	    font-size: 16px!important;
	    padding: 10px!important;
	    box-sizing: border-box;
	    height: auto;
	    text-align: center!important;
	    
	    @media screen and (max-width: 960px) {
			font-size: 14px;
			
			&.cancel-button {
				color: #2d2f2c!important;
	    		background: transparent!important;
	    		font-size: 16px!important;
			}
		}
	}
}

#CreditCardForm .form-row {
	width: 455px;
	
	@media screen and (max-width: 960px) {
		width: 100%;
	}
}

.pt_order-confirmation .summary-primary-left > a.button-fancy-medium  {
	margin-top: 15px;
	margin-bottom: 0;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    height: auto;
    font-family: 'Kipling-Extrabold';
}

.order-detail-options .button-continue-shopping {
	font-size: 16px;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    height: auto;
    font-family: 'Kipling-Extrabold';
    background: #2d2f2c;
    color: #fff!important;
    text-decoration: none;
    padding: 10px;
    border-radius: 3px!important;
    
    @media screen and (max-width: 960px) {
		width: 100%;
	}
}

.sidebar-basket-product {
	a.button-fancy-medium {
		background: transparent;
	    color: #333!important;
	    text-align: left;
	    padding: 0;
	    text-transform: capitalize;
	    font-size: 14px;
	    text-decoration: underline;
	    font-family: 'Kipling-Extrabold';
	}
}

.stellar-offers {
	display: flex!important;
	flex-wrap: wrap;
	padding-bottom: 25px;
	align-items: flex-start;
	height: auto!important;
	
	.stl_content {
		width: 23%!important;
		margin: 1%!important;
		min-width: 0!important;
		flex-shrink: 0;
		position: static!important;
		box-sizing: border-box;
		
		@media screen and (max-width: 960px) {
			width: 46%!important;
			margin: 2%!important;
		}
		
		.stl_body_bgcolor {
			background: #fff!important;
		}
		
		.stl_heading {
			font-size: 22px!important;
			
			@media screen and (max-width: 960px) {
				font-size: 18px!important;
			}
		}
		
		.stl_action {
			height: auto!important;
			
			a {
				font-size: 16px!important;
				
				@media screen and (max-width: 960px) {
					font-size: 14px!important;
				}
			}
		}
	}
}

.rewards-col-header {
	li {
		border: none!important;
		font-family: 'Kipling-Regular', sans-serif!important;
		font-size: 16px;
	}
}

.account-fw-rewards .search-result-items .element-product .elem-1 {
	font-size: 14px!important;
}

.account-fw-rewards .search-result-items .element-product .elem-3 {
	width: 100%!important;
}

.account-fw-rewards {
	.search-result-items {
		padding: 0;
		background: transparent;
		margin: 0 -20px;
		
		button {
			color: #6c6b73!important;
			font-family: 'Kipling-Regular', sans-serif!important;
			font-size: 14px!important;
			margin-top: 5px!important;
			
			&:hover {
				background: transparent!important;
			}
		}
		
		li {
			padding: 10px 20px;
			color: #6c6b73!important;
			
			span {
				color: #6c6b73!important;
			}
			
			&:nth-child(2n + 2) {
				background: #f0f0f0;
			}
			
			.order-history-header {
				display: flex;
			}
		}
	}
}

.tracking-block {
	padding: 30px 0 25px 0;
	
	img {
		background:#4e148d; padding: 15px; width: 140px; box-sizing: border-box;
		
		@media screen and (max-width: 960px) {
			width: 100px;
			padding: 10px;
		}
	}
	
	.info {
		font-size: 16px; margin-top: 15px;
		
		@media screen and (max-width: 960px) {
			font-size: 14px;
			margin-top: 10px;
		}
	}
	
	a {
		color: #fb311d;
	}
}

.MyAccountUL {
	@media screen and (max-width: 960px) {
		padding: 25px 10px 0 10px!important;
	}
}

.MyAccountUL a {
	font-family: 'Kipling-Regular', sans-serif!important;
	font-size: 22px!important;
	display: block;
	margin-bottom: 25px;
	text-transform: none!important;
	
	@media screen and (max-width: 960px) {
			font-size: 14px!important;
			margin-bottom: 12px;
			text-decoration: underline;
		}
}

.account-img {
	text-align: center;
	
	img {
		display: block;
		margin: 0 auto;
		
		@media screen and (max-width: 960px) {
			width: 50%;
		}
	}
}

.situation.notsignin {
	margin-top: 30px;
	margin-bottom: 60px;
	
	@media screen and (max-width: 960px) {
		margin-top: -5px;
   		margin-bottom: 25px;
	}
	
	.bg {
		display: none;
		
		@media screen and (max-width: 960px) {
			display: flex;
		}
		
		position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    align-items: center;
	    justify-content: center;
	    z-index: 0;
	    
	    img {
	    	display: block;
		    width: 100%;
		    height: 100%;
		    object-fit: contain;
	    }
	}
	
	.rl--cta__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		position: relative;
		z-index: 1;
		
		.rl--cta__header {
			font-family: 'Kipling-Regular', sans-serif!important;
			font-size: 16px!important;
			display: block;
			text-align: center;
			margin-bottom: 10px;
			color: #292929;
			
			
			@media screen and (max-width: 960px) {
				margin-top: 15px;
			}
		}
		
		.rl--cta__btn {
			text-align: center;
			
			a {
				font-family: 'Kipling-Regular', sans-serif!important;
				font-size: 14px!important;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				color: #fff;
				background: #2d2f2c;
				height: 34px;
				width: 230px;
				border-radius: 8px;
				padding: 0;
				text-decoration: none;
				
				&:hover {
					background: #000;
				}
			}
		}
	}
}

.reg-kip-land {
	margin-bottom: 50px;
	
	.bg {
		display: none;
		
		@media screen and (max-width: 960px) {
			display: flex;
		}
		
		position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    align-items: center;
	    justify-content: center;
	    z-index: 0;
	    
	    img {
	    	display: block;
		    width: 100%;
		    height: 100%;
		    object-fit: contain;
	    }
	}
	
	.text-block {
		font-family: 'Kipling-Light', sans-serif!important;
		font-size: 24px!important;
		text-align: center;
		position: relative;
		z-index: 1;
		
		img {
			height:50px;
			position: relative;
    		top: 16px;
		}
		
		@media screen and (max-width: 960px) {
			font-size: 18px!important;
		}
	}
	
	.label-box {
		text-align: center;
		position: relative;
		z-index: 1;
	}
	
	label.fancyCheck {
		text-transform: none;
		margin: 15px auto 30px auto;
		clear: both;
		padding-top: 0;
		padding-bottom: 0;
		display: inline-block;
		
		@media screen and (max-width: 960px) {
			width: 75%!important;
		}
		
		div:last-child {
			display: none;
		}
	}
	
	.sub-block {
		position: relative;
		z-index: 1;
	}
	
	.js-second-step {
		background: #badb94;
		
		.action-buttons {
			padding: 20px 0;
		}
	}
	
	.action-buttons {
		text-align: center;
		display: flex;
		justify-content: center;
		width: 100%;
		position: relative;
		z-index: 1;
		
		a {
			font-family: 'Kipling-Regular', sans-serif!important;
				font-size: 14px!important;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				color: #fff;
				background: #2d2f2c;
				height: 34px;
				width: 230px;
				border-radius: 8px;
				padding: 0;
				text-decoration: none;
				text-transform: uppercase;
				margin-right: 10px;
				
				&:last-child {
					margin-right: 0;
				}
				
				&:hover {
					background: #000;
				}
		}
	}
}

.points-blance-landing {
	background: #badb94;
	font-family: 'Kipling-Light', sans-serif!important;
	font-size: 24px!important;
	text-align: center;
	padding: 20px 0;
	margin-top: 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media screen and (max-width: 960px) {
		display: block;
	}
	
	&:after {
        content: '';
        position: absolute;
        left: -100%;
        width: 100%;
        top: 0;
        bottom: 0;
        background: #badb94;
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        right: -100%;
        width: 100%;
        top: 0;
        bottom: 0;
        background: #badb94;
        z-index: 1;
    }
	
	@media screen and (max-width: 960px) {
		font-size: 18px!important;
	}
	
	.button {
		text-transform: uppercase;
		color: #fff;
		background: #2d2f2c;
		font-size: 14px;
	    font-weight: bold;
	    padding: 7px 30px 5px;
	    margin-left: 20px;
	    border-radius: 6px;
	    display: inline-block;
	    
	    @media screen and (max-width: 960px) {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}

.gift-certificate-wrap {
	display: flex;
    align-items: center;
    position: relative;
    justify-content: space-around;
    height: 180px;
    border: 3px solid #90ae59;
    padding: 5px;
    margin: 8px 0;
    
    @media screen and (max-width: 960px) {
		height: 140px;
	}
    
    .bg {
    	position: absolute;
    	left: 5px;
    	right: 5px;
    	top: 5px;
    	bottom: 5px;
    	z-index: 1;
    	
    	img {
    		display: block;
    		width: 100%;
    		height: 100%;
    	}
    }
    
    .logo, .column {
    	position: relative;
    	z-index: 2;
    }
    
    .logo {
    	img {
    		width: 140px;
    		
    		@media screen and (max-width: 960px) {
				width: 100px;
				margin-left: -7px;
			}
    	}
    }
    
    .row-reward-info {
    	color: #000;
    	font-family: 'Kipling-Extrabold', sans-serif!important;
		font-size: 14px!important;
		text-transform: uppercase;
		
		@media screen and (max-width: 960px) {
			font-size: 12px!important;
		}
    }
    
    .giftcert-info {
    	@media screen and (max-width: 960px) {
        	width: 50%;
    		flex-shrink: 0;
    	}
    	
    	span {
    		color: #000;
	    	font-family: 'Kipling-Regular', sans-serif!important;
			font-size: 14px!important;
			font-style: italic;
			
			@media screen and (max-width: 960px) {
				position: absolute;
				left: 40px;
    			bottom: 5px;
			    white-space: nowrap;
			    font-size: 12px!important;
			}
    	}
    }
    
    .final-part {
    	text-align: center;
    	margin-top: -25px;
    	
    	@media screen and (max-width: 960px) {
    		margin-top: -8px;
    	}
    	
    	.block-1 {
    		display: flex;
    		align-items: center;
    		
    		@media screen and (max-width: 960px) {
				display: block;
			}
    		
    		span {
    			display: block;
    			
    			&:nth-child(1) {
    				color: #000;
			    	font-family: 'Kipling-Extrabold', sans-serif!important;
					font-size: 44px!important;
					margin-right: 20px;
					margin-top: 5px;
					text-transform: uppercase;
					
					@media screen and (max-width: 960px) {
						margin-right: 0;
						margin-top: 0;
						font-size: 38px!important;
					}
    			}
    			
    			&:nth-child(2) {
    				color: #90ae59;
    				font-family: 'Kipling-Extrabold', sans-serif!important;
					font-size: 14px!important;
					text-transform: uppercase;
					
					@media screen and (max-width: 960px) {
						font-size: 12px!important;
						margin-top: -5px;
					}
    			}
    		}
    	}
    	
    	.block-2 {
    		font-size: 16px!important;
    		color: #000;
    		text-transform: uppercase;
    		
    		@media screen and (max-width: 960px) {
				font-size: 12px!important;
				margin-top: 5px;
				
				strong {
					display: block;				
				}
			}
    	}
    }
}

.line-green-account {
	width: 100%;
	height: 6px;
	background: #dbf7be;
}

@media screen and (max-width: 960px){
	.account-fw-adresses .payment-list li {
	    width: 100%;
	    margin: 10px 0 10px;
	    padding: 15px;
	}
	
	.account-fw-adresses .payment-list li:nth-child(even) {
    	background: #dbdbdb;    	
	}
	.account-fw-adresses.payment-details {
		padding:0px !important;
	}
}