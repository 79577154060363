.product-set-alt {
	color: $black;
	padding-bottom: 60px;
	@include mobile {
		padding: 30px 0 0 0;
	}
	@include mobile-xs {
		padding: 30px 30px 0 30px;
	}
	@include mobile-xss {
		padding: 30px 0 0 0;
	}
	.mobile-quantity-parent {
		#pdp-detail & {
			padding: 0;
			input {
				height: 21px;
				width: 23px;
				border-radius: 2px;
				border: 1px solid $color-middle-light-grey;
				font-size: 10px;
				color: $black;
				padding: 0;
				text-align: center;
				line-height: 20px;
				&:focus {
					border-radius: 2px;
					border-width: 0.5px;
				}
			}
			span {
				width: 6px;
				height: 6px;
				position: relative;
				font-size: 0;
				line-height: 0;
				overflow: hidden;
				cursor: pointer;
				outline: 0;
				&:after,
				&:before {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					background: $black;
					border-radius: 1px;
				}
				&:after {
					width: 2px;
					height: 6px;
				}
				&:before {
					width: 6px;
					height: 2px;
				}
			}
			.fa-minus {
				margin-right: 8px;
				&:after {
					display: none;
				}
			}
			.fa-plus {
				margin-left: 8px;
			}
		}
	}
	h1.product-name {
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 13px;
		font-family: $kipling-light;
		text-transform: uppercase;
		text-align: left;
		letter-spacing: 1.92px;
		@include mobile {
			margin-bottom: 24px;
			text-align: center;
		}
		@include mobile-xs {
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 1.6px;
			margin-bottom: 12px;
		}
		a {
			outline: 0;
		}
	}
	.product-set-description {
		text-align: left;
	}
	.product-set-alt-wrapper {
		display: flex;
		align-items: stretch;
		@include mobile {
			display: block;
		}
	}
	.product-wishlist-section {
		&.pdp-wishlist-intance {
			right: 0;
			top: 0;
			.product-wishlist-icon {
				width: 30px;
			}
		}
	}
}

.product-set-about {
	flex: 0 0 430px;
	max-width: 430px;
	margin-right: 58px;
	position: relative;
	@include tablet {
		flex: 0 0 300px;
		max-width: 300px;
		margin-right: 30px;
	}
	@include mobile {
		width: 100%;
		max-width: 500px;
		margin: 0 auto 40px;
	}
	@include mobile-xs {
		max-width: none;
		margin: 0 auto 54px;
	}
	.hero-image {
		display: block;
		width: 100%;
	}
	.product-set-about-bottom {
		display: flex;
		align-items: flex-start;
		margin-top: 12px;
		justify-content: space-between;
		@include tablet {
			display: block;
		}
		@include mobile {
			display: flex;
		}
		@include mobile-xs {
			display: block;
			text-align: center;
		}
		.product-add-to-cart {
			flex: 0 1 auto;
			.add-all-to-cart {
				&.button-fancy-large {
					background: $color-urban-black;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 1.104px;
					min-width: 0;
					max-width: 100%;
					box-sizing: border-box;
					display: inline-block;
					display: inline-flex;
					vertical-align: top;
					padding: 5px 23px;
					border-radius: 10px;
					-webkit-appearance: none;
					align-items: center;
					justify-content: center;
					text-align: center;
					margin: 0 !important;
					width: auto !important;
					transition: background $hover;
					height: auto;
					min-height: 48px;
					@include tablet {
						display: block;
						width: 100% !important;
						margin-top: 12px !important;
					}
					@include mobile {
						display: inline-block;
						display: inline-flex;
						margin: 0 !important;
						width: auto !important;
						position: static;
						z-index: auto;
					}
					&:hover {
						color: $white;
						background: $color-middle-grey;
					}
				}
			}
		}
		.product-set-pricing {
			flex: 0 0 auto;
			margin-right: 15px;
			@include mobile-xs {
				margin-right: 12px;
			}
			.product-price {
				text-align: left;
				margin: 0;
				font-size: 18px;
				line-height: 22px;
				letter-spacing: 1.44px;
				@include mobile-xs {
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 1.28px;
					text-align: center;
					margin-bottom: 10px;
				}
			}
		}
	}
	.product-set-about-box {
		&.is-absolute {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 430px;
			@include tablet {
				width: 300px;
			}
			@include mobile {
				width: auto;
				position: static;
			}
		}
		&.is-fixed {
			position: fixed;
			width: 430px;
			@include tablet {
				width: 300px;
			}
			@include mobile {
				width: auto;
				position: static;
			}
		}
	}
}

.product-set-details {
	display: grid;
	grid-template-areas: 'info actions' 'promotions promotions';
	grid-template-columns: 1fr 164px;
	gap: 20px;
	padding-top: 64px;
	@include tablet {
		padding-top: 36px;
		grid-template-columns: 1fr 144px;
	}
	@include mobile {
		grid-template-columns: 1fr 164px;
		padding-top: 64px;
	}
	@include mobile-s {
		padding-top: 36px;
		grid-template-columns: 1fr 144px;
	}
	@include mobile-xs {
		display: block;
		padding: 0;
	}
	.product-set-details-actions {
		grid-area: actions;
		@include mobile-xs {
			display: flex;
			justify-content: space-between;
			margin-top: 18px;
		}
		.availability {
			display: block;
			margin-top: 6px;
			.availability-msg {
				#pdp-detail & {
					margin: 0;
					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.8px;
					color: $color-middle-light-grey;
				}
			}
		}
		.inventory {
			padding: 0;
		} 
		.product-add-to-cart {
			margin-top: 19px;
			@include mobile-xs {
				margin: 0;
			}
			.add-to-cart {
				&.button-fancy-medium {
					#product-set-list & {
						margin: 0;
						transition: background $hover;
						height: 29px;
						font-size: 10px;
						letter-spacing: 0.8px;
						line-height: 16px;
						display: inline-block;
						display: inline-flex;
						vertical-align: top;
						justify-content: center;
						text-align: center;
						align-items: center;
						padding: 5px 20px;
						border-radius: 6px;
						-webkit-appearance: none;
						outline: 0;
						float: none;
						width: auto;
						max-width: 100%;
						height: auto;
						min-height: 29px;
						font-family: $kipling-extrabold;
						min-width: 132px;
						@include mobile {
							position: static;
							z-index: auto;
						}
						@include mobile-xs {
							min-height: 36px;
							font-size: 13px;
							line-height: 16px;
							letter-spacing: 1.016px;
							min-width: 166px;
						}
					}
				}
			}
		}
		#stickvogel-content {
			margin: 19px 0 -8px 0;
			@include mobile-xs {
				margin: 0 0 12px 0;
				text-align: right;
			}
			.svLinkConfigurator {
				background: $color-urban-black;
				display: inline-block;
				display: inline-flex;
				vertical-align: top;
				font-size: 8px;
				line-height: 10px;
				letter-spacing: 0.617px;
				min-width: 132px;
				max-width: 100%;
				padding: 5px 20px;
				min-height: 20px;
				border-radius: 10px;
				box-sizing: border-box;
				text-transform: uppercase;
				width: auto;
				float: none;
				@include mobile-xs {
					min-width: 144px;
				}
				.pdp-monogaram-img_desktop {
					#pdp-detail & {
						display: block;
						vertical-align: top;
						width: 13px;
						margin: 0 5px;
						top: -2px;
					}
				}
				.monogram-tooltip {
					display: inline-block;
					vertical-align: top;
					left: 0;
					bottom: 0;
					width: 10px;
					height: 10px;
					top: -1px;
					margin-left: 5px;
					.fa-info-circle {
						font-size: 11px;
					}
					.tooltip-content {
						min-width: 0;
						font-size: 10px;
						line-height: 13px;
						padding: 5px;
					}
				}
			}
		}
	}
	.product-set-details-info {
		grid-area: info;
		@include mobile-xs {
			display: grid;
			justify-content: space-between;
			grid-template-columns: 1fr auto;
			gap: 18px;
			padding-top: 64px;
		}
		.product-name {
			font-size: 12px;
			line-height: 14px;
			margin-bottom: 9px;
			letter-spacing: 0.96px;
			font-weight: normal;
			text-align: left;
		}
		.product-price {
			margin: 0;
			text-align: left;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 13px;
			letter-spacing: 0.64px;
			@include mobile-xs {
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.56px;
				margin: -1px 0 -2px 0;
				text-align: right;
			}
			.price-sales {
				color: $color-red-main;
			}
			.price-standard {
				margin-right: 10px;
			}
		}
		.product-variations {
			@include mobile {
				padding: 0;
			}
			.attribute {
				.Color {
					margin: 0 0 0 -3px;
					li {
						padding: 0;
						float: none;
						flex: 0 0 auto;
						margin: 2px;
						width: 20px;
						height: 20px;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: center;
						&.selected {
							border-color: $black;
						}
						a {
							width: 14px;
							height: 14px;
							border-radius: 50%;
							flex: 0 0 auto;
						}
					}
					.swathces-row {
						margin: -2px;
						display: flex;
						flex-wrap: wrap;
					}
				}
				.colorValueLabel,
				.label,
				.pleaseSelectColor {
					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.8px;
					color: $color-middle-light-grey;
					float: none;
					vertical-align: top;
					margin: 0;
				}
				.value {
					margin-top: 5px;
					clear: none;
				}
			}
		}
		.seedeets-box {
			display: inline-block;
			vertical-align: top;
			max-width: 100%;
			color: $black;
			font-size: 10px;
			line-height: 14px;
			letter-spacing: 0.48px;
			margin-top: 16px;
			@include mobile-xs {
				font-size: 12px;
				line-height: 14px;
				margin: 0;
				text-align: right;
			}
			.seedeets {
				text-decoration: underline;
				text-underline-offset: 2px;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.product-set-details-promotions {
		grid-area: promotions;
		@include mobile-xs {
			margin-top: 20px;
		}
	}
	.promotion {
		margin-top: 16px;
		@include mobile {
			text-align: left;
		}
		.promo-details {
			font-size: 10px;
			line-height: 12px;
			letter-spacing: 0.48px;
			span {
				&:first-child {
					text-underline-offset: 1px;
				}
			}
			&:hover {
				.promo-details-full {
					font-size: 10px;
					line-height: 12px;
				}
			}
		}
		.promotion-callout {
			padding: 16px 20px;
			background: $color-middle-grey;
			font-size: 12px;
			line-height: 14px;
			margin: 0;
		}
	}
	.visually-hidden {
		&:first-child {
			+ .promotion {
				margin: 0;
			}
		}
	}
}

.product-set-main {
	flex: 1 1 auto;
	min-width: 0;
	@include mobile {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
	}
	.product-set-item {
		max-width: 695px;
		display: flex;
		align-items: flex-start;
		margin-bottom: 65px;
		@include mobile {
			max-width: none;
			text-align: left;
		}
		@include mobile-xs {
			padding: 0 16px;
			display: block;
			margin-bottom: 50px;
		}
		@include mobile-xss {
			padding: 0;
		}
		&:last-child {
			margin: 0;
		}
		> form {
			flex: 1 1 100%;
			max-width: 100%;
			display: flex;
			align-items: flex-start;
			@include mobile-xs {
				display: block;
			}
		}
		.product-set-details {
			flex: 1 1 auto;
			min-width: 0;
		}
		.product-set-image {
			flex: 0 0 290px;
			max-width: 290px;
			margin-right: 48px;
			@include tablet {
				flex: 0 0 220px;
				max-width: 220px;
				margin-right: 30px;
			}
			@include mobile {
				flex: 0 0 290px;
				max-width: 290px;
				margin-right: 48px;
			}
			@include mobile-s {
				flex: 0 0 220px;
				max-width: 220px;
				margin-right: 30px;
			}
			@include mobile-xs {
				max-width: none;
				margin: 0 0 12px 0;
			}
			.product-gallery {
				width: auto;
				float: none;
				.slick-arrow {
					width: 18px;
					height: 27px;
					bottom: 55px;
					cursor: pointer;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					transition: opacity $hover;
					&.fa-angle-left {
						background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAxOCAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeTE9Ii0wLjUzMDYxMiIgeDI9IjIxLjA2NDciIHkyPSItMC41MzA2MTIiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjc1NTY4OSAwLjY1NDkzMSAwLjY1NDkzMSAwLjc1NTY4OSAxNi45MTgzIDEpIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMDYxMjIiLz4KPGxpbmUgeTE9Ii0wLjUzMDYxMiIgeDI9IjIwLjA1ODMiIHkyPSItMC41MzA2MTIiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjc5MzYwNiAtMC42MDg0MzIgLTAuNjA4NDMyIDAuNzkzNjA2IDE2LjkxODMgMjcpIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMDYxMjIiLz4KPC9zdmc+Cg==');
					}
					&.fa-angle-right {
						background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAxOCAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjEuMzQ3MDMiIHkxPSIwLjU5OTAyMiIgeDI9IjE3LjI2NTQiIHkyPSIxNC4zOTQ5IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMDYxMjIiLz4KPGxpbmUgeDE9IjAuNjc2NzkzIiB5MT0iMjYuNTc4OSIgeDI9IjE2LjU5NTIiIHkyPSIxNC4zNzQ4IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMDYxMjIiLz4KPC9zdmc+Cg==');
					}
					&.fa-angle-left,
					&.fa-angle-right {
						&:before {
							#pdp-main & {
								display: none;
							}
						}
					}
					&.pdp-slick-next {
						left: auto;
						right: 0;
					}
					&.pdp-slick-prev {
						left: 0;
					}
					&.pdp-slick-next,
					&.pdp-slick-prev {
						top: auto;
					}
					&:hover {
						opacity: 0.8;
					}
				}
				.product-gallery-elem {
					padding: 30px 30px 0 30px;
					box-sizing: border-box;
					img,
					picture {
						display: block;
						margin: 0 auto;
					}
				}
			}
			.product-primary-image {
				padding: 0;
				min-height: 0;
			}
		}
	}
}

.product-gallery {
	&:not(.slick-initialized) {
		display: flex;
		overflow: hidden;
		padding: 0;
		.product-gallery-elem {
			flex: 1 0 100%;
			max-width: 100%;
		}
	}
	img,
	video {
		display: block;
		width: 100%;
		aspect-ratio: 1/1;
	}
	video {
		display: block;
		width: 100%;
		aspect-ratio: 1/1;
		max-width: none;
		height: auto;
	}
	.pdp-slick-next,
	.pdp-slick-prev {
	    cursor: pointer;
	}
}