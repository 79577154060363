#ajax-form-submit {
    background-color: #ffffff;
    border: 5px solid  #ffffff;
}

.ui-submit-popup {
    border: 1px solid  #BDBBBB;
    background-color: #ffffff;
    box-shadow: none !important;
}


.ui-submit-popup .ui-dialog-titlebar {
    padding: 0 !important;
    margin: 0 !important;
}

// ********** POPUP SIGNIN **********
.popup-signin {
    h2 {
        margin: 10px 0 0 !important;
        padding: 0 !important;
        text-transform: capitalize !important;
        font-size: 23px;
    }

    .form-row {
        box-sizing: border-box;
    }

    input {
        width: 100% !important;
        box-sizing: border-box;
        border: 1px solid #BDBBBB !important;
        outline: none;
        border-radius: 0;
    }

    .login-box-content label.fancyCheck {
        padding: 5px 0 0 30px !important;
        margin: 10px 0 0 !important;
    }

    .section-header {
        background: #ffffff;
    }

    #password-reset {
        float: right;
        color: $color-green--darker;
        text-decoration: underline;
		margin-top: 15px;
    }

    button {
        border-radius: 0 !important;
        width: 160px !important;
        padding: 5px 0 !important;
        height: auto !important;
        font-size: 16px !important;
    }

    button:hover {
		background-color: #e1e1e1;
    	text-decoration: none;
    }

    .si--su__container {
        padding: 20px 0;
        border-top: 1px solid  #BDBBBB;
    }

    .si--su__header {
        font-size: 15px;
        text-transform: capitalize;
        color: #000;
        font-family: Kipling-Extrabold,Futura,sans-serif;
        letter-spacing: 1px;
    }

    .si--su__redirect a {
        font-size: 13px;
        color: $color-green--darker;
        text-decoration: underline;
        letter-spacing: 1px;
    }

    .form-row.form-indent{
        padding:0;
        border:0;
	}

}

// ********** POPUP SIGNUP **********
.create-account--popup__container .create-account-wrapper {
        background-color: #ffffff;
}

.popup-signin input {
	height: 40px;
    margin-bottom: 13px;
}

.popup-signin {
	.section-header {
		font-family: Kipling-Regular,Futura,sans-serif!important;
		text-transform: capitalize;
	    font-size: 25px;
	    margin: 0;
	    color: #2d2f2c;
	    margin-bottom: 15px!important;
	}
}

#signUpArea {
	.section-header {
		font-family: Kipling-Regular,Futura,sans-serif!important;
		text-transform: capitalize;
	    font-size: 25px;
	    margin: 0;
	    color: #2d2f2c;
	    margin-bottom: 0!important;
	}
}

.mobile-left-navigation .login-box {
	input, select, textarea {
		font-size: 13px!important;
	}
	.form-row span {
		font-size: 13px!important;
	}
	.ca--help__wrapper {
		margin-bottom: 30px;
    	font-size: 15px;
    	text-align: center;
	}
	.section-header {
		font-family: Kipling-Regular,Futura,sans-serif!important;
	    text-transform: capitalize;
	    font-size: 18px;
	    margin: 0;
	    color: #2d2f2c;
	    margin-bottom: 0!important;
	    background: transparent;
	    padding: 10px;
	}
}

.mobile-left-navigation .mobile-signin .form-row {
	clear: both!important;
}

.mobile-left-navigation .mobile-signin .form-caption.email-share-policy {
	padding-left: 40px;

	a {
		color: #6e6d72!important;
    	font-size: 14px!important;
	}
}

.mobile-left-navigation .mobile-signin .m--dob, .mobile-left-navigation .mobile-signin .m--mob {
	width: 35%!important;
}

.mobile-left-navigation .mobile-signin .input-name--container, .mobile-left-navigation .mobile-signin .input-lastname--container {
	width: 50%!important;
}

.mobile-left-navigation .m--gender-select {
	width: 50%;
}

.create-account-wrapper, .popup-signin, #signUpArea, .mobile-left-navigation .login-box {
	padding: 0 10px;

	label, .login-box-content label.fancyCheck {
		font-family: Kipling-Regular,Futura,sans-serif!important;
	    text-transform: none!important;
	    color: #2d2f2c!important;
	    font-size: 13px!important;

	    a {
	    	font-size: 13px!important;
	    }
	}

	.login-box-content label.fancyCheck {
		padding: 0 0 0 30px;
	}

	input, select, #RegistrationForm input {
		border: 1px solid #2d2f2c!important;
	    border-radius: 7px!important;
	    color: #2d2f2c!important;
	}
	.custom-chevron.bottom:before {
		border-color: #2d2f2c;
	}
	.form-caption {
		font-family: Kipling-Regular,Futura,sans-serif!important;
		color: #6e6d72!important;
		font-size: 13px!important;
	}
	.fancyCheck:before {
		background: transparent!important;
		border-color: #2d2f2c;
		border-radius: 50%;
	}
	input.fancyCheck:checked+label:after {
		content: '';
		width: 12px;
    	height: 12px;
    	background: #2d2f2c;
    	border-radius: 50%;
    	position: absolute;
	}
	.check16 {
		font-family: Kipling-Regular,Futura,sans-serif;
    	color: #6e6d72;
    	font-size: 14px;
    	margin-top: 5px;
    	padding-left: 30px;
	}
	.form-row-button, #RegistrationForm .form-row-button {
		width: 100%!important;
	}
	.form-row-button button, #RegistrationForm button, .form-row.form-row-button.centered button {
		font-size: 16px!important;
	    text-transform: uppercase;
	    border-radius: 7px!important;
	    padding: 10px 0!important;
	    display: block;
	    width: 100%!important;
	    font-family: Kipling-Regular,Futura,sans-serif!important;
	    height: auto!important;
	}
	.car--help__tel, .ca--help__legend {
		color: #2d2f2c!important;
	}
	.si--su__header {
		font-family: Kipling-Regular,Futura,sans-serif;
		color: #2d2f2c!important;
		margin-bottom: 0;
	}
	.si--su__redirect a {
		font-family: Kipling-Regular,Futura,sans-serif;
		color: #2d2f2c!important;
	}
}

.popup-signin, .mobile-left-navigation .login-box {
	#password-reset {
		color: #2d2f2c;
	}
	.form-row-button {
		margin: 10px 0 20px 0;
	}
}

.mobile-left-navigation .mobile-signin .slide-menu a.back-button {
	font-size: 15px;
    color: #5f5f5f;
    padding: 10px 0 0 15px;
    text-decoration: none;

    &:before {
		content: "\e920";
	    font-family: 'icomoon';
	    font-size: 8px;
	    transform: rotate(90deg);
	    display: block;
	    position: absolute;
	    left: -3px;
	    color: #5f5f5f!important;
	    top: 17px!important;
	}
}

.mobile-left-navigation .login-box {
	#password-reset {
		margin-top: 15px;
	}
	.form-row-button {
		margin-top: 15px;
	}
	.form-row {
		margin-bottom: 0;
	}
}

.create-account-wrapper {
    h1 {
        text-transform: capitalize;
	    font-size: 25px;
	    margin: 0;
	    color: #2d2f2c;
    }
    input {
        // width: 96.5%;
        width: 49.5%;
        border: 1px solid #BDBBBB !important;
        outline: none;
        border-radius: 0 !important;
    }
	select {
		font-size: 13px;
	}


    #RegistrationForm .input-text-pw {
        // width: 96.5%;
        width: 49.5%;

        @include mobile {
            width: 100%;
        }
    }

    .ca--popup__legend {
        font-size: 16px;
    }

    .styled-select {
        position: relative;
    }

    .gender--wrapper select,
    .month-of-birthday,
    .day-of-birthday,.year-of-birthday {
        width: 49%;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        border-radius: 0;
        border: 1px solid  #BDBBBB;
        outline: none;
        padding: 0 30px 0 10px;
        line-height: 40px;
        text-overflow: ellipsis
    }

    .gender--wrapper select {
        width: 100%;
        background-color: #fff;
    }

    .month-of-birthday {
        float: left;
    }

    #RegistrationForm .form-row-button {
        margin: 20px auto 0;
        width: 160px;
    }

    #RegistrationForm fieldset {
        margin-bottom: 20px;
    }

    #RegistrationForm .form-row-button button {
        letter-spacing: 1px;
        width: 100%;
        border-radius: 0;
        padding: 5px 0;
        height: auto !important;
        font-size: 19px;
    }

    label.fancyCheck {
        margin-top: 0;
    }

    .ca--help__wrapper {
        font-size: 13px;
        text-align: center;
    }

    .car--help__tel {
        color: #407930;
        text-decoration: underline;
        font-size: 13px;
    }

}
