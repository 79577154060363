.five-star--container {
    .search-result-content,
    .gridCatName,
    .breadcrumb {
        display: none;
    }
}
.fivestars--reviews-gallery-wrapper .TTmediaItem .TTsmallphotoMediaItem img {
    border: none !important;
}
.five-star--container {
    position: relative;

    @include mobile {
        padding: 0 10px;
    }
}
.five-star--container h1 {
    font-family: Kipling-Bold, Futura, sans-serif;
    font-size: 51px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #428236;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 46px;
}
.five-star--product-tabs-nav {
    position: relative;
    max-width: auto;
    z-index: 499;
    flex-flow: row wrap;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin: 2% 0;
    @include mobile {
        margin: 0 0 2%;
    }
}

.five-star--product-tabs-nav--sticky {
    position: relative;
    z-index: 499;
    // float: left;
    // flex-flow: column nowrap;
    // display: -webkit-flex;
    // display: -ms-flex;
    // display: flex;
    // justify-content: center;
    max-width: 76.23px;
    width: 100%;
    margin: auto;

    .five-star--product-tabs-nav-item {
        margin: 0 0 20px;
    }
}
#fivestar-sidebar {
    height: 580px;
    width: 80px;
    float: left;
    @include desktop {
        margin: 100px 0 0;
    }
}
.Icon {
    &__seoul-go {

    }
    &__sanaa {
        @include mobile {
            width: 42px;
        }
    }
    &__kichirou {

    }
    &__pens {
        @include mobile {
            width: 42px;
        }
    }
    &__city-pack {

    }
}
.fivestars--video-slot__wrapper.slot--1 {
    text-align: center;
}
.is-affixed {
    .five-star--product-tabs-nav {
        @include mobile {
            width: 100%;
            z-index: 1;
            position: fixed;

            .bg-wrapper__white {
                width: 100%;
                height: 100%;
                background-color: #fff;
                opacity: 0.8;
            }
        }
    }
}
.fs--container-right {
    width: calc(100% - 8% - 200px);
    margin: 0 auto;
    @include mobile {
        width: 100%;
    }
}

.five-star--product-tabs-nav-item .five-star--product-tabs-nav-item-img-on {
    display: none;
}
.five-star--product-tabs-nav-item .five-star--product-tabs-nav-item-img-off {
    display: block;
}
.five-star--product-tabs-nav-item.active .five-star--product-tabs-nav-item-img-on {
    display: block;
}
.five-star--product-tabs-nav-item.active .five-star--product-tabs-nav-item-img-off {
    display: none;
}
.five-star--product-tabs-nav-item {
    margin: 0 50px 0 0;
}
.last--item {
    margin: 0;
}
.five-star--product-tabs-nav-item:last-child {
    margin: 0;
}
.fivestars--reviews-wrapper {
    margin: 0 auto;
    #TTreviews, .TTreviews {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .TTrevPurchaseDate {
        text-align: left !important;
    }
    .TTreview, .tt-c-review {
        border: none !important;
        width: 49%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-flow: column wrap;
        padding: 15px 0 !important;
        box-sizing: border-box !important;
        @include mobile {
            width: 100%;
        }
    }
    .TTrevCol2,
    .TTrevCol3 {
        width: 100% !important;
    }
    .TTrevCol3 {
        text-align: left !important;
    }
    ul.TTrevLinkLine li {
        display: block !important;
    }
    .TTreviewSummary,
    .TTpoweredby,
    #TT3RightLinks,
    #TTsearchSort,
    #TT3rShowMore img {
        display: none !important;
    }
    #TT3rShowMore {
        text-align: center !important;
        border: none !important;
        width: 100%;
    	display: block;
    	margin-bottom: 20px;
    }
    ul.TTrevLinkLine {
        margin: 0 0 15px !important;
    }
    .TT3ShowMoreText {
        font-size: 18px;
        font-family: Kipling-ExtraBold,Futura,sans-serif;
        color: #000;
        text-transform: uppercase;
        text-decoration: underline;
    }
    @include mobile {
        width: 100%;
        .TTrevCol2,
        .TTrevCol3 {
            margin-left: 0 !important;
        }
    }
}
.fivestars--reviews-wrapper {
	display: none;
}
.fivestars--reviews-wrapper.active {
	display: block;
}
.five-star--reviews-header {
	text-align: center;
	font-family: Kipling-Regular,Futura,sans-serif;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.fivestars--reviews-gallery-wrapper {
	padding: 0!important;
	
	.tt-w-gallery-widget {
		margin-bottom: 30px!important;
	}
	
	.TTGalleryTitleText {
		display: none;
	}

	.TTGalleryContent {
		border-bottom: none;
	}

	.TTmediaItem {
		width: 120px !important;
		margin-right: 10px!important;

		.TTsmallphotoMediaItem {
			height: 120px !important;

			img {
				width: 120px!important;
				height: 120px!important;
				border: 1px solid $color-curious-green;
			}
		}
	}
}
.five-star--product-tabs-nav-item a {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;
}
.five-star--product-tabs-nav-item a p {
    font-family: Kipling-Regular, Futura, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    text-align: left;
    color: #000000;
    text-decoration: underline;
    white-space: nowrap;
    margin: 10px 0 0;
    @include mobile {
        margin: 0;
    }
}
.five-star--product-tabs-nav-item-img {
    // width: 65px;
    // height: 76px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}
.sidebar--static .five-star--product-tabs-nav-item-img {
    align-items: flex-end;
}
.five-star--product-tabs-nav-item-img img {
    max-width: 100%;
}
.five-star--product-tabs-nav-item a:hover p {
    text-decoration: none;
}
.five-star--product-tabs-item {
    display: none;
}
.five-star--product-tabs-item.active {
    display: block;
}
.five-star--product-tabs-item-wrapp {
    display: flex;
    justify-content: center;
    position: relative;
}

.five-star--product-attr {
    position: absolute;
    right: -200px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    h2 {
        font-family: Kipling-Regular, Futura, sans-serif;
        font-size: 30px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: left;
        color: #000000;
        text-transform: uppercase;
    }
    p {
        font-family: Kipling-Regular, Futura, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: left;
        color: #000000;
    }
    a {
    	text-decoration: none;
    }
    a.shop-link {
        margin-top: 25px;
        font-family: Kipling-Bold, Futura, sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: left;
        color: #000000;
        text-transform: uppercase;
        display: block;
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
    .five-star--product-stars {
        margin: 0 0 8px;
        &:hover {
        	cursor: pointer;
        }
        @include mobile {
            max-width: 200px;
        }
    }
    .more-colors--message {
        font-size: 15px;
        margin: 10px 0;
        font-family: Kipling-Regular,Futura,sans-serif;
    }
}
.mobile-500-hidden {
    display: none;
}
.five-star--product-tabs-item-wrapp-img {
    position: relative;
}
@include mobile {
    .five-star--container h1 {
        font-size: 7.5vw;
        margin-top: 20px;
    }
    .five-star--product-tabs-item-wrapp-img {
        width: 60%;
    }
    .five-star--product-attr {
        width: 60%;
        right: -63%;
    }
    .five-star--container h1 img {
        width: 6vw;
    }

    .five-star--product-tabs-nav {
        max-width: none;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-flow: row nowrap;
        padding: 10px 2%;
    }

    .five-star--product-tabs-nav-item a p {
        white-space: nowrap;
    }

    .five-star--product-tabs-nav-item {
        margin-right: 30px;
        margin-top: 15px;
        margin-bottom: 0;
    }

    .five-star--product-tabs-nav-item:last-child {
        margin-right: 0;
    }

    .five-star--product-tabs-nav-item-img {
        margin-right: 0;
        width: auto;
        height: auto;
        margin-bottom: 10px;
    }

    .five-star--product-tabs-nav-item a {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .five-star--product-tabs-item-wrapp {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 700px) {
    .five-star--product-tabs-nav-item {
        margin-right: 0;
        margin-top: 0;
        width: 20%;
    }
}

@media screen and (max-width: 500px) {
    .five-star--container h1 {
        font-size: 25px;
    }
    .mobile-500-hidden {
        display: block;
    }
    .five-star--product-tabs-nav-item {
        margin-right: 9px;
    }
    .five-star--product-tabs-nav-item a p {
        font-size: 12px;
    }
    .five-star--product-attr h2 {
        font-size: 20px;
    }
    .five-star--product-attr p {
        font-size: 14px;
    }
    .five-star--product-attr a {
        margin-top: 15px;
        font-size: 14px;
    }
}

.fivestars--reviews-gallery-wrapper {
	//max-width:450px;
	display:block;
	margin:0 auto;
	.TTGallerySmall .TTGalleryTitle {
		padding-top:0 !important;
	}
}

.sort-reviews {
	ul li {
		text-align: center;
		border-bottom: 2px solid #d8d1d1;
		a.active {
			background: #d8d1d1;
		}
		a:hover {
			background: #d8d1d1;
		}
	}
	a {
		text-align: center;
	    font-family: Kipling-Regular,Futura,sans-serif;
	    font-size: 22px;
	    font-weight: 400;
	    font-style: normal;
	    font-stretch: normal;
	    line-height: normal;
	    color: #000;
	    text-transform: uppercase;
	    display:block;
	    padding: 15px 0 10px;
	}
	
	.title {
		&:after {
			border-style: solid;
		    border-color: black;
		    border-width: 1px 1px 0 0;
		    content: "";
		    display: inline-block;
		    width: 15px;
		    height: 15px;
		    position: relative;
		    transform: rotate(135deg);
		    vertical-align: top;
		    margin-left: 10px;
		}
		&.active:after{
			transform: rotate(-45deg);
			top: 10px;
		}
	}
	.title-wrapper {
		text-align: center;
    	border-top: 2px solid #d8d1d1;
    	border-bottom: 2px solid #d8d1d1;
	}
	
}
   

/*@media (min-width: 500px) {
    .fivestars--reviews-gallery-wrapper.total-pages-3 {
        transform: translateX(34px);
    }
    .fivestars--reviews-gallery-wrapper.total-pages-2 {
        transform: translateX(99px);
    }
    .fivestars--reviews-gallery-wrapper.total-pages-1 {
        transform: translateX(164px);
    }
}*/
