.footer-column-wrapper {
	input[name='email']::placeholder{
	    font-size: 14px;
    	padding-left: 5px;
	    color: #ccc;
    	font-size: 12px;
    	text-transform: none;
    	font-weight:400;
    	display: block;
    	font-family: Kipling-Regular,Futura,sans-serif;
    	letter-spacing: .05em;
	}
}

@media screen and (min-width: 300px) and (max-width:500px){
	.pt_storefront #curalate-fan-reel{
		height:160px !important;
	}
}

@media screen and (min-width: 500px)  and (max-width:700px) {
	.pt_storefront #curalate-fan-reel{
		height:240px !important;
	}
}

@media screen and (min-width: 700px) and (max-width:960px){
	.pt_storefront #curalate-fan-reel{
		height:345px !important;
	}
}

@media screen and (max-width: 960px){
	#footer .footer-email-signUp {
		font-size: 22px;
		margin: 0;
		font-family: Kipling-Light, Futura, sans-serif;
	}
	.footer-bottom-info-wrapper {
		margin: 0 -10px;
	}

	.mobile-footer-logo-wrapper {
		margin: 0 0 20px;
	}

	.mobile-footer-logo-wrapper .mobile-footer-logo-link {
		display: block;
		width: 140px;
		height: 95px;
		background: url(../images/logo.svg) no-repeat;
		margin: 0 auto;
		background-size: 100%;
	}

	.footer-promo-mobile-banner {
		height: 46px;
		overflow: hidden;
		background-color: $color-red-main;
		text-align: center;
		font-family: Kipling-ExtraBold,Futura,sans-serif;
		font-size: 18px;
		color: #fff !important;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		margin: 0 0 15px;
	}

	#footer-banner-sticky .footer-banner-navigation-main {
		background-color: #000;
	}

	#footer-banner-sticky {
			position: fixed;
		    z-index: 500;
	    	bottom: 0;
	    	width: 100%;
	    	display: block;
	    	height: auto;
	   	    -webkit-transform: translate3d(0,210px,0);
			-moz-transform:    translate3d(0,210px,0);
			-ms-transform:     translate3d(0,210px,0);
			-o-transform:      translate3d(0,210px,0);
			transform:         translate3d(0,210px,0);

			-webkit-transition-duration: 500ms;
		    -moz-transition-duration: 500ms;
		    -o-transition-duration: 500ms;
		    transition-duration: 500ms;

			.footer-banner-navigation-main {
	   			height: 50px;
   			    background: $color-red-main;
   				 color: white;
	    		.description {
	    			display: inline-block;
	    			width: 90%;
	    			span.big {
	    				font-size: 14px;
	    				margin: 10px 0 4px 10px;
	    				display: block;
	    			}
	    			span.small {
	    				font-size: 11px;
	    				margin: 0px 0 0px 10px;
	    				display: block;
	    			}
	    		}
	    		span.fa.fa-chevron-up, span.fa.fa-chevron-down {
	    			font-size: 24px;
	    			vertical-align: top;
	   				margin-top: 10px;
	    		}
	    		.closed {
					display : block;
				}
				.open {
					display : none;
				}

			}
			.footer-banner-main-description {
				background : white;
				text-align : center;
			    padding: 15px;
		        max-height: 215px;
	    		overflow: hidden;
			}
			&.opened {
	    		 -webkit-transform: translate3d(0,0,0);
				 -moz-transform:    translate3d(0,0,0);
				 -ms-transform:     translate3d(0,0,0);
				 -o-transform:      translate3d(0,0,0);
				 transform:         translate3d(0,0,0);

				 -webkit-transition-duration: 500ms;
			     -moz-transition-duration: 500ms;
			     -o-transition-duration: 500ms;
			      transition-duration: 500ms;

				.footer-banner-main-description{
					display : block;
				}
				.footer-banner-navigation-main {
					.closed {
						display : none;
					}
					.open {
						display : block;
					}
				}
			}
	}

  .county-footer-mobile {
      padding-bottom: 15px;
  	.localeForm {
  		width : 100%;
  		font-family: Kipling-Regular,Futura,sans-serif;
  		select {
  			margin:0 !important;
  		}
  	}
  }
  #feedback {
	display:none !important;
  }

  #footer {
    height: auto;
    padding: 20px 0 0 0;
    background-color: #f6f6f6;
    border: none;
    text-transform: inherit;

    &:before {
      display: none;
    }

    .container {
      width: 100%;
    }

    a {
      color: inherit;
    }
  }
  
  #expand section, #expand1 section, #expand2 section {
	padding-left: 10px!important;
  }

 .footer-menu {
 	text-align: center;
    margin-bottom: 30px;
    list-style: none!important;
    margin-left: 0!important;

    li {
      border-bottom: 2px solid #e6e6e6;
      padding-left: 10px;

      &:first-child {
        border-top: 2px solid #e6e6e6;
      }
      
      &:last-child {
        border-bottom: none;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      padding: 8px 0;
      font-size: 14px;
      text-transform: capitalize;
      color: #2d2f2c!important;
    }
  }

  .footer-contact {
    margin-bottom: 30px;
    padding-left: 10px;

    .title {
      display: block;
      margin-bottom: 10px;
      font-family: Kipling-Light, Futura, sans-serif;
      font-size: 20px;
      color: #2d2f2c;
      text-align: left;
    }

    .mob-help-cicle {
      display: inline-block;
      color: #2d2f2c;
      margin: 0 15px 0 0;

      > span {
        display: block;
        font-size: 14px;
        text-decoration: underline;
      }
    }
    
    .mob-help-cicle div {
    	color: #2d2f2c!important;
    	text-decoration: underline;
    }
  }

  .footer--signup-container .ovlb label.visually-hidden {background: transparent!important;}
  .footer-form-subscribe {
    position: relative;
    padding: 0 20px;
    margin-bottom: 10px;
    width: 100%;
		margin: 0 auto;

		.footer--signup-container {
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row nowrap;
		}

		.ovlb {
			height: 40px !important;
		}

		.ovlb .ovlbInput {
			height: 40px !important;
			line-height: 40px !important;
			font-size: 12px!important;
			border: solid 1px #8d8d8d!important;
		}

		.ovlb .ovlbLabel {
			line-height: 40px;
			height: 40px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 12px!important;
			border: solid 1px #8d8d8d!important;
		}

    .actions {
      position: relative;
      right: 0;
      top: 0;
      display: inline-block;
      width: 60px;
      height: 40px;
      background: #f6f6f6;
      color: #8d8d8d;
      text-align: center;
      border: none;

      button {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent;
        border: none;
        color: transparent;
       	border: solid 1px #8d8d8d!important;
    	margin-top: 1px;
    	left: -2px;
    	border-left: none;
      }

      &:before {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        content: "\e920";
        font-family: icomoon;
        color: #8d8d8d;
        font-size: 10px;
      }
    }
  }

  .footer-socials {
    margin: 12px 0 20px;
    text-align: left;
    padding: 0 10px;

    a {
      display: inline-block;
      margin: 0 0 0 15px;
      font-size: 22px;
      color: #6d6a72!important;
    }
  }

  .footer-copyright-box {
    	text-align: center;
    	padding:20px 0 10px 0;
    	background-color: #e1e1e1;

		& > span:first-child,
    & > .content-asset:first-child {
			margin: 0 0 5px;
			display: block;
		}

    span,
    div {
      margin: 0 5px;
      font-family: Kipling-Regular,Futura,sans-serif;
      font-size: 8px;
      text-transform: uppercase;
      color: #2c2b2f;

			@media (min-width: 500px) {
				font-size: 16px;
			}
    }

    div {
      margin: 0;
    }

		.htmlSlotContainer {
			span {
				margin: 0 3% 0 0 !important;
			}

			span:last-child {
				margin: 0;
			}

			a {
				font-family: Kipling-Regular,Futura,sans-serif !important;
			}
		}

    a {
      text-decoration: underline;
			font-size: 10px!important;

			&:hover {
				color: $color-curious-green;
			}
    }
  }

  input.email-input,
  .cart-coupon-code input,
  input.password-input,
  .gift-certificate-purchase form .input-text,
  .gift-certificate-purchase form .input-textarea,
  .gift-cert-balance input,
  #dwfrm_ordertrack input,
  #RegistrationForm input.input-text,
  #RegistrationForm .input-textarea,
  #RegistrationForm .input-text-pw,
  #resetPassBox input,
  #setPassword input,
  .newsletterSubscriptionForm .ovlb,
  #StoreLocator input,
  #send-to-friend-main input,
  #send-to-friend-main .input-textarea {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e6e6e6;
    font-family: Kipling-Regular, Futura, sans-serif;
    font-size: 12px;
    border-radius: 0;
    box-shadow: none;
  }

  .ovlb .ovlbLabel {
    top: 0;
    left: 0;
    margin-top: 0;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    line-height: 40px;
    font-size: 12px;
    text-transform: capitalize;
    color: #8c8c8c;
  }

  .ovlb .ovlbInput {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
  }

  label#emailFooterSuccess {
    font-size: 12px;
    color: #000;
    padding: 3px 10px;
  }

  .footer-copyright {
    text-align: center;
  }

}

.footer--signup-container .ovlb label.visually-hidden {
	color: $white;
	background-color: $black;
}
