@media screen and (max-width: 960px){
	.no-hits-page {
		.no-hits-options {
			display : none;
		}
		.product_recommender {
			margin-top: 30px;
		}
	}
	#contactUsKIP {
		float: none;
		width : 100%;
		font-size: 14px!important;

		textarea {
			width: 100%!important;
		}

		.form-row {
			height: auto;
			width: 100%;
			margin-right: 0;
		}

		p {
			font-size: 14px!important;
			margin-left: 0!important;
		}

		.select2-container {
			width: 100%;
		}
	}
	.pt_customer-service h1 {
	    font-size: 25px;
	    margin : 0px;
	}
	#primaryCustService {
    	margin-top: 0px;    	
	}
	#secondaryCustService {
		width: 100%;

		.customerservice-secondary-navigation .toggle {
			color : #333 !important;
		}
	}

	#sendBtn {
		margin-right: 0;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.personalization-cat div {
		display: inline-block;
		max-width: 100%;
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 30px;
	}

	#border {
		width: 100%!important;
		margin-left: 0!important;
	}

	#MONOGRAMOCC,
	#MONOGRAMSTYLE {
		width: 100%!important;
		margin-left: 0!important;
	}

	.pt_error #primary {
		width: 100%;
		float: none;
	}

	.error-page-search input {
		float: left!important;
		padding: 0;
		line-height: 28px;
	}

	.pt_error .error-page-message,
	.pt_error .error-page-search {
		padding-right: 0;
	}

	.pt_error .col-1 {
		border-right: none;
	}


	.table-wrapper {
		width: 100%;
		overflow: auto;
	}
}