/* Product recommendations styles */

.product_recommender {
	padding: 0 50px;

	.product_recommender-title {
		font-size: 26px;
		font-weight: 400;
		font-family: Kipling-Light,Futura,sans-serif;
		margin: 0;
		text-transform: none;
		text-align: left;
		color: #2d2f2c;

		@media screen and (max-width: 960px) {
			font-size: 13px;
		}
	}

	.product_recommender-wrap {
		position: relative;
		width: 100%;
	}

	.product_recommender-list {
		padding: 12px 6px;
		background-color: #e1e1e1;

		.slick-track {
			height: 100%;
			display: flex;
		}

		.slick-slide {
			outline: none;
		}

		.product_recommender-item {
			margin: 0 6px;
			position: relative;
			box-sizing: border-box;
			height: auto;

			&.recommender-bottom-cta {
				.product-tile {
					padding-bottom: 42px;

					@include mobile {
						padding-bottom: 36px;
					}
				}

				.recommendationtocart, .smgt-button {
					padding: 7px 0 5px;
					height: auto;
					width: calc(100% - 24px);
					position: absolute;
					bottom: 12px;
					left: 50%;
					transform: translateX(-50%);
					text-align: center;
					
					@include mobile {
						width: calc(100% - 12px);
						bottom: 6px;
					}
				}
			}
		}

		.product-tile {
			width: 100%;
			height: 100%;
			min-height: auto;
			padding: 12px;
			box-sizing: border-box;
			transition: width .4s ease-in;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			
			.product-image {
				min-height: 0;
				height: auto;
				margin-bottom: 5px;
				border-bottom: none;
			}

			.product-info-wrapper {
				display: flex;
				margin-top: 5px;
				justify-content: space-between;
			}

			.product-brand-wrapper {
				font-size: 13px;
				color: #2d2f2c;
			}

			.product-name-wrapper {
				font-size: 13px;
				color: #69686b;
			}

			.product-pricing {
				font-size: 13px;

				.product-sales-price, .product-standard-price {
					font-size: 13px;
				}
			}

			.turnto-grid-stars {
				display: inline-flex;
				justify-content: flex-end;
				margin-left: 5px;
			}

			.tt-c-rating__star .tt-o-icon--lg {
				width: 12px;
				height: 12px;
				fill: #6c6a71!important;
			}

			.tt-c-rating__star .tt-o-icon-empty {
				stroke: #6c6a71;
			}

			.no-color-swatches, .product-swatches {
				height: auto;
				margin: 0;
				flex-grow: 1;
			}

			.product-swatches .available-colors-label {
				font-size: 13px;
				color: #6c6a71;
				font-style: italic;
			}
			.product-promo {
				display: none;
			}
		}
			
		.slick-arrow {
			position: absolute;
			display: block;
			font-size: 0;
			outline: none;
			border: none;
			background: transparent;
			z-index: 1;
			height: 100%;
			width: 50px;
			top: 0;
			padding: 0;
			transform: none;
			
			&:after {
				color: #2d2c2f;
			}
			
			&.slick-next {
				left: 100%;
				right: auto;
				
				&:after {
					content: ">";
					display: block;
					font-family: Kipling-Light,Futura,sans-serif;
					font-size: 102px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) scaleX(.6);
				}
			}
			
			&.slick-prev {
				right: 100%;
				left: auto;
				
				&:after {
					content: "<";
					display: block;
					font-family: Kipling-Light,Futura,sans-serif;
					font-size: 102px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) scaleX(.6);
				}
			}
		}
	}
}

.jcarousel {
	&-wrapper {
		position: relative;
		margin: 0 auto;
		width: 100%;
	}

	&-prev,&-next {
		position: absolute;
		top: calc(50% - 25px);
		display: block;
		width: 25px;
		font: normal normal 42px/1 'FontAwesome';
		color: $color-dark-grey;
	}
	&-prev {
		left: -25px;
		&:after {
			content: '\f104';
		}
	}
	&-next {
		right: -25px;
		text-align: right;
		&:after {
			content: '\f105';
		}
	}
}


.js .product_recommender .product-image:hover #quickviewbutton {
  display: none;
}

@media only screen and (max-width: 960px) {
	.product_recommender {
		padding: 0 23px;
	}

	.product_recommender .product_recommender-list .slick-arrow {
		width: 23px;
	}

	.product_recommender .product_recommender-list .slick-arrow.slick-prev:after, .product_recommender .product_recommender-list .slick-arrow.slick-next:after {
		font-size: 64px;
		transform: translate(-50%,-50%) scaleX(0.5);
	}

	.product_recommender .product_recommender-list {
		padding: 6px 3px;
	}

	.product_recommender .product_recommender-list .product_recommender-item {
		margin: 0 3px;
	}

	.product_recommender .product_recommender-list .product-tile .product-pricing {
		margin: 0;
	}

	.product_recommender .product_recommender-list .product-tile {
		padding: 6px;
	}

	.product_recommender .product-tile .product-reviewstars {
		display: none;
	}

	.product_recommender .product_recommender-list .slick-arrow.slick-next {
		left: 100%;
	}

	.product_recommender .product_recommender-list .slick-arrow.slick-prev {
		right: 100%;
	}
}

.product-tile .product-image {
	position: relative;
}
.product-tile .product-image .thumb-link img {
	transition: opacity 0.3s ease;
	aspect-ratio: 1;
}
.product-tile .product-image .product-image-rollover {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.product-tile .product-image .product-image-rollover img {
	opacity: 0;
	transition-delay: 0.3s;
}
.product-tile .product-image.rollover-completed .product-image-rollover img {
	transition-delay: 0s;
}
.product-tile .product-image.rollover-loaded:hover .product-image-default img {
	opacity: 0;
}
.product-tile .product-image.rollover-loaded:hover .product-image-rollover img {
	opacity: 1;
}