.nowtrending-landing-wrapp {
    max-width: 1600px;
    margin: 0 auto;
    
    @include mobile-xs() {
    	width: 100%;
    	max-width: none;
    }
}

.nowtrending-landing {
	margin-top: 30px;
    margin-bottom: 100px;
    
    @include mobile() {
    	margin-bottom: 40px;
    }
    
    @include mobile-xs() {
    	margin-top: 0;
    	margin-bottom: 0;
    }
}

.nowtrending-landing-only-mobile {
	display: none!important;
	
	@include mobile-xs() {
    	display: block!important;
    }
}

.nowtrending-landing-hidden-mobile {
	@include mobile-xs() {
    	display: none!important;
    }
}

.nowtrending-landing-hero {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    
    @include mobile-xs() {
    	display: block;
    }
}

.nowtrending-landing-main-img {
    position: relative;
    overflow: hidden;
    width: 48%;
    z-index: 1;
    display: block;
    
    @include mobile-xs() {
    	width: 100%;
    	overflow: visible;
    }
}

.nowtrending-landing-main-img img {
    width: 100%;
    display: block;
}

.nowtrending-landing-main-img .mark {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    text-align: center;
    font-family: Kipling-Extrabold,Futura,sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.38px;
    color: #ffffff;
     
    @include mobile() {
    	font-size: 14px;
    	letter-spacing: 2px;
    }
    
    @include mobile-xs() {
    	font-size: 12px;
    	letter-spacing: 1.6px;
    }
}

.nowtrending-landing-main-img .mark span {
    font-family: Kipling-Light,Futura,sans-serif;
    font-weight: normal;
}

.nowtrending-landing-main-img .text {
    position: absolute;
    right: 0;
    bottom: 29px;
    font-family: Kipling-Extrabold,Futura,sans-serif;
    font-size: 117px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.69;
    letter-spacing: 4.7px;
    text-align: left;
    color: #faf97d;
    
    span {
    	letter-spacing: 3.53px;
    }
    
    @include mobile() {
    	left: 3px;
    	bottom: 68px;
    	right: auto;
    	font-size: 73.25px;
  		letter-spacing: 2.93px;
  		
  		span {
	    	letter-spacing: 2px;
	    }
    }
}

.nowtrending-landing-add-images {
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    text-align: center;
    z-index: 0;
    
    @include mobile-xs() {
    	position: static;
    	padding: 40px;
    }
}

.nowtrending-landing-add-images a {
    width: 23%;
    position: absolute;
    display: block;
    z-index: 0;
    
    @include mobile-xs() {
    	position: static;
    	width: 100%;
    }
}

.nowtrending-landing-add-images a img {
	width: 100%;
	display: block;
}

.nowtrending-landing-section-mobile-images {
	overflow: hidden;
	
	.nowtrending-landing-section-mobile-image-1 {
		position: relative;
		overflow: hidden;
		display: block;
		
		img {
			position: relative;
			max-width: none;
			height: 500px;
		}
	}
	
	.nowtrending-landing-section-mobile-image-2 {
		padding: 40px;
		display: block;
		
		img {
			width: 100%;
		}
	}

	img {
		display: block;
	}
}

.nowtrending-landing-section-2 {
    position: relative;
    display: block;
    width: 90%;
    margin: 50px auto 0 auto;
    overflow: hidden;
    z-index: 2;
    
    @include mobile-xs() {
    	margin-top: 0;
    	width: 100%;
    }
}

.nowtrending-landing-section-2-main-img {
    text-align: center;
    position: relative;
    z-index: 1;
    width: 100%;
    display: block;
}

.nowtrending-landing-section-2-main-img img {
    width: 100%;
    display: block;
}

.nowtrending-landing-section-2-add-img {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    display: block;
    
    @include mobile-xs() {
    	position: static;
    	background: #000000;
    }
}

.nowtrending-landing-section-2-add-img img {
    width: 100%;
    height: 100%;
    display: block;
    
    @include mobile-xs() {
    	width: auto;
    	height: 600px;
    	max-width: none;
    	position: relative;
    }
}

.nowtrending-landing-section-2-3-wrapp {
    position: relative;
    overflow: hidden;
}

.trigger-nowtrending-landing-section-3 {
    position: absolute;
    left: 0;
    right: 0;
    top: 35%;
}

.nowtrending-landing-section-3 {
    width: 77%;
    margin: 50px auto 0 auto;
    z-index: 1;
    position: relative;
    opacity: 0;
    transition: .3s ease-in-out opacity;
    
    @include mobile-xs() {
    	opacity: 1;
    	width: 100%;
    	margin-top: 0;
    	position: static;
    }
}

.nowtrending-landing-section-3-images {
    display: flex;
    justify-content: space-between;
    
    @include mobile-xs() {
    	display: block;
    }
}

.nowtrending-landing-section-3-images > a {
    width: 29%;
    flex: 0 0 29%;
    display: block;
    
    @include mobile-xs() {
    	width: 100%;
    }
}

.nowtrending-landing-section-3-images img {
    width: 100%;
    display: block;
    position: relative;
}

.nowtrending-landing-footer {
    margin-top: 55px;
    text-align: center;
    
    @include mobile-xs() {
    	margin-top: 0;
    	padding-top: 20px;
    	padding-bottom: 60px;
    	background: #000000;
    }
}

.nowtrending-landing-footer a {
    font-family: Kipling-Extrabold,Futura,sans-serif;
    font-size: 23px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.66;
    letter-spacing: 0.92px;
    text-align: center;
    color: #000000;
    text-decoration: underline;
    
    @include mobile() {
    	font-size: 18px;
    	letter-spacing: normal;
    }
    
    @include mobile-xs() {
    	color: #FFFFFF;
    	font-size: 15px;
    	letter-spacing: normal;
    }
}

.nowtrending-landing-footer a:hover {
    text-decoration: none;
}

body.talkable-hide #talkable-offer {
	display: none;
}