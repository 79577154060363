// thin chevrons
.custom-chevron::before {
	border-style: solid;
    border-color: #7a7a7a;
	border-width: 1px 1px 0 0;
	content: '';
	display: inline-block;
	width: 15px;
	height: 15px;
	position: relative;
	transform: rotate(-45deg);
	vertical-align: top;
}

.custom-chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.custom-chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

.custom-chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}
