@media screen and (max-width: 960px) { 
	.gift-cert-landing {
		.button-fancy-medium {
			height : 100% !important;
			min-height : 40px;
		    font-size: 12px;
    		padding: 0;
    		margin: 0;
   		    width: 90%;
		}
		h2 {
			    min-height: 50px;
		}
	}
}